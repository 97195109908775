import React, { FC } from 'react';
import { DropdownOption } from '../Dropdown/types';
import './checkboxSelector.scss';
import Checkbox from '../Checkbox';

interface CheckboxSelectorProps {
    options: DropdownOption[];
    values: string[];
    onChange: (values: string[]) => void;
}

const CheckboxSelector: FC<CheckboxSelectorProps> = ({ options, values, onChange }) => {
    const getOption = (option: DropdownOption) => {

        const handleClick = () => {
            if (values.includes(option.value)) {
                onChange(values.filter((value) => value !== option.value));
                return;
            }
            onChange([...values, option.value]);
        };

        return (
            <div key={`cs-${option.value}`} className="option-container">
                <Checkbox checked={values.includes(option.value)} onClick={handleClick} />
                <span className="label">{option.label}</span>
            </div>
        );
    };

    return (
        <div className="checkbox-selector-container">{options.map((option: DropdownOption) => (getOption(option)))}</div>
    );
};

export default CheckboxSelector;
