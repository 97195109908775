import React, { FC, useState } from 'react';
import moment from 'moment';
import './savedShiftDisplay.scss';
import { DeleteIcon, DownChevron, OtherEditIcon as EditIcon, UpChevron } from '../../../Elements/CustomSVGIcon';

interface SavedDate {
    date: string
    startTime?: string | undefined;
    endTime?: string | undefined;
    startDate?: string | undefined;
    endDate?: string | undefined;
    range?: boolean;
}

interface SavedShiftProps {
    shiftName: string | undefined;
    savedDates: SavedDate[];
    onRemove: (key:string) => void;
    onEdit: (key:string) => void;
    key: string;
}

const SavedShift: FC<SavedShiftProps> = ({ shiftName, savedDates, onRemove, onEdit, key }) => {
    const [showDates, setShowDates] = useState<boolean>(true);
    const today = new Date();

    // Sort by date and startTimes
    const sortedDatedDates = savedDates.sort((a, b) => {
        if (a.date === b.date) {
            if (a.startTime && b.startTime) {
                return a.startTime.localeCompare(b.startTime);
            }
            return 0;
        }
        return a.date.localeCompare(b.date);
    });

    const formatTime = (timeString: string) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = new Date(`2000-01-01T${timeString}`).toLocaleTimeString([], options as Intl.DateTimeFormatOptions);
        if (formattedTime === '00:00 am') {
            return '12:00 AM';
        } if (formattedTime === '00:00 pm') {
            return '12:00 PM';
        }
        return formattedTime.replace(/\b([ap])m\b/gi, match => match.toUpperCase());
    };

    const handleShowDatesClick = () => {
        setShowDates(!showDates);
    };

    const getDateDisplay = (date: SavedDate) => {
        const formattedDate = moment(date.date).format('dddd Do MMMM');
        const beforeToday = new Date(date.date) < today;
        const [month, day, year] = date.date.split('-');
        const slotDate = `${year}-${month}-${day}`;
        const getTime = () => {
            if (date.startTime && date.endTime) {
                const isTimeInThePast = new Date(`${slotDate}T${date.startTime}`) < today;
                return (
                    <><span className="label">{`${formatTime(date.startTime)} - ${formatTime(date.endTime)}`}</span>
                        <div className="action-icons-wrapper">
                            <div onClick={() => !isTimeInThePast && onEdit(date.date)} className="icon-wrapper">
                                <EditIcon s={isTimeInThePast ? '#BDBDBD' : ''} />
                            </div>
                            <div onClick={() => !isTimeInThePast && onRemove(date.date)} className="icon-wrapper">
                                <DeleteIcon s={isTimeInThePast ? '#BDBDBD' : ''} />
                            </div>
                        </div>
                    </>
                );
            }
            return (
                <div className="action-icons-wrapper">
                    <div onClick={() => !beforeToday && onEdit(date.date)} className="icon-wrapper">
                        <EditIcon s={beforeToday ? '#BDBDBD' : ''} />
                    </div>
                    <div onClick={() => !beforeToday && onRemove(date.date)} className="icon-wrapper">
                        <DeleteIcon s={beforeToday ? '#BDBDBD' : ''} />
                    </div>
                </div>
            );
        };

        return (
            <div className="date-display" key={key}>
                <span className="label" style={{ width: '265px' }}>{formattedDate}</span>
                {getTime()}
            </div>
        );
    };

    return (
        <div className="saved-date-container">
            <div className="header">
                <h5 className="heading">{shiftName}</h5>
                <div className="icon-wrapper" onClick={handleShowDatesClick}>
                    {showDates ? <UpChevron /> : <DownChevron />}
                </div>
            </div>
            {showDates && <div className="dates-display-container">{sortedDatedDates.map((date) => getDateDisplay(date))}</div>}
        </div>
    );
};

export default SavedShift;
