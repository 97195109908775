import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { RequestDataResponse } from 'core/dashboard/types';
import './dashboard.scss';
import { ADMIN_USER, SCORCHSOFT_USER, SECURITY_USER, ADMIN_ACCESS_LEVELS } from 'core/utils/value';
import { AddIcon } from 'components/Elements/CustomSVGIcon';
import { LeftOutlined } from '@ant-design/icons';
import { cancelAmendmentRequest, createVisitRequest } from 'core/visitRequestForm/visitRequestFormApi';
import { renderApprover, renderDateSubmitted, renderPeriod, renderRefNumber, renderRequester, renderSite } from 'components/RequestTable/CellRenderers';
import { Modal } from 'antd/lib';
import { DropdownOption } from 'components/Elements/Dropdown/types';
import { isArray, isEmpty, isNull } from 'underscore';
import { resetLockedStatus } from 'core/visitRequestReview/visitRequestReviewSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import { AppDispatch, CombinedStates } from '../../core/types';
import { dashboardFilterRequest, exportCompletedVisits } from '../../core/dashboard/dashboardApi';
import SearchFilter from './components/SearchFilter';
import RequestTable from './components/RequestTable';
import Main from '../../components/layout/Main';
import HourlyRateModal from './components/HourlyRateModal';
import { DashboardFilterParams } from './types';
import LockedRequestModal from './components/OverruledModal';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { requestData, isFetching, summaryData, filterData, pagination } = useSelector((state: CombinedStates) => state.dashboard);
    const { lockedStatus: { overriden, lockedBy, requestId } } = useSelector((state: CombinedStates) => state.visitRequestReview);
    const { userData } = useSelector((state: CombinedStates) => state.user);
    const { isCreating, lastRequestAmended } = useSelector((state: CombinedStates) => state.visitRequestForm);

    const [statusList, setStatusList] = useState<object>({});
    const [siteList, setSiteList] = useState<Array<object>>([]);
    const [sortedData, setSortedData] = useState<RequestDataResponse[] | null>(null);
    const [sortDirection, setSortDirection] = useState(queryParams.get('sort') || null);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);
    const toolbar = [
        { label: 'Dashboard', link: ADMIN_ACCESS_LEVELS.includes(userData?.role?.accessLevel) ? '/dashboard' : '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];

    const getArrayQueryParam = (param: string | null): Array<string> => (param ? param.split(',') : []);

    const [searchTerms, setSearchTerms] = useState<string | Array<string>>(
        getArrayQueryParam(queryParams.get('searchTerms')).map((term) => decodeURIComponent(term)),
    );
    const [startDate, setStartDate] = useState<string>(queryParams.get('startDate') || '');
    const [endDate, setEndDate] = useState<string>(queryParams.get('endDate') || '');
    const [siteText, setSiteText] = useState<string | Array<string>>(getArrayQueryParam(queryParams.get('siteText')));
    const [statusText, setStatusText] = useState<string | Array<string>>(getArrayQueryParam(queryParams.get('statusText')));
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const backLink = (
        <Button type="link" onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    useEffect(() => {
        if (userData?.role?.accessLevel === SECURITY_USER) {
            navigate('/security-dashboard');
        }

        if (lastRequestAmended !== undefined && lastRequestAmended !== null) {
            dispatch(cancelAmendmentRequest(lastRequestAmended));
        }
    }, []);

    useEffect(() => {
        if (filterData) {
            setStatusList(filterData?.statuses);
            setSiteList(filterData?.sites);
        }
    }, [filterData]);

    const handleOverruleModalClose = () => {
        dispatch(resetLockedStatus());
    };

    const handleSubmitRequest = (page = 1) => {
        dispatch(dashboardFilterRequest({
            q: searchTerms,
            status: statusText,
            site: siteText,
            startDate,
            endDate,
            page,
            sort: sortDirection,
        }));
    };

    const requestCard = [
        {
            id: 1,
            number: summaryData?.pending,
            title: 'Pending Approval',
        },
        {
            id: 2,
            number: summaryData?.rejectedQueried,
            title: 'Rejected or Queried',
        },
        {
            id: 3,
            number: summaryData?.approved,
            title: 'Approved',
        },
        {
            id: 4,
            number: summaryData?.completed,
            title: 'Completed',
        },
    ];

    const tableColumns = [
        {
            title: 'Reference #',
            dataIndex: 'ref',
            key: 'ref',
            render: (ref:number) => renderRefNumber(ref),
        },
        {
            title: 'Site',
            dataIndex: 'site.address',
            key: 'site.address',
            render: (_:undefined, record:RequestDataResponse) => renderSite(_, record),
        },
        {
            title: 'Requester',
            dataIndex: 'requester',
            key: 'requester',
            render: (_:undefined, record:RequestDataResponse) => renderRequester(_, record),
        },
        {
            title: 'Requested Period',
            dataIndex: 'requestedPeriod',
            key: 'requestedPeriod',
            render: (_:undefined, record:RequestDataResponse) => renderPeriod(_, record),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Date Submitted',
            dataIndex: 'dateSubmitted',
            key: 'dateSubmitted',
            render: (_:undefined, record:RequestDataResponse) => renderDateSubmitted(_, record),
        },
        {
            title: 'Approver',
            dataIndex: 'approver',
            key: 'approver',
            render: (_:undefined, record:RequestDataResponse) => renderApprover(_, record, userData),
        },
    ];

    const onSort = () => {
        let newDirection = null;
        if (sortDirection === null) {
            newDirection = 'desc';
        } else if (sortDirection === 'desc') {
            newDirection = 'asc';
        }
        setSortDirection(newDirection);
    };

    const updateSearchFilterParams = (params: DashboardFilterParams) => {
        setSearchTerms(params.searchTerms);
        setStartDate(params.startDate);
        setEndDate(params.endDate);
        setSiteText(params.siteText);
        setStatusText(params.statusText);
    };

    const handleCreateNewRequest = async () => {
        dispatch(createVisitRequest())
            .then(unwrapResult)
            .then((payload: VisitRequestFormResponsePayload) => {
                navigate(`/visitor-request/${payload.visitRequest.encryptedId}/step/1`);
            });
    };

    const handleExportClick = () => {
        setShowExportModal(true);
    };

    const handleQueryChange = (state: string | Array<string>, queryKey: string) => {
        if (typeof state === 'string') {
            if (isNull(state) || isEmpty(state)) {
                queryParams.delete(queryKey);
            } else {
                queryParams.set(queryKey, state);
            }
        } else if (isArray(state)) {
            if (isNull(state) || isEmpty(state)) {
                queryParams.delete(queryKey);
            } else {
                queryParams.set(queryKey, state.toString());
            }
        }
    };

    const onExport = (hourlyRate: DropdownOption) => {
        setShowExportModal(false);
        handleQueryChange(searchTerms, 'searchTerms');
        handleQueryChange(startDate, 'startDate');
        handleQueryChange(endDate, 'endDate');
        handleQueryChange(siteText, 'siteText');
        dispatch(exportCompletedVisits({
            hourlyRate: hourlyRate.value,
            site: siteText,
            q: searchTerms,
            startDate,
            endDate,
        }));
    };

    useEffect(() => {
        const { currentPage } = pagination;
        handleQueryChange(searchTerms, 'searchTerms');
        handleQueryChange(startDate, 'startDate');
        handleQueryChange(endDate, 'endDate');
        handleQueryChange(siteText, 'siteText');
        handleQueryChange(statusText, 'statusText');
        if (sortDirection) {
            handleQueryChange(sortDirection, 'sort');
        }

        handleSubmitRequest(currentPage);
        navigate({ pathname: '/dashboard', search: queryParams.toString() });
    }, [searchTerms, startDate, endDate, siteText, statusText, sortDirection]);

    return (
        <>
            <Modal
                open={showExportModal}
                onCancel={() => setShowExportModal(false)}
                footer={null}
                width={650}
                centered
                className="availability-modal">
                <HourlyRateModal
                    onCancel={() => setShowExportModal(false)}
                    onExport={onExport}
                    defaultOption={{ value: 'Standard Hourly', label: 'Standard Hourly' }} />
            </Modal>
            <Main
                className="login"
                title={<div>Dashboard</div>}
                hideSubHeader
                subHeaderTitle="Dashboard"
                subHeaderHasBackLink
                subHeaderBackLink="/"
                menuWidth={350}
                menuPlacement="right"
                toolbar={toolbar}
                showFooter={false}
                floatingHeader={false}
                footerContent=""
                floatingFooter={false}
                backLink={backLink}
                breadcrumb={navigation}>
                <div className="main-container dashboard">
                    <div>
                        <div className="request-card-content">
                            {requestCard.map(card => (
                                <div key={card.id} className="request-card">
                                    <div className="content">
                                        <span className="request-number">{card.number}</span>
                                        <span className="request-text">{card.title}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="request-button-container">
                            <h2>Requests</h2>
                            <Button type="primary" className="create-button" onClick={handleCreateNewRequest} loading={isCreating}>
                                <AddIcon />
                                <div style={{ padding: 10 }}>Create New Request</div>
                            </Button>
                        </div>
                        <div className="search-container">
                            <SearchFilter
                                statusFilter={statusList}
                                siteFilter={siteList}
                                onSort={() => onSort()}
                                onClear={() => setSortedData(null)}
                                isExport={Boolean(userData?.role?.accessLevel === ADMIN_USER || SCORCHSOFT_USER)}
                                onExport={handleExportClick}
                                searchParams={queryParams}
                                updateFilters={updateSearchFilterParams} />
                        </div>
                        <div>
                            <RequestTable
                                pagination={{
                                    currentPage: pagination.currentPage,
                                    pageSize: pagination.pageSize,
                                    total: pagination.total,
                                    onChange: (page) => {
                                        handleSubmitRequest(page);
                                        // scroll to top
                                        window.scrollTo({ top: 0, behavior: 'auto' });
                                    },
                                }}
                                filters={{
                                    searchTerms,
                                    startDate,
                                    endDate,
                                    siteText,
                                    statusText,
                                    sortDirection,
                                }}
                                loading={isFetching}
                                data={sortedData || requestData}
                                columns={tableColumns} />
                        </div>
                    </div>
                </div>
                <LockedRequestModal
                    isModalOpen={overriden === true}
                    handleCancel={handleOverruleModalClose}
                    lockedBy={lockedBy}
                    requestId={requestId} />
            </Main>
        </>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
