import React, { FC, useState, useEffect } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { LeftChevron, RightChevron, CloseIcon } from 'components/Elements/CustomSVGIcon';
import Checkbox from 'components/Elements/Checkbox';
import { Modal } from 'antd';
import type { HandleDateValues, SiteAvailability } from 'pages/VisitorRequestForm/DateSelectionForm/types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { setApplyToAllVisitors } from 'core/visitRequestForm/visitRequestFormSlice';
import FormSection from '../FormSection';
import { convertDateStringToRanges, convertRangesToDateString, categoriseDatesByMonth } from './calendarHelpers';
import './dateSelector.scss';
import AvailabilityModal from './AvailabilityModal';

interface ShiftSelected {
    _tempIndex?: number | undefined;
    blockDate?: string | undefined | null;
    id?: number | null;
    day?: string;
    date: string;
    shift: string | undefined;
    shiftType: number | string | undefined;
    startTime: string | undefined;
    endTime: string | undefined;
    bookingTimeCritical?: boolean;
    saved?: boolean;
}

interface DateSelectorProps {
    handleDatesSelect: (values: HandleDateValues) => void;
    selectedDates: string[];
    siteAvailability: SiteAvailability;
    holidays: string[];
    minDate: string;
    selectedShift: ShiftSelected[];
}

const DateSelector:FC<DateSelectorProps> = ({ handleDatesSelect, selectedDates, siteAvailability, holidays, minDate, selectedShift }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { applyToAllVisitors } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const [applyAllDates, setApplyAllDates] = useState<boolean>(applyToAllVisitors);
    const [includeWeekends, setIncludeWeekends] = useState<boolean>(true);
    const [includeBankHolidays, setIncludeBankHolidays] = useState<boolean>(true);
    const [showAvailabilityModal, setShowAvailabilityModal] = useState<boolean>(false);
    const today = new Date();
    const oneYearFromNow = new Date(today.getFullYear() + 1, today.getMonth(), 1);
    const minimumCalendarDate = new DateObject(minDate);
    const maximumCalendarDate = new DateObject(oneYearFromNow);

    useEffect(() => {
        const values = {
            dates: selectedDates,
            applyAllDates,
            includeWeekends,
            includeBankHolidays,
            startDate: [],
            endDate: [],
        };
        handleDatesSelect(values);
    }, [applyAllDates, includeWeekends, includeBankHolidays]);

    const handleApplyDatesChange = () => {
        setApplyAllDates(!applyAllDates);
        dispatch(setApplyToAllVisitors(!applyAllDates));
    };

    const handleWeekendsChange = () => {
        setIncludeWeekends(!includeWeekends);
    };

    const handleBankHolidaysChange = () => {
        setIncludeBankHolidays(!includeBankHolidays);
    };

    const handleViewAvailabilityClick = () => {
        setShowAvailabilityModal(true);
    };

    function formatDate(date: Date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    }
    const handleRemoveDate = (date: Date) => {
        const values = {
            dates: selectedDates.filter((selectedDate) => selectedDate !== moment(date).format('MM-DD-YYYY')),
            applyAllDates,
            includeWeekends,
            includeBankHolidays,
            startDate: [],
            endDate: [],
        };
        handleDatesSelect(values);
    };

    const onDateChange = (dates: DateObject[]) => {
        const newDates: string[] = convertRangesToDateString(dates);
        const dateRanges = convertDateStringToRanges(newDates);
        let startDate: string[] = [];
        let endDate: string[] = [];
        if (dateRanges.length > 0 && !newDates.includes('Invalid date')) {
            dateRanges.forEach((range) => {
                if (range[0] && range[1] && formatDate(range[0]) !== formatDate(range[1])) {
                    startDate.push(formatDate(range[0]));
                    endDate.push(formatDate(range[1]));
                } else {
                    startDate = [];
                    endDate = [];
                }
            });
        }

        const values = {
            dates: newDates,
            applyAllDates,
            includeWeekends,
            includeBankHolidays,
            startDate,
            endDate,
        };

        handleDatesSelect(values);
    };

    // @ts-expect-error type error in npm package for date ranges
    const dates: DateObject[] = convertDateStringToRanges(selectedDates);

    const calculateCurrentDate = (date: Date) => {
        const selectedShiftDate = selectedShift.find((shift) => shift.date === moment(date).format('YYYY-MM-DD'));
        if (selectedShiftDate) {
            const shiftStartTime = new Date(`${selectedShiftDate.date}T${selectedShiftDate.startTime}`);

            if (shiftStartTime < today) {
                return true;
            }
        }
        return false;
    };

    const getDatesSelected = () => {
        const categorisedDates = categoriseDatesByMonth(selectedDates);
        const datesSelected = categorisedDates.map((monthObject) => (
            <div key={monthObject.monthYear} className="date-month-container">
                <h3 className="month-label">{monthObject.monthYear}:</h3>
                <div className="month-dates">{monthObject.dates.map((date: Date) => (
                    <div className="day-wrapper" onClick={() => !calculateCurrentDate(date) && handleRemoveDate(date)}>
                        <span key={moment(date).format('Do')} className={calculateCurrentDate(date) ? 'month-date-disabled' : 'month-date'}>{moment(date).format('Do')}</span>
                        <div className="remove-date-wrapper">
                            <CloseIcon w={16} h={16} s={calculateCurrentDate(date) ? '#BDBDBD' : ''} />
                        </div>
                    </div>
                ))}
                </div>
            </div>
        ));
        return datesSelected;
    };

    return (
        <>
            <Modal
                open={showAvailabilityModal}
                onCancel={() => setShowAvailabilityModal(false)}
                footer={null}
                width={810}
                className="availability-modal">
                <AvailabilityModal siteAvailability={siteAvailability} holidays={holidays} />
            </Modal>
            <FormSection title="Select Dates">
                <div className="select-dates-calendar-wrapper">
                    <Calendar
                        className="date-selection-calendar-container"
                        multiple
                        range
                        highlightToday={false}
                        value={dates}
                        onChange={onDateChange}
                        monthYearSeparator=" "
                        renderButton={(direction: string, handleClick: React.MouseEventHandler<HTMLButtonElement>) => (
                            <button className="calendar-arrow" type="button" onClick={handleClick}>
                                {direction === 'right' ? <RightChevron /> : <LeftChevron />}
                            </button>
                        )}
                        minDate={minimumCalendarDate}
                        maxDate={maximumCalendarDate} />
                </div>
                <div className="view-link">
                    <span onClick={handleViewAvailabilityClick}>View Open Days & Upcoming Bookings</span>
                </div>
                <div className="selected-bookings-container">
                    <div className="dates-selected-container">
                        <h3>Dates selected</h3>
                        <div className="list-container">{getDatesSelected()}</div>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={applyAllDates} onClick={handleApplyDatesChange} />
                            <span className="checkbox-label">Apply all selected dates for each visitor added</span>
                        </div>
                    </div>
                    <div className="bulk-bookings-container">
                        <h3>Include in Bulk Bookings</h3>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={includeWeekends} onClick={handleWeekendsChange} />
                            <span className="checkbox-label">Weekends</span>
                        </div>
                        <div className="checkbox-wrapper">
                            <Checkbox checked={includeBankHolidays} onClick={handleBankHolidaysChange} />
                            <span className="checkbox-label">Bank Holidays</span>
                        </div>
                    </div>
                </div>
            </FormSection>
        </>

    );
};

export default DateSelector;
