import React, { FC } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { LeftChevron, RightChevron } from 'components/Elements/CustomSVGIcon';
import { DailyVisit } from 'pages/SecurityDashboard/types';
import Tooltip from 'components/Elements/Tootltip';
import './securityCalendar.scss';

interface SecurityCalendarProps {
    visitData: DailyVisit[];
}

const SecurityCalendar: FC<SecurityCalendarProps> = ({ visitData }) => {
    const mapADay = (date: DateObject) => {
        const markedDates = visitData.filter((visit) => visit.startDate === date.format('YYYY-MM-DD'));
        const isMarkedDate = markedDates.length > 0 && date.format('YYYY-MM-DD') !== new Date().toISOString().split('T')[0];

        let tooltip = null;

        if (isMarkedDate && markedDates.length > 0) {
            tooltip = (
                <div>
                    {markedDates.map((markedDate) => (
                        <div>
                            <span>{markedDate?.shift}</span>
                            <br />
                            <span>{markedDate?.timeSlot}</span>
                        </div>
                    ))}
                </div>
            );
        }

        const markedDateJsx = (
            <div className="marked-date-container">
                <Tooltip title={tooltip} placement="top">
                    <span>{date.day}</span>
                    <div className="red-dot" />
                </Tooltip>
            </div>
        );
        return { children: isMarkedDate ? markedDateJsx : <span>{date.day}</span> };
    };

    return (
        <div className="security-calendar-container">
            <Calendar
                className="security-calendar"
                readOnly
                showOtherDays
                monthYearSeparator=" "
                renderButton={(direction: string, handleClick: React.MouseEventHandler<HTMLButtonElement>) => (
                    <button className="calendar-arrow" type="button" onClick={handleClick}>
                        {direction === 'right' ? <RightChevron w={7} h={14} /> : <LeftChevron w={7} h={14} />}
                    </button>
                )}
                mapDays={(date) => mapADay(date.date)} />
        </div>
    );
};

export default SecurityCalendar;
