import React, { FC, useState } from 'react';
import Table from 'components/Elements/Table';
// import { Table } from 'antd';
import { DailyVisit } from 'pages/SecurityDashboard/types';
import './securityVisitList.scss';
import Tooltip from 'components/Elements/Tootltip';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import moment from 'moment';
import { securityDashboardRequest } from 'core/securityDashboard/securityDashboardApi';
import ViewDetailsModal from '../SecurityInfoBlock/ViewDetailsModal';

export interface SecurityVisitListProps {
    visitData: DailyVisit[];
}

const SecurityVisitList: FC<SecurityVisitListProps> = ({ visitData }) => {
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<DailyVisit | null>(null);

    const { isFetching, pagination } = useSelector((state: CombinedStates) => state.securityDashboard);
    const dispatch = useDispatch<AppDispatch>();

    const handleRowClick = (record: DailyVisit) => {
        setModalData(record);
        setShowDetailsModal(true);
    };

    const handleCancel = () => {
        setShowDetailsModal(false);
    };

    const columns = [
        {
            title: 'Ref',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Site',
            dataIndex: 'siteName',
            key: 'siteName',
            width: 200,
        },
        {
            title: 'Number of Visitors',
            dataIndex: 'visitorsCount',
            key: 'visitorsCount',
            width: 200,
        },
        {
            title: 'Starting Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 170,
            render: (text: string) => <span>{moment(text).format('D MMMM YYYY')}</span>,
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
            width: 170,
            render: (text: string, record: DailyVisit) => (
                <>
                    <p style={{ margin: 0 }}>{record.shift}</p>
                    <p style={{ margin: 0 }}>{record.timeSlot}</p>
                </>
            ),
        },
        {
            title: 'Area Access',
            dataIndex: 'areaAccess',
            key: 'areaAccess',
            width: 180,
        },
        {
            title: 'Work Details',
            dataIndex: 'workDetails',
            key: 'workDetails',
            render: (text: string) => <Tooltip title={text}><p className="work-details">{text}</p></Tooltip>,
        },
    ];

    return (
        <>
            <ViewDetailsModal
                open={showDetailsModal}
                handleCancel={handleCancel}
                data={modalData} />
            <div className="security-visit-list-container">
                <Table
                    loading={isFetching}
                    data={visitData}
                    columns={columns}
                    // paginationSize={20}
                    pagination={{
                        // defaultPageSize: pagination.pageSize,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        currentPage: pagination.currentPage,
                        onChange: (page: number) => {
                            dispatch(securityDashboardRequest({ page }));
                        },
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            handleRowClick(record);
                        },
                    })} />
            </div>
        </>

    );
};

export default SecurityVisitList;
