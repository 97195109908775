import React, { FC } from 'react';
import TextInput from 'components/Elements/TextInput';
import type { UploadFile } from 'antd/lib/upload/interface';
import './workDetails.scss';
import Tooltip from 'components/Elements/Tootltip';
import FileUploader from '../FileUploader';
import { WarningIcon } from '../../Elements/CustomSVGIcon';
import FormSection from '../FormSection';
import UploadedFileList from '../UploadedFilesList';

interface WorkDetailsProps {
    descriptionOfWorks: string;
    workReference: string;
    comments: string;
    onWorkReferenceChange: (value: string) => void;
    onFileUploadChange: (files: UploadFile) => void;
    onCommentsChange: (value: string) => void;
    onDescriptionOfWorksChange: (value: string) => void;
}

const WorkDetails: FC<WorkDetailsProps> = ({
    descriptionOfWorks,
    workReference,
    comments,
    onWorkReferenceChange,
    onFileUploadChange,
    onCommentsChange,
    onDescriptionOfWorksChange,
}) => {

    const handleDescriptionOfWorksChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onDescriptionOfWorksChange(e.target.value);
    };

    const handleReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onWorkReferenceChange(e.target.value);
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onCommentsChange(e.target.value);
    };

    const handleFileUploadChange = (files: UploadFile[]) => {
        const lastFile = files[files.length - 1];
        if (lastFile) {
            onFileUploadChange(lastFile);
        }
    };

    return (
        <FormSection title="Work Details">
            <div className="work-details-container">
                <h5 className="heading margin-5">Brief Description of Works</h5>
                <textarea
                    className="description-textarea"
                    value={descriptionOfWorks}
                    placeholder="Please provide a description of the works you will be completing"
                    onChange={handleDescriptionOfWorksChange} />
                <div className="heading-wrapper">
                    <h5 className="heading">Work Reference</h5>
                    <Tooltip title="Please enter a Works Ref. This can be in the format of the following: INC, FLT, NET or CRQ etc.">
                        <div className="warning-icon-wrapper">
                            <WarningIcon />
                        </div>
                    </Tooltip>
                </div>
                <div className="reference-wrapper">
                    <TextInput onChange={handleReferenceChange} value={workReference} />
                </div>
                <h5 className="heading margin-5">Notes or Comments</h5>
                <textarea
                    className="description-textarea"
                    value={comments}
                    placeholder="Please provide any additional notes or information here"
                    onChange={handleNotesChange} />
                <h5 className="heading margin-8">Supporting Documentation</h5>
                <FileUploader onChange={handleFileUploadChange} />
                <UploadedFileList />
            </div>
        </FormSection>
    );
};

export default WorkDetails;
