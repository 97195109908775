import React, { FC } from 'react';
import './csvUploader.scss';
import { Button, Upload, message } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import { DownloadIcon, UploadIcon } from 'components/Elements/CustomSVGIcon';
import { CSVUploaderProps } from './types.s';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

/* eslint-disable @typescript-eslint/no-var-requires */
const form = require('assets/visitor_form.csv');

const beforeUpload = (file: FileType): Promise<boolean> => {
    const isCsv = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
    if (!isCsv) {
        message.error('You can only upload CSV file!');
        return Promise.reject(new Error('Invalid file type!'));
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const contents = e.target?.result as string;

            // remove "" from the csv file
            const sanitizedContents = contents.replace(/"/g, '');

            let lines = sanitizedContents.split(/\r?\n/);

            // Remove the header
            if (lines.length > 0 && lines[0]?.startsWith('Full Name')) {
                lines = lines.slice(1);
            }

            // Remove any empty lines
            lines = lines.filter(line => line.trim() !== '');

            const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const isValid = lines.every(line => {
                const entries = line.split(',').map(entry => entry.trim()) as string[];

                const validName = entries[0] ? entries[0]?.length > 0 : false;
                const validPhone = entries[1] ? entries[1]?.length > 0 : false;
                const emailValid = entries[2] ? emailRegEx.test(entries[2]) : false;
                const validCompany = entries[3] ? entries[3]?.length > 0 : false;

                // Check if the email is valid and all required fields are filled.
                return emailValid && validName && validPhone && validCompany;
            });

            if (isValid) {
                resolve(true);
            } else {
                message.error('The CSV file has invalid or empty required entries!');
                reject(new Error('Invalid CSV file!'));
            }
        };
        reader.onerror = () => {
            message.error('Error reading the file!');
            reject(new Error('Error reading file!'));
        };
        reader.readAsText(file);
    });
};

const CSVUploader:FC<CSVUploaderProps> = ({ onUpload }) => {
    const downloadFile = () => {
        const fileUrl = form;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'Visitor_Form.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (file: any) => {
        const actualFileObject = file.file;

        if (actualFileObject) {
            const formData = new FormData();
            formData.append('csv_file', actualFileObject);
            onUpload(formData);
        }
    };

    return (
        <div className="upload-container">
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleChange}>
                <div>
                    <UploadIcon />
                    <div>
                        Upload .CSV of Visitor Data
                    </div>
                </div>
            </Upload>
            <Button className="csv-downloader"
                style={{ height: 102 }}
                type="dashed"
                onClick={() => downloadFile()}>
                <div>
                    <DownloadIcon />
                    <div>
                        Download .CSV Form
                    </div>
                </div>
            </Button>
        </div>

    );
};
export default CSVUploader;
