import React, { useState } from 'react';
import { Button, Input, Collapse, Popconfirm } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddCircleIcon, CheckboxCheckedIcon, CheckboxUncheckedIcon, ChevronDownIcon, EditIcon, ChevronUpIcon, BinIcon, FileIcon, WarningReviewIcon } from 'components/Elements/CustomSVGIcon';
import './FormDetails.scss';
import PropTypes from 'prop-types';
import { DateTime, VisitorRequestReview } from 'core/visitRequestReview/types';
import type { CollapseProps } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { addComment, downloadAttachment } from 'core/visitRequestReview/visitRequestReviewApi';
import { VisitorRequest } from 'core/visitRequestForm/types';
import { AppDispatch } from '../../../core/types';

interface FormDetailsProps {
    details: VisitorRequestReview | VisitorRequest;
    onPressTerm?: () => void;
    id?: string | undefined;
    signedBy?: string;
    onAcceptTerm?: (value: boolean) => void;
    onRemoveDate?: (id: number) => void;
    amending?: boolean;
    encryptedId?: string;
    isCostApproverByPass?: boolean;
    hasCostApprove?: boolean;
}

const FormDetails: React.FC<FormDetailsProps> = ({ onPressTerm, details, id, signedBy, onAcceptTerm, onRemoveDate, isCostApproverByPass, hasCostApprove }) => {
    const [showAddComment, setShowAddComment] = useState<boolean>(false);
    const [newComment, setNewComment] = useState<string>('');
    const [isSigned, setIsSigned] = useState(false);

    const navigation = useNavigate();
    const { state: navigationState } = useLocation();
    const url = new URL(window.location.href);
    const { pathname } = url;
    const visitorRequestForm = pathname.includes('visitor-request');
    const dispatch = useDispatch<AppDispatch>();

    const numberOfShifts = details?.dateAndTime?.reduce((acc, group) => acc + group.shifts.length, 0);

    const handleSignDeclaration = () => {
        setIsSigned(!isSigned);
        if (onAcceptTerm) {
            onAcceptTerm(!isSigned);
        }
    };

    // const handleRemoveDocument = (docid: number) => {
    //     console.log('Remove document', docid);
    // };

    const handleAddCommentClick = () => {
        setShowAddComment(!showAddComment);
    };

    const handleCancelCommentClick = () => {
        setShowAddComment(false);
    };

    const handleNewCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewComment(e.target.value);
    };

    const handleSubmitNewComment = () => {
        if (details.encryptedId && newComment) {
            dispatch(addComment({ id: details.encryptedId.toString(), newComment }));
        }
        setNewComment('');
        setShowAddComment(false);
    };

    const renderIcon = (isActive: boolean | undefined) => {
        if (isActive) {
            return <ChevronUpIcon />;
        }
        return <ChevronDownIcon />;
    };

    const renderExtraIcon = (step:number) => {
        if (visitorRequestForm) {
            return (
                <Button type="link" className="edit-button" onClick={() => navigation(`/visitor-request/${id}/step/${step}`, { state: navigationState })}>
                    <EditIcon />
                </Button>
            );
        }
        return null;
    };

    const handleAttachmentClick = (_id: number | undefined | string, name: string) => {
        if (id && _id) {
            dispatch(downloadAttachment({
                id: id.toString(),
                attachmentId: _id.toString(),
                fileName: name,
            }));
        }
    };

    const dateAndTime: CollapseProps['items'] = details?.dateAndTime && details.dateAndTime.map((group: DateTime) => ({
        label: group.shiftType,
        key: group.shiftType,
        children: group.shifts.map((shift) => (
            <div key={shift.id}>
                <div className="date-time-content">
                    <div className="date-shift-content">
                        <div className="date-shift-day">
                            <div style={{ width: '30%' }}>{shift.times.dates}</div>
                            <div className="single-shift">{shift.times.startTime} - {shift.times.endTime}</div>
                            {visitorRequestForm && (
                                <div className="date-extra-button">
                                    {renderExtraIcon(2)}
                                    <Popconfirm
                                        disabled={numberOfShifts === 1 ?? true}
                                        title="Are you sure you want to remove this date?"
                                        onConfirm={() => onRemoveDate && onRemoveDate(shift.id)}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button type="link" className="bin-button">
                                            <BinIcon s={numberOfShifts === 1 ? '#C6C6C6' : '#D90429'} />
                                        </Button>
                                    </Popconfirm>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )),
    })) || [];

    const renderVisitorsList = (visitorDetail: VisitorRequestReview['visitors']) => {
        let orderedVisitors: VisitorRequestReview['visitors'] = [];

        if (visitorDetail && visitorDetail.length !== 0) {
            orderedVisitors = [...visitorDetail].sort((a, b) => a.name.localeCompare(b.name));
        }

        return (
            <div className="visitor-list-wrapper">
                <ul className="review-visitor-list">
                    {orderedVisitors?.map((visitor) => <li key={visitor.id}>{visitor.name}</li>)}
                </ul>
            </div>
        );
    };

    const renderVisitors = (visitorDetail: VisitorRequestReview['visitors']) => {
        return (
            <div className="visitor-content-container">
                <div className="visitor-content-header-row">
                    <div className="detail-item">
                        Visitors
                    </div>
                    <div className="detail-item">
                        Check-in Time
                    </div>
                    <div className="detail-item">
                        Check Out Time
                    </div>
                </div>
                {visitorDetail?.map((detail) => (
                    <div className="visitor-content-row">
                        <div className="detail-item" key={detail.id}>
                            {detail?.name}
                        </div>
                        <div className="detail-item" key={detail.id}>
                            {detail?.lastCheckedIn !== '-' ? dayjs(detail?.lastCheckedIn).format('hh:mm A') : detail?.lastCheckedIn}
                        </div>
                        <div className="detail-item" key={detail.id}>
                            {detail?.lastCheckedOut !== '-' ? dayjs(detail?.lastCheckedOut).format('hh:mm A') : detail?.lastCheckedOut}
                        </div>
                    </div>
                ))}
            </div>
        );

        const totalItems = visitorDetail?.length || 0;
        if (totalItems <= 4) {
            return (
                <div className="visitor-content-container">
                    <div className="column">
                        {visitorDetail?.map((detail) => (
                            <div className="detail-item" key={detail.id}>
                                {detail?.name}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        const itemsPerColumn = Math.ceil(totalItems / 2);
        const visotorsColumn1 = visitorDetail?.slice(0, itemsPerColumn);
        const visotorsColumn2 = visitorDetail?.slice(itemsPerColumn);

        return (
            <div className="visitor-content-container">
                <div className="column">
                    {visotorsColumn1?.map((detail) => (
                        <div className="detail-item" key={detail.id}>
                            {detail?.name}
                        </div>
                    ))}
                </div>
                <div className="column">
                    {visotorsColumn2?.map((detail) => (
                        <div className="detail-item" key={detail.id}>
                            {detail?.name}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSelectedSite = (selectedSite: VisitorRequestReview['site']) => (
        <div>
            <div className="detail-item">
                <div className="detail-label">
                    Site Type:
                </div>
                <div className="detail-value">
                    {selectedSite?.type}
                </div>
            </div>
            <div className="detail-item">
                <div className="detail-label">
                    Site Address:
                </div>
                <div className="detail-value">
                    {selectedSite?.fullAddress}
                </div>
            </div>
        </div>
    );

    const renderVisitorsOnSite = (visitorsOnSite: VisitorRequestReview['lastCheckedIn']) => (
        <div className="rounded-container">
            <table>
                <thead>
                    <tr>
                        <th>Visitors</th>
                        <th>Check-in Time</th>
                        <th>Check-out Time</th>
                    </tr>
                </thead>
                <tbody>
                    {visitorsOnSite.map((visitor) => (
                        <tr key={visitor.visitorName}>
                            <td>{visitor.visitorName}</td>
                            <td>{visitor.lastCheckedIn}</td>
                            <td>{visitor.lastCheckedOut}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const renderWorkType = () => (
        <div>
            <div className="detail-item">
                <div className="detail-label">
                    Work Types:
                </div>
                <div className="detail-value">
                    {details.workTypeName}
                </div>
            </div>
            <div className="detail-item">
                <div className="detail-label">
                    Description of Works:
                </div>
                <div className="detail-value">
                    {details.workDetails?.description || 'N/A'}
                </div>
            </div>
            <div className="detail-sub-item">
                <div className="detail-label">
                    Supporting Documentation
                </div>
                <div className="detail-sub-value">
                    {details.attachments ? (
                        details.attachments.map((attachment) => (
                            <div className="file-detail-content" key={attachment.id} onClick={() => handleAttachmentClick(attachment?.id, attachment.originalFilename)}>
                                <div className="file-detail">
                                    <div className="file-name">
                                        <FileIcon />
                                        <span>{attachment.originalFilename}</span>
                                    </div>
                                    {/* <div className="remove-file-icon" onClick={() => handleRemoveDocument(attachment.id)}>
                                        <CancelIcon s="#C0D1D8" />
                                    </div> */}
                                    <div className="border" />
                                </div>
                            </div>
                        ))
                    ) : ('N/A')}
                </div>
            </div>
        </div>
    );

    const renderComment = (comments: VisitorRequestReview['comments']) => (
        comments?.map((comment) => (
            <div className="content-container" key={comment.id}>
                <div className="detail-item">
                    <div className="detail-label">
                        <span>{comment.commenter}:</span>
                    </div>
                    <div className="detail-value">
                        <span>{comment.comment}</span>
                    </div>
                </div>
                <div className="detail-comment-time">
                    <span>{comment.createdDate} at {comment.createdTime}</span>
                </div>
            </div>
        ))
    );

    return (
        <div style={{ display: 'grid', justifyContent: 'center', paddingBottom: 100, position: 'relative' }}>
            <div className="form-header">
                <h1>{visitorRequestForm ? 'Review Visitor Request' : 'Full Request Details'}</h1>
                {(!isCostApproverByPass && !hasCostApprove && !visitorRequestForm) && (<div className="cost-approver-bypass"> <WarningReviewIcon /> <div className="cost-approver-bypass-text">Cost Approval is Required</div></div>)}
            </div>
            <div className="detail-content">
                <div className="header-form-container">
                    <h2>Selected Site</h2>
                </div>
                <div className="content-container">
                    {renderSelectedSite(details.site)}
                </div>
            </div>

            { ('lastCheckedIn' in details && details.lastCheckedIn.length > 0) && (
                <div className="detail-content">
                    <div className="header-form-container">
                        <h2>Visitors on Site</h2>
                        {renderExtraIcon(4)}
                    </div>
                    <div className="content-container">
                        {renderVisitorsOnSite(details.lastCheckedIn)}
                    </div>
                </div>
            )}

            <div className="detail-content">
                <div className="header-form-container">
                    <h2>Visitor Added</h2>
                    {renderExtraIcon(3)}
                </div>
                {onAcceptTerm ? renderVisitorsList(details.visitors) : renderVisitors(details.visitors)}
            </div>

            <div className="detail-content">
                <h2>Dates and Times</h2>
                <div className="content-container">
                    <Collapse
                        expandIconPosition="end"
                        expandIcon={({ isActive }) => renderIcon(isActive)}
                        items={dateAndTime} />
                    <div className="total-hour">
                        <div>Total Hours Billed:&nbsp;</div>
                        <div>{details.totalHours}</div>
                    </div>
                </div>
            </div>

            <div className="detail-content">
                <div className="header-form-container">
                    <h2>Work Type</h2>
                    {renderExtraIcon(5)}
                </div>
                <div className="content-container">
                    {renderWorkType()}
                </div>
            </div>
            {!visitorRequestForm ? (
                <div className="detail-content">
                    <h2>Comments</h2>
                    <div className="comments-content">
                        {renderComment(details.comments)}
                        {showAddComment ? (
                            <div>
                                <h3 className="heading">Add your comment here</h3>
                                <textarea className="comment-textarea" onChange={handleNewCommentChange} />
                                <div className="button-wrapper">
                                    <button className="save-button" type="button" onClick={handleSubmitNewComment}>Submit</button>
                                    <button className="cancel-button" type="button" onClick={handleCancelCommentClick}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <div className="add-comment" onClick={handleAddCommentClick}>
                                <div className="icon-wrapper">
                                    <AddCircleIcon />
                                </div>
                                <span className="add-comment-text">Add a Comment</span>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="declaration-content">
                    <div className="grey-background" />
                    <h2>Declaration</h2>
                    <div className="content-container">
                        <div className="sign-item">
                            <div className="detail-label">
                                <div onClick={handleSignDeclaration}>
                                    {isSigned ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
                                </div>
                                <span style={{ paddingLeft: 10 }}>I declare that all applicants understand and accept the Vodafone{'\n'}
                                    <span className="term-condition" onClick={() => onPressTerm && onPressTerm()} style={{ fontWeight: 700, textDecorationLine: 'underline', cursor: 'pointer' }}>
                                        Terms and Conditions
                                    </span> of access.
                                </span>
                            </div>
                            <div className="signed-container">
                                <div className="signed-title">Signed</div>
                                <Input
                                    disabled
                                    value={signedBy}
                                    style={{
                                        fontSize: 18,
                                        width: 400,
                                        backgroundColor: '#EDF2F4',
                                        border: 'none' }} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

FormDetails.defaultProps = {
    onPressTerm: () => console.log('onPressTerm'),
    id: undefined,
    signedBy: '',
    onAcceptTerm: () => console.log('onAcceptTerm'),
    onRemoveDate: () => console.log('onRemoveDate'),
    amending: false,
    encryptedId: '',
    isCostApproverByPass: false,
    hasCostApprove: false,
};

FormDetails.propTypes = {
    onPressTerm: PropTypes.func,
    id: PropTypes.string,
    signedBy: PropTypes.string,
    onAcceptTerm: PropTypes.func,
    onRemoveDate: PropTypes.func,
    amending: PropTypes.bool,
    encryptedId: PropTypes.string,
    isCostApproverByPass: PropTypes.bool,
    hasCostApprove: PropTypes.bool,
};

export default FormDetails;
