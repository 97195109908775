import React, { useState, useEffect, useRef, FC } from 'react';
import { CompletedStatusIcon, FirstStatusIcon, InactiveStatusIcon } from 'components/Elements/CustomSVGIcon';
import './status.scss';

interface StatusProps {
    labels: string[];
    currentStatus: number;
}

const Status: FC<StatusProps> = ({ labels, currentStatus }) => {
    const [componentWidth, setComponentWidth] = useState<number>(0);
    const statusRef = useRef<HTMLDivElement>(null);

    const statusLength: number = labels.length;

    useEffect(() => {
        if (statusRef.current) {
            const stepperWidth: number = statusRef.current.offsetWidth;
            setComponentWidth(stepperWidth);
        }
    });

    const renderStatus = (label: string, index: number): JSX.Element => {
        const statusWidth: number = componentWidth ? componentWidth / statusLength : 150;
        const ICON_WIDTH = 25;
        const ICON_HEIGHT = 20;
        const getIcon = (): JSX.Element => {
            if (index < currentStatus && index !== 0) {
                return <CompletedStatusIcon />;
            }
            if (index === 0) {
                return <FirstStatusIcon />;
            }
            return <InactiveStatusIcon />;
        };

        return (
            <div className="status-wrapper" key={index} style={{ width: `${statusWidth}px` }}>
                <div className="status-graphic">
                    <div className="icon-wrapper" style={{ height: `${ICON_HEIGHT}px`, width: `${ICON_WIDTH}px` }}>{getIcon()}</div>
                </div>
                <span className="status-label" style={{ color: index > currentStatus - 1 ? '#C0D1D8' : '#FFFFFF' }}>{label}</span>
            </div>
        );
    };

    return <div className="status-container" ref={statusRef}>{labels.map((label, index) => renderStatus(label, index))}</div>;
};

export default Status;
