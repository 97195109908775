import React, { FC, useState, useEffect } from 'react';
import './changeSelectedDateModal.scss';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { LeftChevron, RightChevron } from 'components/Elements/CustomSVGIcon';
import { useSelector } from 'react-redux';
import { CombinedStates } from 'core/types';

export interface ChangeSelectedDateModalProps {
    selectedDateId: number | null;
    onSave: (newDate: string) => void;
}

const ChangeSelectedDateModal: FC<ChangeSelectedDateModalProps> = ({ selectedDateId, onSave }) => {
    const [chosenDate, setChosenDate] = useState<string>('');

    const { visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { dates } = visitRequest;

    const today = new Date();
    const oneYearFromNow = new Date(today.getFullYear() + 1, today.getMonth(), 1);
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    const minimumCalendarDate = new DateObject(tomorrow);
    const maximumCalendarDate = new DateObject(oneYearFromNow);

    useEffect(() => {
        const originalDate = dates.find((date) => date.id === selectedDateId);
        if (originalDate) {
            setChosenDate(originalDate.date);
        }
    }, [selectedDateId]);

    const selectedDates = dates.filter((date) => date.id !== selectedDateId).map((date) => date.date);

    const handleSaveClick = () => {
        onSave(chosenDate);
    };

    const mapADay = (date: DateObject) => {
        const isAlreadyTaken = selectedDates.includes(date.format('YYYY-MM-DD'));
        const isSelected = chosenDate === date.format('YYYY-MM-DD');
        const handleNewDayClick = () => {
            setChosenDate(date.format('YYYY-MM-DD'));
        };

        if (isSelected) {
            return { children: <span className="selected-day">{date.day}</span> };
        }
        if (isAlreadyTaken) {
            return { children: <span className="taken-day">{date.day}</span> };
        }
        return { children: <div onClick={handleNewDayClick}><span className="available-day">{date.day}</span></div> };
    };

    if (!selectedDateId) {
        return null;
    }

    return (
        <div className="change-selected-date-container">
            <h1 className="heading">Edit Selected Date</h1>
            <div className="calendar-container">
                <Calendar
                    className="availability-calendar"
                    readOnly
                    highlightToday={false}
                    mapDays={(date) => mapADay(date.date)}
                    showOtherDays
                    monthYearSeparator=" "
                    renderButton={(direction: string, handleClick: React.MouseEventHandler<HTMLButtonElement>) => (
                        <button className="calendar-arrow" type="button" onClick={handleClick}>
                            {direction === 'right' ? <RightChevron /> : <LeftChevron />}
                        </button>
                    )}
                    minDate={minimumCalendarDate}
                    maxDate={maximumCalendarDate} />

            </div>
            <div className="button-wrapper">
                <button onClick={handleSaveClick} type="button">Save Changes</button>
            </div>
        </div>
    );
};

export default ChangeSelectedDateModal;
