export const ADMIN_USER = 'admin';
export const SCORCHSOFT_USER = 'scorchsoft-admin';
export const SECURITY_USER = 'security';
export const APPROVER_USER = 'approver';
export const REQUESTER_USER = 'requester';
export const ADMIN_ACCESS_LEVELS = [SCORCHSOFT_USER, ADMIN_USER];

export const PENDING_COST_APPROVAL = 'Pending Cost Approval';

export const VISITOR_REQUEST_STATUS = {
    rejected: {
        statusId: 2,
        title: 'Reject Request',
        subTitle: 'Reason for rejection',
        description: 'You are about to reject this visitor request. Please enter the reason for the rejection below. The requester will then be able to make amendments to the request and resubmit the form.',
        confirmButton: 'Reject Request',
        cancelButton: 'Close',
        action: 'reject',
        placeholder: 'Please enter your reasoning for rejecting the request',
    },
    query: {
        statusId: 2,
        title: 'Query Request',
        subTitle: 'Question for requester',
        confirmButton: 'Send',
        cancelButton: 'Close',
        action: 'query',
        placeholder: 'Please enter your query',
    },
    confirm_approval: {
        statusId: 3,
        title: 'Confirm Approval',
        description: 'Are you sure you want to approve this visitor request?',
        confirmButton: 'Approve Request',
        cancelButton: 'Return to Request',
        action: 'approve',
    },
    cancel: {
        statusId: 2,
        title: 'Cancel Request',
        subTitle: 'Reason for cancellation',
        description: 'You are about to cancel this request. Please enter the reason for the cancelation below. Cancelling the request is permanent and a new request will have to be submitted if you change your mind.',
        confirmButton: 'Cancel Request',
        cancelButton: 'Return to Request',
        action: 'cancel',
        placeholder: 'Please enter your reasoning for cancelling the request',
    },
    cost_approval: {
        statusId: 1,
        title: 'Cost Approval',
        description: 'Are you sure you want to approve this visitor request?',
        confirmButton: 'Approve Request',
        cancelButton: 'Return to Request',
        action: 'cost_approve',
    },
};

export const mockViewData = {
    selectedSite: {
        id: 1,
        name: 'Selected Site',
        details: [
            {
                id: 1,
                label: 'Site Type',
                value: 'MTX',
            },
            {
                id: 2,
                label: 'Site Address:',
                value: '23 Street Name, Birmingham, B1 223',
            },
        ],
    },
    visitorsAdded: {
        id: 2,
        name: 'Visitors Added',
        type: 'list',
        details: [{
            id: 1,
            value: 'John Smith',
        },
        {
            id: 2,
            value: 'John Taylor',
        },
        {
            id: 3,
            value: 'Lucy Jones',
        },
        {
            id: 4,
            value: 'John Smith',
        },
        {
            id: 5,
            value: 'John Taylor',
        },
        {
            id: 6,
            value: 'Lucy Jones',
        },
        {
            id: 11,
            value: 'John Smith',
        },
        {
            id: 12,
            value: 'John Taylor',
        },
        {
            id: 13,
            value: 'Lucy Jones',
        },
        {
            id: 14,
            value: 'John Smith',
        },
        {
            id: 15,
            value: 'John Taylor',
        }],
    },
    dates: {
        id: 3,
        name: 'Dates & Times',
        type: 'accordion',
        details: [{
            id: 1,
            label: 'Day Shift',
            value: 'Thursday 5th March',
            timeSlot: '9 AM - 5 PM',
        },
        {
            id: 2,
            label: 'Night Shift',
            value: 'Thursday 15th March',
            timeSlot: '7 PM - 7 AM',
        },
        {
            id: 3,
            label: '24/7',
            shiftStart: '12 PM',
            shiftEnd: '18 PM',
            value: ['Thursday 5th March',
                'Thursday 15th March',
                'Thursday 25th March',
                'Friday 6th March',
                'Sunday 18th March'],
        }],
    },
    work: {
        id: 4,
        name: 'Work Type',
        details: [{
            id: 1,
            label: 'Work Types:',
            value: 'Parent Work Type',
        }, {
            id: 2,
            label: 'Description of Works:',
            value: 'Lorem ipsum dolor sit amet consectetur. Sed facilisis laoreet felis et eleifend pellentesque. Aliquam amet tellus cursus dui cras penatibus.',
        }],
    },
    comments: {
        id: 5,
        name: 'Comments',
        type: 'comment',
        details: [{
            id: 1,
            label: 'John Smith',
            value: 'Lorem ipsum dolor sit amet consectetur. Sed facilisis laoreet felis et eleifend pellentesque. Aliquam amet tellus cursus dui cras penatibus.',
            time: '01/02/2023 at 11:32 AM',
        }],
    },
};
