import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import '@fontsource/nunito-sans/800.css';
import '@fontsource/nunito-sans'; // Defaults to weight 400
import '@fontsource/nunito-sans/700.css'; // weight 700
import '@fontsource/nunito-sans/600.css'; // weight 800
import Routes from './core/routes';
import DebugWrapper from './core/utils/debug';
import { setVersion } from './core/global/globalSlice';
import './styles/style.scss';
/** Actions: The necessary actions for dispatching our bootstrap values */
// import { setVersion } from './core/global/globalSlice';

/** configureStore */
import { store, persistor } from './core/store';

/** The version of the app but not displayed yet */
import pack from '../package.json';

const VERSION = pack.version;

const VersionCheck = () => {
    const dispatch = useDispatch();
    dispatch(setVersion(VERSION));
    return null;
};

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
    <DebugWrapper>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <VersionCheck />
                <BrowserRouter
                    future={{ v7_startTransition: true }}
                    basename="/">
                    <Routes />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </DebugWrapper>,
);
