/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { dashboardVisitRequest, dashboardFilterRequest, exportCompletedVisits } from './dashboardApi';
import { DashboardState, DataProps, RequestDataResponse } from './types';

const emptyRequestState: RequestDataResponse = {
    encryptedId: '',
    ref: 0,
    site: {
        address: '',
        id: 0,
    },
    requestedPeriod: {
        earliest: {
            date: '',
            startTime: '',
            endTime: '',
        },
        latest: {
            date: '',
            startTime: '',
            endTime: '',
        },
    },
    requester: {
        name: '',
        email: '',
        role: {},
    },
    status: {},
    dateSubmitted: '',
    approver: {
        name: '',
        email: '',
        role: {},
    },
    vodafoneMitieApprover: {
        name: '',
        email: '',
        role: {},
    },
    currentStep: 0,
};

const initialState = {
    error: null,
    isFetching: false,
    requestData: [emptyRequestState],
    pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
    },
    summaryData: {
        approved: 0,
        completed: 0,
        pending: 0,
        rejectedQueried: 0,
    },
    filterData: {
        statuses: {},
        sites: [],
    },
} as DashboardState;

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetPagination: (state: DashboardState) => {
            state.pagination = {
                currentPage: 1,
                pageSize: 20,
                total: 0,
            };
        },
    },
    extraReducers: (builder) => {
        // Visit request data
        builder
            .addCase(dashboardVisitRequest.fulfilled, (
                state:DashboardState,
                action:PayloadAction<DataProps>,
            ) => {
                state.isFetching = false;
                if (action.payload.visitRequests) {
                    state.requestData = action.payload.visitRequests.data;
                    state.pagination = {
                        currentPage: action.payload.visitRequests.meta.current_page || 1,
                        pageSize: action.payload.visitRequests.meta.per_page || 20,
                        total: action.payload.visitRequests.meta.total || 0,
                    };
                } else {
                    state.requestData = [emptyRequestState];
                }

                if (action.payload.summary) {
                    state.summaryData = action.payload.summary;
                }
                if (action.payload.filters) {
                    state.filterData = action.payload.filters;
                }
            });

        builder
            .addCase(dashboardFilterRequest.fulfilled, (
                state:DashboardState,
                action:PayloadAction<DataProps>,
            ) => {
                state.isFetching = false;
                if (action.payload.visitRequests) {
                    state.requestData = action.payload.visitRequests.data;
                    state.pagination = {
                        currentPage: action.payload.visitRequests.meta.current_page || 1,
                        pageSize: action.payload.visitRequests.meta.per_page || 20,
                        total: action.payload.visitRequests.meta.total || 0,
                    };
                } if (action.payload.summary) {
                    state.summaryData = action.payload.summary;
                } if (action.payload.filters) {
                    state.filterData = action.payload.filters;
                } else {
                    state.requestData = [emptyRequestState];
                }
            });

        builder.addCase(exportCompletedVisits.fulfilled, (state:DashboardState) => {
            state.isFetching = false;
        });

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                dashboardVisitRequest.pending,
                dashboardFilterRequest.pending,
                exportCompletedVisits.pending,
            ),
            (state:DashboardState) => {
                state.isFetching = true;
                state.error = null;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                dashboardVisitRequest.rejected,
                dashboardFilterRequest.rejected,
                exportCompletedVisits.rejected,
            ),
            (state:DashboardState, action) => {
                state.isFetching = false;
                state.error = action.error;
            },
        );
    },
});

// this is for configureStore
export default dashboardSlice.reducer;
export const { resetPagination } = dashboardSlice.actions;
