interface Event {
    title: string;
    date: string;
    notes: string;
    bunting: boolean;
}

interface DivisionData {
    division: string;
    events: Event[];
}

interface BankHolidaysData {
    'england-and-wales': DivisionData;
    'scotland': DivisionData;
    'northern-ireland': DivisionData;
}

interface Holidays {
    year: string;
    division: string;
    holiday: Event;
}

type BankHolidaysFunc = () => Promise<Holidays[] | undefined>;

const getBankHolidays: BankHolidaysFunc = async () => {
    try {
        const response = await fetch('https://www.gov.uk/bank-holidays.json');
        const jsonData: BankHolidaysData = await response.json();

        const holidays: Holidays[] = [];
        Object.entries(jsonData).forEach(([division, data]) => {
            data.events.forEach((event: Event) => {
                const year = new Date(event.date).getFullYear().toString();
                holidays.push({
                    year,
                    division,
                    holiday: event,
                });
            });
        });

        return holidays;

    } catch (error) {
        console.error(error);
        return undefined;
    }
};

export default getBankHolidays;
