import React, { useEffect, useState } from 'react';
import RequestFormLayout from 'components/layout/RequestFormLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import DateSelector from 'components/Form/DateSelector';
import ShiftSelector from 'components/Form/ShiftSelector';
import type { SaveShiftValues } from 'components/Form/ShiftSelector/types';
import { notification } from 'antd';
import { loadVisitRequestForm, updateVisitRequestForm } from 'core/visitRequestForm/visitRequestFormApi';
import { Shift, VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import CostCode from 'components/Form/CostCode';
import { isEmpty } from 'underscore';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';
import { SiteAvailability, HandleDateValues } from './types';
import getBankHolidays from './holiday';

export interface VisitDatesProps {
    id: number | null;
    date: string,
    saved: boolean,
    shiftName?: string | undefined,
    startTime?: string | undefined,
    endTime?: string | undefined,
    range?: boolean | undefined,
    startDate?: string | undefined,
    endDate?: string | undefined,
    shiftType?: number | string | undefined,
    blockDate?: string | undefined | null,
}

interface TimeSlot {
    label: string;
    startTime: string;
    endTime: string;
}

interface ShiftOption {
    id?: number;
    name: string;
    value: string;
    timeSlots: TimeSlot[];
}

interface ShiftSelected {
    _tempIndex?: number | undefined;
    blockDate?: string | undefined | null;
    id?: number | null;
    day?: string;
    date: string;
    shift: string | undefined;
    shiftType: number | string | undefined;
    startTime: string | undefined;
    endTime: string | undefined;
    bookingTimeCritical?: boolean;
    saved?: boolean;
}

interface customShiftProps {
    startTime: string;
    endTime: string;
    date: string;
    blockDate: string | undefined | null;
}

const DateSelectionForm: React.FC = () => {
    const navigation = useNavigate();
    const { state: navState } = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: string }>();
    const { stepData, visitRequest: visitRequestState } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
    const [visitDates, setVisitDates] = useState<VisitDatesProps[]>([]);
    const [shiftOptions, setShiftOptions] = useState<ShiftOption[]>([]);
    const [selectedShift, setSelectedShift] = useState<ShiftSelected[]>([]);
    const [holidays, setHolidays] = useState<string[]>([]);
    const [code, setCode] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [requireEmail, setRequireEmail] = useState<boolean>(false);
    const [saveTotalHours, setSaveTotalHours] = useState<number>(0);
    const [minimumCalendarDate, setMinimumCalendarDate] = useState<string>('');
    const amending = navState?.amending;
    const isVoafoneEmail = (requesterEmail: string) => requesterEmail.includes('@vodafone.com') || requesterEmail.includes('@mitie.com');

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        getBankHolidays().then(currentHolidays => {
            const filteredHolidays = currentHolidays
                ?.filter(holiday => holiday.year === currentYear.toString() && holiday.division === 'england-and-wales')
                .map(holiday => {
                    const date = new Date(holiday.holiday.date);
                    return `${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}-${date.getFullYear()}`;
                });
            setHolidays(filteredHolidays || []);
        });
    }, []);

    const siteAvailability : SiteAvailability = {
        site: visitRequestState?.site,
        approvedVisits: stepData?.approvedVisits || [],
    };

    useEffect(() => {
        if (visitRequestState.costApproverByPass) {
            setRequireEmail(false);
        } else if (visitRequestState?.requester?.email) {
            if (!isVoafoneEmail(visitRequestState?.requester?.email)) {
                const selectedDays = selectedDates.map(date => moment(date, 'MM-DD-YYYY').format('dddd').toLocaleLowerCase());
                const openDays = siteAvailability.site?.openDays?.map(day => day.day.toLocaleLowerCase());
                const isOnlyOpenDays = selectedDays.every(day => openDays?.includes(day));
                const is24_7Shift = selectedShift.some(shift => shift.shift === '24/7');
                if (!isOnlyOpenDays) {
                    setRequireEmail(true);
                } else if (is24_7Shift) {
                    setRequireEmail(true);
                } else {
                    setRequireEmail(false);
                }
            }
        }
    }, [visitDates, siteAvailability.site?.openDays, visitRequestState?.requester?.email]);

    const handleNextClick = (navigationState: Record<string, unknown>) => {
        let error = { message: '', description: '' };
        if (isEmpty(selectedShift)) {
            error = {
                message: 'Shift Not Selected',
                description: 'Please select a shift to proceed',
            };
        }

        if (visitDates.some((date) => !date.saved)) {
            error = {
                message: 'Shift Not Saved',
                description: 'Please save the shift to proceed',
            };
        }

        if (!email.includes('@vodafone.com') && requireEmail) {
            error = {
                message: 'Invalid Email',
                description: 'Please enter a valid Vodafone email to proceed',
            };
        }

        if (email === '' && requireEmail) {
            error = {
                message: 'Email Not Provided',
                description: 'Please fill an email to proceed',
            };
        }

        if (code === '') {
            error = {
                message: 'Cost Code Not Provided',
                description: 'Please fill a cost code to proceed',
            };
        }

        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return;
        }

        if (selectedDates !== null && id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 2,
                data: {
                    selectedDates: selectedShift,
                    costCode: {
                        costCentre: code,
                        costApprovalEmail: email,
                    },
                    amending,
                    totalHours: saveTotalHours,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation(`/visitor-request/${id}/step/3`, { state: navigationState });
                }
            });
        }
    };

    const handleBackClick = (navigationState: Record<string, unknown>) => {
        navigation(`/visitor-request/${id}/step/1`, { state: navigationState });
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 2,
                amending,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit requst to proceed',
            });
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        }).replace(/\//g, '-');
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    const formatTime = (timeString: string) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedTime = new Date(`2000-01-01T${timeString}`).toLocaleTimeString([], options as Intl.DateTimeFormatOptions);
        return formattedTime.replace(/\b([ap])m\b/gi, match => match.toUpperCase());
    };

    const handleDatesSelect = (values: HandleDateValues) => {
        if (values.dates && !values.dates.includes('Invalid date')) {
            let validDate = values.dates;

            if (!values.includeWeekends) {
                validDate = validDate.filter((date) => {
                    const day = new Date(date).getDay();
                    return day !== 0 && day !== 6;
                });
            }

            if (!values.includeBankHolidays) {
                validDate = validDate.filter((date) => !holidays.includes(date));
            }

            setSelectedDates(validDate.map(date => formatDate(date)));

            const formatted = validDate.concat(visitRequestState.dates?.map((date) => {
                const parts = date.date.split('-');
                const formattedDate = `${parts[1]}-${parts[2]}-${parts[0]}`;
                return formattedDate;
            }).filter((formattedDate) => !validDate.includes(formattedDate)) || []);

            validDate = formatted?.filter((date) => validDate.includes(date));
            const filteredOutDate = selectedDates.filter((date) => !validDate.includes(date))
                .concat(formatted?.filter((date) => !validDate.includes(date)) || []);

            const formattedFilteredOut = filteredOutDate.map((date) => {
                const parts = date.split('-');
                return `${parts[2]}-${parts[0]}-${parts[1]}`;
            });

            setSelectedShift((prevSelectedShifts) => prevSelectedShifts.filter((shift) => !formattedFilteredOut.includes(shift.date)));

            const sortedDates = validDate.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
            const selectedVisitDates = sortedDates.map((dates) => {
                const date = formatDate(dates);
                const parts = date.split('-');
                const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
                const dateId = selectedShift?.find((visitDate) => visitDate.date === dates)?.id || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.id || null;
                const saved = selectedShift?.some((visitDate) => visitDate.date === dates) || selectedShift?.some((visitDate) => visitDate.date === formattedDate) || false;
                const shift = selectedShift?.find((visitDate) => visitDate.date === dates)?.shift || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.shift;
                const startTime = selectedShift?.find((visitDate) => visitDate.date === dates)?.startTime || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.startTime;
                const endTime = selectedShift?.find((visitDate) => visitDate.date === dates)?.endTime || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.endTime;
                const shiftType = selectedShift?.find((visitDate) => visitDate.date === dates)?.shiftType || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.shiftType;
                const blockDate = selectedShift?.find((visitDate) => visitDate.date === dates)?.blockDate || selectedShift?.find((visitDate) => visitDate.date === formattedDate)?.blockDate;
                return {
                    id: dateId,
                    date,
                    saved,
                    shiftName: shift,
                    shiftType,
                    startTime,
                    endTime,
                    blockDate,
                };
            });

            setVisitDates(prevDate => {
                const filteredSelectedVisitDates = selectedVisitDates.filter(selectedItem => !prevDate.some(prevItem => prevItem.date === selectedItem.date));
                const filteredPrevDate = prevDate.filter(prevItem => sortedDates.includes(prevItem.date));
                const mergedVisitDates = [...filteredPrevDate, ...filteredSelectedVisitDates];

                const sortedMergeDates = mergedVisitDates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

                const uniqueVisitDates = sortedMergeDates.reduce((unique: VisitDatesProps[], item) => {
                    const isNullId = item.id === null;
                    const isDuplicate = !isNullId && unique.some(existingItem => existingItem.id === item.id);
                    if (isNullId || !isDuplicate) {
                        unique.push(item);
                    }
                    return unique;
                }, []);
                return uniqueVisitDates;
            });

        }
    };

    function checkForOverlap(newShift: customShiftProps, existingShifts: VisitDatesProps[] | customShiftProps[]): boolean {
        const format = 'MM-DD-YYYY HH:mm:ss';
        const newStart = moment(`${newShift.date} ${newShift.startTime}`, format);
        const newEnd = moment(`${newShift.date} ${newShift.endTime}`, format);

        if (newEnd.isBefore(newStart)) {
            newEnd.add(1, 'day');
        }

        return existingShifts.some(shift => {
            if (shift.startTime === undefined || shift.endTime === undefined) {
                return false;
            }
            const existingStart = moment(`${shift.date} ${shift.startTime}`, format);
            const existingEnd = moment(`${shift.date} ${shift.endTime}`, format);

            if (existingEnd.isBefore(existingStart)) {
                existingEnd.add(1, 'day');
            }

            return newStart.isBefore(existingEnd) && newEnd.isAfter(existingStart);
        });
    }

    const handleSaveShift = (values: SaveShiftValues) => {
        const shifts: customShiftProps[] = [];
        values.dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        // Custom shift logic
        if (values.shift === '24/7' && values.startTime === values.endTime && values.timeSlot !== 'Custom') {
            values.dates.forEach((date) => {
                const visitStart = `${date} ${values.startTime}`;
                const visitEnd = `${date} ${values.endTime}`;
                const initialEndTime = new Date(visitStart);
                initialEndTime.setHours(initialEndTime.getHours() + 12);
                shifts.push({
                    startTime: moment(new Date(visitStart)).format('HH:mm:ss'),
                    endTime: moment(new Date(initialEndTime)).format('HH:mm:ss'),
                    date: moment(new Date(visitStart)).format('MM-DD-YYYY'),
                    blockDate: moment(new Date(visitStart)).format('MM-DD-YYYY'),
                });
                shifts.push({
                    startTime: moment(new Date(initialEndTime)).format('HH:mm:ss'),
                    endTime: moment(new Date(visitEnd)).format('HH:mm:ss'),
                    date: moment(initialEndTime).format('MM-DD-YYYY'),
                    blockDate: moment(new Date(visitStart)).format('MM-DD-YYYY'),
                });
            });
        }
        if (values.timeSlot === 'Custom') {
            values.dates.forEach((date) => {

                const visitStart = `${date} ${values.customTimeSlotStartTime}`;
                const initialEndTime = new Date(visitStart);
                const startCustom = new Date(visitStart);
                let isExactStart = false;

                if (moment(minimumCalendarDate).isSame(moment(), 'day') && moment(startCustom).isBefore(moment())) {
                    notification.error({
                        message: 'Invalid Start Time',
                        description: 'The start shift time you selected is earlier than the current time for the current date. Please select a valid future time to create a shift.',
                    });
                    return;
                }

                if ((startCustom.getHours() === 7 || startCustom.getHours() === 19) && startCustom.getMinutes() === 0) {
                    isExactStart = true;
                    const firstShiftEndTime = new Date(startCustom);
                    firstShiftEndTime.setHours(startCustom.getHours() + 12);
                    shifts.push({
                        startTime: moment(new Date(startCustom)).format('HH:mm:ss'),
                        endTime: moment(firstShiftEndTime).format('HH:mm:ss'),
                        date: moment(startCustom).format('MM-DD-YYYY'),
                        blockDate: moment(startCustom).format('MM-DD-YYYY'),
                    });
                }

                let nextStartTime: Date;
                if (isExactStart) {
                    nextStartTime = new Date(`${date} ${shifts[shifts.length - 1]?.endTime}`) || new Date(startCustom);
                } else {
                    if (startCustom.getHours() < 19) {
                        if (startCustom.getHours() < 19 && startCustom.getHours() >= 7) {
                            initialEndTime.setHours(19, 0, 0, 0); // Set to 7pm same day
                        } else {
                            initialEndTime.setHours(7, 0, 0, 0); // Same day 7am
                        }
                    } else {
                        initialEndTime.setDate(initialEndTime.getDate() + 1);
                        initialEndTime.setHours(7, 0, 0, 0); // Next day 7am
                    }
                    const newShift = {
                        startTime: moment(startCustom).format('HH:mm:ss'),
                        endTime: moment(initialEndTime).format('HH:mm:ss'),
                        date: moment(startCustom).format('MM-DD-YYYY'),
                        blockDate: moment(startCustom).format('MM-DD-YYYY'),
                    };
                    const hasOverlap = checkForOverlap(newShift, shifts);

                    // Check for overlap for the current date's newShift before adding to shifts array
                    if (hasOverlap) {
                        // change start time to end time of last shift
                        const lastShift = shifts[shifts.length - 1] || { endTime: '07:00:00' };
                        initialEndTime.setHours(7, 0, 0, 0);
                        if (lastShift.endTime === '07:00:00') {
                            initialEndTime.setHours(initialEndTime.getHours() + 12);
                        }
                        let initialBlockDate = initialEndTime;
                        const hasDateSelected = visitDates.some((item) => item.date === moment(initialEndTime).format('MM-DD-YYYY'));
                        if (!hasDateSelected) {
                            initialBlockDate = new Date(`${values.dates[0]} 00:00:00`);
                        }
                        const newShift2 = {
                            startTime: lastShift.endTime,
                            endTime: moment(initialEndTime).format('HH:mm:ss'),
                            date: moment(initialEndTime).format('MM-DD-YYYY'),
                            blockDate: moment(initialBlockDate).format('MM-DD-YYYY'),
                        };
                        shifts.push(newShift2);
                    } else {
                        shifts.push(newShift);
                    }
                    nextStartTime = initialEndTime;
                }

                let nextEndTime = new Date(nextStartTime);
                nextEndTime.setHours(nextStartTime.getHours() + 12);
                if (nextEndTime.getHours() === 19 && nextStartTime.getHours() === 7) {
                    if (startCustom.getHours() > 7 && startCustom.getHours() <= 19) {
                        nextStartTime.setDate(nextStartTime.getDate() + 1);
                    }
                }
                let nextShift: customShiftProps;
                if (startCustom.getHours() > 19) {
                    const hasDateSelected = visitDates.some((item) => item.date === moment(nextStartTime).format('MM-DD-YYYY'));
                    let initialBlockDate = nextStartTime;
                    if (!hasDateSelected) {
                        initialBlockDate = new Date(`${values.dates[0]} 00:00:00`);
                    }
                    nextShift = {
                        startTime: moment(nextStartTime).format('HH:mm:ss'),
                        endTime: moment(nextEndTime).format('HH:mm:ss'),
                        date: moment(nextStartTime).format('MM-DD-YYYY'),
                        blockDate: moment(initialBlockDate).format('MM-DD-YYYY'),
                    };
                } else {
                    nextShift = {
                        startTime: moment(nextStartTime).format('HH:mm:ss'),
                        endTime: moment(nextEndTime).format('HH:mm:ss'),
                        date: moment(nextStartTime).format('MM-DD-YYYY'),
                        blockDate: moment(startCustom).format('MM-DD-YYYY'),
                    };
                }
                shifts.push(nextShift);

                if (!isExactStart) {
                    nextStartTime = nextEndTime;
                    nextEndTime = new Date(nextStartTime);
                    nextEndTime.setHours(nextStartTime.getHours() + 12);
                    let newShift: customShiftProps;

                    if (startCustom.getHours() > 19) {
                        newShift = {
                            startTime: moment(nextStartTime).format('HH:mm:ss'),
                            endTime: moment(nextEndTime).format('HH:mm:ss'),
                            date: moment(nextStartTime).format('MM-DD-YYYY'),
                            blockDate: moment(nextStartTime).format('MM-DD-YYYY'),
                        };
                    } else {
                        const hasDateSelected = visitDates.some((item) => item.date === moment(initialEndTime).format('MM-DD-YYYY'));
                        let initialBlockDate = startCustom;
                        if (!hasDateSelected) {
                            initialBlockDate = new Date(`${values.dates[0]} 00:00`);
                        }
                        newShift = {
                            startTime: moment(nextStartTime).format('HH:mm:ss'),
                            endTime: moment(nextEndTime).format('HH:mm:ss'),
                            date: moment(nextStartTime).format('MM-DD-YYYY'),
                            blockDate: moment(initialBlockDate).format('MM-DD-YYYY'),
                        };
                    }
                    if ((shifts[shifts.length - 1]?.endTime !== '07:00:00' && shifts[shifts.length - 2]?.endTime !== '19:00:00') || (startCustom.getHours() > 7 && startCustom.getHours() < 19)) {
                        shifts.push(newShift);
                    }
                }
            });
        }

        if (values.shift === 'Custom') {
            values.dates.forEach((date) => {
                const visitStart = `${date} ${values.customShiftStartTime}`;
                const visitEnd = `${date} ${values.customShiftEndTime}`;
                const initialEndTime = new Date(visitStart);
                const startCustom = new Date(visitStart);
                const endCustom = new Date(visitEnd);
                let isExactStart = false;
                if (moment(minimumCalendarDate).isSame(moment(), 'day') && moment(startCustom).isBefore(moment())) {
                    notification.error({
                        message: 'Invalid Start Time',
                        description: 'The start shift time you selected is earlier than the current time for the current date. Please select a valid future time to create a shift.',
                    });
                    return;
                }

                const startHour = startCustom.getHours();
                const endHour = endCustom.getHours();
                let shiftDuration;
                if (endHour < startHour) {
                    shiftDuration = (24 - startHour) + endHour;
                } else {
                    shiftDuration = endHour - startHour;
                }
                if (shiftDuration > 12 || shiftDuration === 0) {
                    notification.error({
                        message: 'Invalid Shift Time',
                        description: 'The shift you selected is more than 12 hours. Please select a valid shift time to create a shift.',
                    });
                    return;
                }

                if ((startCustom.getHours() === 7 || startCustom.getHours() === 19) && startCustom.getMinutes() === 0 && (endCustom.getHours() === 7 || endCustom.getHours() === 19) && endCustom.getMinutes() === 0) {
                    isExactStart = true;
                    const firstShiftEndTime = new Date(startCustom);
                    firstShiftEndTime.setHours(startCustom.getHours() + 12);
                    shifts.push({
                        startTime: moment(new Date(startCustom)).format('HH:mm:ss'),
                        endTime: moment(firstShiftEndTime).format('HH:mm:ss'),
                        date: moment(startCustom).format('MM-DD-YYYY'),
                        blockDate: moment(startCustom).format('MM-DD-YYYY'),
                    });
                }

                if (isExactStart) {
                    return;
                }
                if (stepData?.enabled24_7 && endCustom.getHours() !== startCustom.getHours() + 12) {
                    if (startCustom.getHours() < 7 && endCustom.getHours() > 7) {
                        initialEndTime.setHours(7, 0, 0, 0); // Set to 7am same day
                    } else if (startCustom.getHours() < 19 && endCustom.getHours() > 19) {
                        initialEndTime.setHours(19, 0, 0, 0); // Set to 7pm same day
                    } else if (startCustom.getHours() > 19 && endCustom.getHours() > 7 && endCustom.getHours() < 19) {
                        initialEndTime.setDate(initialEndTime.getDate() + 1);
                        initialEndTime.setHours(7, 0, 0, 0); // Next day 7am
                    } else {
                        initialEndTime.setHours(endCustom.getHours(), endCustom.getMinutes(), 0, 0);
                    }
                } else if (startCustom.getHours() < 19) {
                    if (startCustom.getHours() < 19 && startCustom.getHours() >= 7) {
                        initialEndTime.setHours(19, 0, 0, 0); // Set to 7pm same day
                    } else {
                        initialEndTime.setHours(7, 0, 0, 0); // Same day 7am
                    }
                } else {
                    initialEndTime.setDate(initialEndTime.getDate() + 1);
                    initialEndTime.setHours(7, 0, 0, 0); // Next day 7am
                }
                const newShift = {
                    startTime: moment(startCustom).format('HH:mm:ss'),
                    endTime: moment(initialEndTime).format('HH:mm:ss'),
                    date: moment(startCustom).format('MM-DD-YYYY'),
                    blockDate: moment(startCustom).format('MM-DD-YYYY'),
                };
                const hasOverlap = checkForOverlap(newShift, shifts);

                // Check for overlap for the current date's newShift before adding to shifts array
                if (hasOverlap) {
                    notification.error({
                        message: 'Shift Overlap',
                        description: 'Your requested shift start/end time overlaps with an existing shift. Please enter a different start time.',
                    });
                    return;
                }
                shifts.push(newShift);
                const nextStartTime = initialEndTime;

                const nextEndTime = stepData?.enabled24_7 ? new Date(visitEnd) : new Date(nextStartTime);
                if (!stepData?.enabled24_7) {
                    nextEndTime.setHours(startCustom.getHours() + 12);
                    nextEndTime.setMinutes(endCustom.getMinutes());
                }
                if (nextEndTime.getHours() === nextStartTime.getHours() && nextEndTime.getMinutes() === nextStartTime.getMinutes()) {
                    return;
                }
                shifts.push({
                    startTime: moment(nextStartTime).format('HH:mm:ss'),
                    endTime: moment(nextEndTime).format('HH:mm:ss'),
                    date: moment(nextStartTime).format('MM-DD-YYYY'),
                    blockDate: moment(startCustom).format('MM-DD-YYYY'),
                });
            });
        }
        if (values.timeSlot !== 'Custom' && values.dates.length > 0 && values.shift !== 'Custom') {
            let hasOverlap = false;
            values.dates.forEach((date) => {
                const newShift = {
                    startTime: values.startTime || '',
                    endTime: values.endTime || '',
                    date,
                    blockDate: null,
                };

                if (checkForOverlap(newShift, visitDates)) {
                    notification.error({ message: 'Shift Overlap', description: 'Your requested shift start/end time overlaps with an existing shift. Please enter a different start time.' });
                    hasOverlap = true;
                }
            });
            if (hasOverlap) {
                return;
            }
        }

        if (values.selectedDates?.includes(moment(minimumCalendarDate).format('MM-DD-YYYY'))) {
            values.selectedDates?.forEach((date) => {
                const todayDate = new Date();
                const [month, day, year] = date.split('-');
                const slotDate = `${year}-${month}-${day}`;
                const time = values?.startTime || (values.shiftType === '30' ? values.customShiftStartTime : values.customTimeSlotStartTime);
                const dateTime = new Date(`${slotDate}T${time}`);
                const isTimeInThePast = dateTime < todayDate;
                if (moment(minimumCalendarDate).isSame(moment(), 'day') && values.selectedDates?.includes(moment(minimumCalendarDate).format('MM-DD-YYYY')) && isTimeInThePast) {
                    notification.error({
                        message: 'Invalid Start Time',
                        description: 'The start shift time you selected is earlier than the current time for the current date. Please select a valid future time to create a shift.',
                    });
                }
            });
        }

        const hasOverlap = shifts.some((shift) => checkForOverlap(shift, visitDates));
        if (hasOverlap) {
            notification.error({ message: 'Shift Overlap', description: 'Your requested shift start/end time overlaps with an existing shift. Please enter a different start time.' });
            return;
        }

        const completeVisitDates = [...visitDates];
        const uniqueblockDateShifts = shifts.filter((shift, index, self) => index === self.findIndex((t) => t.blockDate === shift.blockDate));

        uniqueblockDateShifts.forEach(shiftToUpdate => {
            const matchingIndex = completeVisitDates.findIndex(visitDate => visitDate.date === shiftToUpdate.blockDate && !visitDate.blockDate);

            if (matchingIndex > -1) {
                completeVisitDates[matchingIndex] = {
                    ...completeVisitDates[matchingIndex], // Preserve existing properties
                    date: shiftToUpdate.date,
                    startTime: shiftToUpdate.startTime,
                    endTime: shiftToUpdate.endTime,
                    blockDate: shiftToUpdate.blockDate,
                    saved: completeVisitDates[matchingIndex]?.saved || false,
                    id: completeVisitDates[matchingIndex]?.id || null,
                };
            }
        });

        shifts.forEach((shift) => {
            if (!completeVisitDates.some(date => date.date === shift.date && date.startTime === shift.startTime)) {
                completeVisitDates.push({
                    startTime: shift.startTime,
                    endTime: shift.endTime,
                    startDate: shift.date,
                    endDate: shift.date,
                    date: shift.date,
                    saved: false,
                    id: null,
                    blockDate: shift.blockDate,
                });
            }
        });

        const updatedVisitDates = completeVisitDates.map((date) => {
            const customShift = shifts.find(shift => shift.date === date.date && shift.startTime === date.startTime);
            if (customShift) {
                return {
                    ...date,
                    saved: true,
                    shiftName: values.shift,
                    startTime: customShift.startTime,
                    endTime: customShift.endTime,
                    blockDate: customShift.blockDate,
                    shiftType: values.shiftType ? parseInt(values.shiftType, 10) : 0,
                };
            } if (values.dates.includes(date.date) && (date.startTime || values.startTime)) {
                return {
                    ...date,
                    saved: true,
                    shiftName: values.shift,
                    startTime: values.startTime || date.startTime,
                    endTime: values.endTime || date.endTime,
                    shiftType: values.shiftType ? parseInt(values.shiftType, 10) : 0,
                };
            }
            return date;
        });

        // sort by date
        updatedVisitDates.sort((a, b) => {
            const dateA = a.date || '';
            const dateB = b.date || '';

            // Compare dates
            if (dateA < dateB) {
                return -1;
            } if (dateA > dateB) {
                return 1;
            }
            return 0;
        });

        updatedVisitDates.forEach((day, index) => {
            const dateString = day.date || '';
            const parts = dateString.split('-');
            const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

            const tempId = day.id !== null ? day.id : `temp-${day.blockDate}-${index}`;

            setSelectedShift((prevSelectedShifts) => {
                const updatedShifts = prevSelectedShifts.filter((shift) => {
                    const shiftTempId = shift.id !== null ? shift.id : `temp-${shift.blockDate}-${shift._tempIndex}`;
                    return shiftTempId !== tempId;
                });

                return [
                    ...updatedShifts,
                    {
                        ...day,
                        id: day.id || null,
                        _tempIndex: index,
                        date: formattedDate,
                        day: dateString,
                        shift: day.shiftName,
                        shiftType: day.shiftType,
                        startTime: day.startTime,
                        endTime: day.endTime,
                        blockDate: day.blockDate,
                    },
                ];
            });
        });

        setVisitDates(updatedVisitDates);
    };

    function transformShiftData(shifts: Shift[]) {
        const shiftOption: ShiftOption[] = [];

        // Iterate over each shift type
        shifts.forEach((shift, index) => {
            // Find if the shift type already exists in shiftOptions
            const existingShift = shiftOption.find(option => option.name === shift.type);

            // If the shift type doesn't exist, create a new entry
            if (!existingShift) {
                const newShiftOption = {
                    id: index,
                    name: shift.type,
                    value: shift.typeId,
                    timeSlots: [{
                        label: `${formatTime(shift.startTime)} - ${formatTime(shift.endTime)}`,
                        startTime: shift.startTime,
                        endTime: shift.endTime,
                    }],
                };
                shiftOption.push(newShiftOption);
            } else {
                existingShift.timeSlots.push({
                    label: `${formatTime(shift.startTime)} - ${formatTime(shift.endTime)}`,
                    startTime: shift.startTime,
                    endTime: shift.endTime,
                });
            }
        });

        const newShiftOptionCustom = {
            id: 0,
            name: 'Custom',
            value: '30',
            timeSlots: [],
        };
        shiftOption.push(newShiftOptionCustom);

        // check if select day is an open day then add "Open Day" shift
        const matchingEverySelectDayWithOpenDay = selectedDates.map(date => {
            const day = moment(date, 'MM-DD-YYYY').format('dddd').toLocaleLowerCase();
            const matchOpenDay = siteAvailability.site?.openDays?.find(open => open.day.toLocaleLowerCase() === day);
            return matchOpenDay;
        });

        if (matchingEverySelectDayWithOpenDay.every(day => day !== undefined) && !isEmpty(matchingEverySelectDayWithOpenDay) && matchingEverySelectDayWithOpenDay[0]) {
            const startTimes = matchingEverySelectDayWithOpenDay?.map(days => days?.startTime);
            const endTimes = matchingEverySelectDayWithOpenDay?.map(days => days?.endTime);
            if (startTimes && endTimes) {
                if (startTimes.every(time => time === startTimes[0]) && endTimes.every(time => time === endTimes[0])) {
                    const { startTime } = matchingEverySelectDayWithOpenDay[0];
                    const { endTime } = matchingEverySelectDayWithOpenDay[0];
                    const latestId = Math.max(...shiftOption.map(option => option?.id).filter((shiftId): shiftId is number => shiftId !== undefined));

                    const formatDateToTimeString = (date: Date) => date.toTimeString().slice(0, 5);
                    if (startTime === endTime) {
                        const startCustom = new Date(`1970-01-01T${startTime}Z`);
                        const firstShiftEndTime = new Date(startCustom);
                        firstShiftEndTime.setHours(startCustom.getHours() + 11);

                        const firstShiftEndTimeString = formatDateToTimeString(firstShiftEndTime);
                        const firstShiftOption = {
                            id: latestId + 1,
                            name: `Open Day ${formatTime(startTime)} - ${formatTime(firstShiftEndTimeString)}`,
                            value: '40',
                            timeSlots: [{
                                label: `${formatTime(startTime)} - ${formatTime(firstShiftEndTimeString)}`,
                                startTime,
                                endTime: firstShiftEndTimeString,
                            }],
                        };

                        const secondShiftOption = {
                            id: latestId + 2,
                            name: `Open Day ${formatTime(firstShiftEndTimeString)} - ${formatTime(endTime)}`,
                            value: '40',
                            timeSlots: [{
                                label: `${formatTime(firstShiftEndTimeString)} - ${formatTime(endTime)}`,
                                startTime: firstShiftEndTimeString,
                                endTime: startTime,
                            }],
                        };

                        shiftOption.push(firstShiftOption, secondShiftOption);
                    } else {
                        const newOpenShiftOption = {
                            id: latestId + 1,
                            name: `Open Day ${formatTime(matchingEverySelectDayWithOpenDay[0].startTime)} - ${formatTime(matchingEverySelectDayWithOpenDay[0].endTime)}`,
                            value: '40',
                            timeSlots: [{
                                label: `${formatTime(matchingEverySelectDayWithOpenDay[0].startTime)} - ${formatTime(matchingEverySelectDayWithOpenDay[0].endTime)}`,
                                startTime: matchingEverySelectDayWithOpenDay[0].startTime,
                                endTime: matchingEverySelectDayWithOpenDay[0].endTime,
                            }],
                        };
                        shiftOption.push(newOpenShiftOption);
                    }

                }
            }
        }
        return shiftOption;
    }

    const handleRemoveShift = (date: string) => {
        const dateToRemove = visitDates.find(visitDate => visitDate.date === date && visitDate.blockDate);
        const blockDateToRemove = dateToRemove?.blockDate;
        const updatedVisitDates = visitDates.map((visitDate) => {
            if (visitDate.date === date && !visitDate.blockDate) {
                return {
                    ...visitDate,
                    saved: false,
                    shiftName: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    range: false,
                    startDate: undefined,
                    endDate: undefined,
                };
            } if (visitDate.blockDate === blockDateToRemove && blockDateToRemove !== undefined) {
                return {
                    ...visitDate,
                    saved: false,
                    shiftName: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    range: false,
                    startDate: undefined,
                    endDate: undefined,
                    blockDate: visitDate.blockDate,
                };
            }
            return visitDate;
        });

        const blockDateExists = updatedVisitDates.some((visitDate) => visitDate.blockDate === blockDateToRemove);
        if (blockDateExists && blockDateToRemove !== undefined) {
            const uniqueblockDates = new Set(); // Set to track unique blockDates
            const filteredVisitDates = updatedVisitDates.filter(day => {
                if (!day.blockDate || !uniqueblockDates.has(day.blockDate) || day.blockDate !== blockDateToRemove) {
                    uniqueblockDates.add(day.blockDate);
                    return true;
                }
                return false;
            });
            // update blockDate to be undefinded
            const updatedblockDateDates = filteredVisitDates.map((visitDate) => {
                if (visitDate.blockDate === blockDateToRemove) {
                    return {
                        ...visitDate,
                        date: visitDate.blockDate || '',
                        blockDate: null,
                    };
                }
                return visitDate;
            });
            updatedblockDateDates.sort((a, b) => {
                const dateA = a.date || '';
                const dateB = b.date || '';

                // Compare dates
                if (dateA < dateB) {
                    return -1;
                } if (dateA > dateB) {
                    return 1;
                }
                return 0;
            });
            setVisitDates(updatedblockDateDates);
            setSelectedShift(prevSelectedShifts => prevSelectedShifts.filter(shift => shift.blockDate !== blockDateToRemove));
        } else {
            setVisitDates(updatedVisitDates);
        }
        setSelectedShift(prevSelectedShifts => prevSelectedShifts.filter(shift => shift.day !== date));
    };

    const handleEditShift = (date: string) => {
        const dateToRemove = visitDates.find(visitDate => visitDate.date === date && visitDate.blockDate);
        const blockDateToRemove = dateToRemove?.blockDate;
        const updatedVisitDates = visitDates.map((visitDate) => {
            if (visitDate.date === date && !visitDate.blockDate) {
                return {
                    ...visitDate,
                    saved: false,
                    shiftName: undefined,
                    shiftType: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    range: false,
                    startDate: undefined,
                    endDate: undefined,
                };
            } if (visitDate.blockDate === blockDateToRemove && blockDateToRemove !== undefined) {
                return {
                    ...visitDate,
                    saved: false,
                    shiftName: undefined,
                    shiftType: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    range: false,
                    startDate: undefined,
                    endDate: undefined,
                    blockDate: visitDate.blockDate,
                    date: visitDate.blockDate || '',
                };
            }
            return visitDate;
        });

        const blockDateExists = updatedVisitDates.some((visitDate) => visitDate.blockDate === blockDateToRemove);
        if (blockDateExists && blockDateToRemove !== undefined) {
            const uniqueblockDates = new Set(); // Set to track unique blockDates
            const filteredVisitDates = updatedVisitDates.filter(day => {
                if (!day.blockDate || !uniqueblockDates.has(day.blockDate) || day.blockDate !== blockDateToRemove) {
                    uniqueblockDates.add(day.blockDate);
                    return true;
                }
                return false;
            });
            // update blockDate to be undefinded
            const updatedblockDateDates = filteredVisitDates.map((visitDate) => {
                if (visitDate.blockDate === blockDateToRemove) {
                    return {
                        ...visitDate,
                        blockDate: null,
                    };
                }
                return visitDate;
            });
            updatedblockDateDates.sort((a, b) => {
                const dateA = a.date || '';
                const dateB = b.date || '';

                // Compare dates
                if (dateA < dateB) {
                    return -1;
                } if (dateA > dateB) {
                    return 1;
                }
                return 0;
            });
            setVisitDates(updatedblockDateDates);
            setSelectedShift(prevSelectedShifts => prevSelectedShifts.filter(shift => shift.blockDate !== blockDateToRemove));
        } else {
            setVisitDates(updatedVisitDates);
        }
        setSelectedShift(prevSelectedShifts => prevSelectedShifts.filter(shift => shift.day !== date));
    };

    useEffect(() => {
        if (stepData?.shifts) {
            const shifts = transformShiftData(stepData?.shifts);
            setShiftOptions(shifts);
        }
    }, [stepData?.shifts]);

    useEffect(() => {
        setSelectedShift(visitRequestState.dates || []);
        const dates = visitRequestState.dates?.reduce((acc: string[], curr) => {
            if (curr.blockDate === null) {
                acc.push(curr.date);
            } else {
                const alreadyIncluded = acc.some(date => {
                    const dateEntry = visitRequestState?.dates?.find(d => d.date === date);
                    return dateEntry?.blockDate === curr.blockDate && dateEntry?.date === curr.date;
                });
                if (!alreadyIncluded) {
                    acc.push(curr.date);
                }
            }
            return acc;
        }, []) || [];

        // set initial dates to setVisitDates
        const allDate = visitRequestState.dates?.reduce((acc: {
            shift: string;
            id?: number;
            date:string;
            startTime: string;
            endTime: string;
            shiftType: string;
            blockDate?: string;}[], curr) => {
            if (curr.id === null || !acc.some(item => item.id === curr.id)) {
                acc.push(curr);
            }
            return acc;
        }, []) || [];
        const currentVisitDates = allDate.map((day) => {
            const date = formatDate(day.date);
            const parts = date.split('-');
            const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
            const saved = visitRequestState.dates?.some((visitDate) => visitDate.date === day.date) || visitRequestState.dates?.some((visitDate) => visitDate.date === formattedDate) || false;

            return {
                id: day.id || null,
                date,
                saved,
                shiftName: day.shift,
                shiftType: day.shiftType,
                startTime: day.startTime,
                endTime: day.endTime,
                blockDate: day.blockDate,
            };
        });
        currentVisitDates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setSelectedDates(dates.map(date => formatDate(date)));
        setVisitDates(currentVisitDates);
    }, [visitRequestState.dates]);

    useEffect(() => {
        if (stepData.costCode) {
            setCode(stepData.costCode.costCentre || '');
            setEmail(stepData.costCode.costApprovalEmail || '');
        }
    }, [stepData.costCode]);

    useEffect(() => {
        const latestShiftStartTime = stepData.shifts?.reduce((latestTime, shift) => {
            const shiftStartTime = moment(shift.startTime, 'HH:mm:ss');
            return shiftStartTime.isAfter(latestTime) ? shiftStartTime : latestTime;
        }, moment('00:00:00', 'HH:mm:ss'));

        if (latestShiftStartTime?.isAfter(moment())) {
            setMinimumCalendarDate(moment().format('YYYY-MM-DD'));
        } else {
            setMinimumCalendarDate(moment().add(1, 'day').format('YYYY-MM-DD'));
        }

        const shiftOptionsToday = stepData.shifts?.filter(shift => {
            const shiftStartTime = moment(shift.startTime, 'HH:mm:ss');
            return shiftStartTime.isAfter(moment());
        });

        const allOtherDatesSaved = visitDates.every(visit => {
            const shiftDate = moment(visit.date);

            if (shiftDate.isSame(moment(), 'day')) {
                return visit.saved === false;
            }
            return visit.saved === true;
        });

        const todayDate = moment(minimumCalendarDate).format('MM-DD-YYYY');
        if (shiftOptionsToday && ((allOtherDatesSaved && selectedDates.length > 0) || (todayDate && selectedDates.length === 1)) && selectedDates.includes(todayDate)) {
            setShiftOptions(transformShiftData(shiftOptionsToday));
        } else if (stepData?.shifts) {
            setShiftOptions(transformShiftData(stepData.shifts));
        }

    }, [stepData.openDays, stepData.shifts, selectedDates, selectedShift]);

    return (
        <RequestFormLayout
            currentStep={2}
            isNextEnabled
            isBackEnabled={!navState?.amending}
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}>
            <>
                <DateSelector
                    handleDatesSelect={handleDatesSelect}
                    selectedDates={selectedDates}
                    selectedShift={selectedShift}
                    siteAvailability={siteAvailability}
                    holidays={holidays}
                    minDate={minimumCalendarDate} />
                <ShiftSelector
                    visitDates={visitDates}
                    shiftOptions={shiftOptions}
                    handleRemoveShift={handleRemoveShift}
                    handleSaveShift={handleSaveShift}
                    handleEditShift={handleEditShift}
                    totalHours={stepData.totalHours}
                    enabled24_7={stepData.enabled24_7}
                    onChangetotalHours={(value) => setSaveTotalHours(value)}
                    openDays={stepData.openDays} />
                <CostCode
                    code={code}
                    email={email}
                    requireEmail={requireEmail}
                    onChangeCode={(value) => setCode(value)}
                    onChangeEmail={(value) => setEmail(value)} />
            </>
        </RequestFormLayout>
    );
};

export default DateSelectionForm;
