import React, { FC } from 'react';
import type { SavedShiftDateObject } from '../types';
import { DeleteIcon, OtherEditIcon as EditIcon } from '../../../Elements/CustomSVGIcon';
import './savedShiftDate.scss';

interface SavedShiftDateProps {
    shiftDate: SavedShiftDateObject;
    onEdit: (date: number) => void;
    onRemove: (date: number) => void;
}

const SavedShiftDate: FC<SavedShiftDateProps> = ({ shiftDate, onEdit, onRemove }) => (
    <div className="saved-shift-date-container">
        <div className="header">
            <h3 className="heading">{shiftDate.date}</h3>
            <p className="shift-label">{shiftDate.time}</p>
            <div className="icons-wrapper">
                <div className="icon" onClick={() => onEdit(shiftDate.id)}>
                    <EditIcon />
                </div>
                <div className="icon" onClick={() => onRemove(shiftDate.id)}>
                    <DeleteIcon />
                </div>
            </div>
        </div>
        <div className="detail-section">
            <h5 className="heading">Site Areas</h5>
            <p className="detail">{shiftDate.areas}</p>
        </div>
        <div className="detail-section">
            <h5 className="heading">Visitors</h5>
            <div className="visitors-list">{shiftDate.visitors}</div>
        </div>
    </div>
);

export default SavedShiftDate;
