import React, { FC } from 'react';
import termsAndConditions from './terms-and-conditions.json';
import './TermsAndConditionsModal.scss';

const TermsAndConditions: FC = () => {
    if (!termsAndConditions) {
        return null;
    }

    return (
        <div className="terms-and-conditions-container">{termsAndConditions.map((section) => (
            <div key={section.order}>
                <h3>{section.heading}</h3>
                <div>{section.items.map((item) => (
                    <div key={item.itemNumber} className="terms-item">
                        <h5 className="number-heading">{item.itemNumber}</h5>
                        <p className="text">{item.text}</p>
                    </div>
                ))}
                </div>
            </div>
        ))}
        </div>
    );
};

export default TermsAndConditions;
