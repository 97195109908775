import React from 'react';
import VisitorRequestView from 'pages/VisitorRequestView';
import SiteSelectionForm from 'pages/VisitorRequestForm/SiteSelectionForm';
import DateSelectionForm from 'pages/VisitorRequestForm/DateSelectionForm';
import SiteAccessForm from 'pages/VisitorRequestForm/SiteAccessForm';
import WorkTypeSelectionForm from 'pages/VisitorRequestForm/WorkTypeSelectionForm';
import ReviewForm from 'pages/VisitorRequestForm/ReviewForm';
import VisitorSelectionForm from 'pages/VisitorRequestForm/VisitorSelectionForm';
import Dashboard from 'pages/Dashboard';
import SecurityDashboard from 'pages/SecurityDashboard';
import ResetPassword from 'pages/Auth/ResetPassword';
import TwoFactorAuth from 'pages/Auth/TwoFactorAuth';
import Signup from 'pages/Auth/Signup';
import Login from 'pages/Auth/Login';
import VisitorsOnSite from 'pages/VisitorsOnSite';
import UnreturnedCardsAndKeys from 'pages/UnreturnedCardsAndKeys';
import CreateNewRequest from 'pages/VisitorRequestForm/components/CreateNewRequest';
import { CoreRouter, LogoutRoute } from './utils/coreRouter';

const Routes: React.FC = () => (
    <CoreRouter routes={[
        { path: '/', component: Login, type: 'default' },
        { path: '/reset-password/:token/:email', component: ResetPassword, type: 'default' },
        { path: '/two-factor', component: TwoFactorAuth, type: 'default' },
        { path: '/signup/:inviteCode?', component: Signup, type: 'default' },
        { path: '/dashboard', component: Dashboard, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/security-dashboard', component: SecurityDashboard, type: 'auth', allowedOnlyRoles: ['security', 'scorchsoft-admin'] },
        { path: '/visitors-on-site', component: VisitorsOnSite, type: 'auth', allowedOnlyRoles: ['security', 'scorchsoft-admin'] },
        { path: '/visitors-on-site/:id', component: VisitorsOnSite, type: 'auth', allowedOnlyRoles: ['security', 'scorchsoft-admin'] },
        { path: '/unreturned-cards-and-keys', component: UnreturnedCardsAndKeys, type: 'auth', allowedOnlyRoles: ['admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-view-request/:id', component: VisitorRequestView, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        // { path: '/visitor-request/:id', component: VisitorRequestForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-view/:id', component: VisitorRequestView, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/1', component: SiteSelectionForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/2', component: DateSelectionForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/3', component: VisitorSelectionForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/4', component: SiteAccessForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/5', component: WorkTypeSelectionForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/visitor-request/:id/step/6', component: ReviewForm, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
        { path: '/logout', component: LogoutRoute, type: 'auth' },
        { path: '/visitor-request/new', component: CreateNewRequest, type: 'auth', allowedOnlyRoles: ['user', 'admin', 'security', 'scorchsoft-admin'] },
    ]} />
);

export default Routes;
