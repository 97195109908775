import React, { FC, useState, useEffect } from 'react';
import { Button, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Main from 'components/layout/Main';
// import Table from 'components/Elements/Table';
import './unreturnedCardsAndKeys.scss';
import Tooltip from 'components/Elements/Tootltip';
import { AccessCardObject } from 'core/securityDashboard/types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { markCardReturned, unreturnedCardsAndKeysRequest } from 'core/securityDashboard/securityDashboardApi';
import { ADMIN_ACCESS_LEVELS } from 'core/utils/value';

const UnreturnedCardsAndKeys: FC = () => {
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const { userData } = useSelector((state: CombinedStates) => state.user);

    const toolbar = [
        { label: 'Dashboard', link: ADMIN_ACCESS_LEVELS.includes(userData?.role?.accessLevel) ? '/dashboard' : '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const backLink = (
        <Button type="link" onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    const dispatch = useDispatch<AppDispatch>();
    const { unreturnedCardsAndKeys, isFetching } = useSelector((state: CombinedStates) => state.securityDashboard);

    useEffect(() => {
        dispatch(unreturnedCardsAndKeysRequest());
    }, []);

    const handleMarkAsReturnedClick = () => {
        const visitors = unreturnedCardsAndKeys.filter((item) => selectedKeys.includes(item.id));
        const accessCardIds = visitors.map((visitor) => visitor.unreturnedAccessCards.map((card) => card.id)).flat();
        const referenceKeyIds = visitors.map((visitor) => visitor.unreturnedKeyReferences.map((key) => key.id)).flat();
        const cardIds = accessCardIds.concat(referenceKeyIds);
        dispatch(markCardReturned({ cardIds }));
        setSelectedKeys([]);
    };

    const columns = [
        {
            title: 'Site',
            dataIndex: 'siteName',
            key: 'siteName',
            width: 250,
        },
        {
            title: 'Visitor',
            dataIndex: 'visitorName',
            key: 'visitorName',
            width: 180,
        },
        {
            title: 'Temporary Access Card',
            dataIndex: 'assignedAccessCards',
            key: 'assignedAccessCards',
            width: 250,
            render: (assignedAccessCards: AccessCardObject[]) => {
                const accessCardDescriptions = assignedAccessCards.map((card) => card.description);
                const text = accessCardDescriptions.join(', ');
                if (text === '') {
                    return (
                        <span>-</span>
                    );
                }
                return (
                    <span
                        style={{
                            maxWidth: '100%',
                            wordBreak: 'break-all',
                            display: 'inline-block',
                        }}>
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Key Reference',
            dataIndex: 'assignedKeyReferences',
            key: 'assignedKeyReferences',
            width: 250,
            render: (assignedKeyReferences: AccessCardObject[]) => {
                const keyReferenceDescriptions = assignedKeyReferences.map((key) => key.description);
                const text = keyReferenceDescriptions.join(', ');
                if (text === '') {
                    return (
                        <span>-</span>
                    );
                }
                return (
                    <span
                        style={{
                            maxWidth: '100%',
                            wordBreak: 'break-all',
                            display: 'inline-block',
                        }}>
                        {text}
                    </span>
                );
            },
        },
        {
            title: 'Date of Visit',
            dataIndex: 'visitDate',
            key: 'visitDate',
            width: 175,
            render: (text: string) => (
                <span>{moment(text).format('D MMMM YYYY')}</span>
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 170,
            render: (text: string) => (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            ),
        },
    ];

    const data = unreturnedCardsAndKeys.map((item) => {
        const {
            id,
            siteName,
            visitorName,
            unreturnedAccessCards,
            unreturnedKeyReferences,
            lastCheckedOut,
            lateReason,
        } = item;
        return {
            key: id,
            siteName,
            visitorName,
            assignedAccessCards: unreturnedAccessCards,
            assignedKeyReferences: unreturnedKeyReferences,
            visitDate: lastCheckedOut,
            reason: lateReason,
        };
    });

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedKeys(selectedRowKeys);
        },
    };

    return (
        <Main
            className="login"
            title={<div>Dashboard</div>}
            hideSubHeader
            subHeaderTitle="Dashboard"
            subHeaderHasBackLink
            subHeaderBackLink="/"
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent=""
            floatingFooter={false}
            backLink={backLink}
            breadcrumb={navigation}
            showFooter={false}>
            <div className="main-container dashboard">
                <div className="unreturned-cards-and-keys-container">
                    <h1 className="heading">Unreturned Cards & Keys</h1>
                    {selectedKeys.length > 0 ? (
                        <div className="button-wrapper">
                            <span className="caption">{selectedKeys.length} {selectedKeys.length > 1 ? 'items' : 'item'} selected</span>
                            <button onClick={handleMarkAsReturnedClick} className="return-button" type="button">Mark as returned</button>
                        </div>
                    ) : null}
                    <div className="table-wrapper">
                        <Table
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            loading={isFetching}
                            columns={columns}
                            dataSource={data} />
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default UnreturnedCardsAndKeys;
