import { isUndefined } from 'underscore';
import { ErrorResponse } from '../user/types';

const validatePassword = (password: string): string[] => {
    const error = [];
    if (password.length === 0) {
        error.push('Password cannot be empty');
    }
    if (password.length < 8) {
        error.push('Password should be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
        error.push('Password must contain at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
        error.push('Password must contain at least one letter');
    }
    if (!/[0-9]/.test(password)) {
        error.push('Password must contain at least one number');
    }
    return error;
};

const log = (message:unknown) => console.log('LOG:', message);

const handleError = (error:unknown) => {
    if (typeof error === 'object' && error !== null && 'response' in error && 'data' in (error as ErrorResponse).response) {
        const serverError = error as ErrorResponse; // Perform a type assertion after doing a type guard check
        return serverError.response.data.message || 'Error';
    }
    return false;
};

function makeParams(params:object) {
    return Object.entries(params)
        .map(([key, value]) => (!isUndefined(value) ? `${key}=${value}` : null))
        .filter(p => p)
        .join('&');
}

const makeUrl = (url:string, paramsObject:object) => {
    const params = makeParams(paramsObject);
    return `${url}${params.length > 0 ? `?${params}` : ''}`;
};

export {
    validatePassword,
    log,
    handleError,
    makeUrl,
};
