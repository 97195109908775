import React, { FC } from 'react';
import './workTypeSelector.scss';
import FormSection from '../FormSection';
import NestedCheckboxSearch from '../NestedCheckboxSearch';
import type { CheckboxData } from '../NestedCheckboxSearch/types';

interface WorkTypeSelectorProps {
    workTypeData: CheckboxData[];
    handleChange: (data: CheckboxData[]) => void;
}

const WorkTypeSelector: FC<WorkTypeSelectorProps> = ({ workTypeData, handleChange }) => (
    <FormSection title="Select Work Type">
        <div className="work-type-selector-container">
            <NestedCheckboxSearch data={workTypeData} onChange={handleChange} />
        </div>
    </FormSection>
);

export default WorkTypeSelector;
