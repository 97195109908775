import React, { FC } from 'react';
import './visitorsAdded.scss';
import type { AddedVisitor } from 'pages/VisitorRequestForm/VisitorSelectionForm/types';
import FormSection from '../FormSection';
import VisitorDisplay from './VisitorDisplay';

interface VisitorsAddedProps {
    addedVisitors: AddedVisitor[];
    removeVisitor: (visitor: AddedVisitor) => void;
}

const VisitorsAdded: FC<VisitorsAddedProps> = ({ addedVisitors, removeVisitor }) => {
    const sortedVisitors = [...addedVisitors]?.sort((a, b) => a?.name?.localeCompare(b?.name));

    const getVisitor = (visitor: AddedVisitor) => {
        const onDeleteVisitor = () => {
            removeVisitor(visitor);
        };
        return (
            <VisitorDisplay
                key={visitor.id}
                visitor={visitor}
                onDelete={onDeleteVisitor} />
        );
    };

    return (
        <div className="add-visitors-visitors-added-cotainer">
            <FormSection title="Visitors Added">
                <div>{sortedVisitors?.map((visitor: AddedVisitor) => getVisitor(visitor))}
                </div>
            </FormSection>
        </div>
    );
};

export default VisitorsAdded;
