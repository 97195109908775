import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPagination } from 'core/dashboard/dashboardSlice';
import { resetPagination as resetSecurityPagination } from 'core/securityDashboard/securityDashboardSlice';
import { clearRateLimit } from 'core/user/userSlice';
import Button from '../../Elements/Button';
import { AppDispatch, CombinedStates } from '../../../core/types';
import { loginUser, forgotPassword } from '../../../core/user/userApi';
import './loginForm.scss';
import { FinishedFailedProps, valuesProps } from './types';
import { SECURITY_USER } from '../../../core/utils/value';

const LoginForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const {
        isFetching,
        isLoggedIn,
        showTwoFactorPage,
        userData,
        rateLimit,
    } = useSelector((state:CombinedStates) => state.user);

    const [
        forgetPasswordModalVisible,
        setForgetPasswordModalVisible,
    ] = useState(false);
    const [isRateLimit, setIsRateLimit] = useState(false);
    const [timer, setTimer] = useState(0);

    const [form] = Form.useForm();

    const onFinish = (values: valuesProps) => {
        dispatch(loginUser({
            email: values.email,
            password: values.password,
            callback: () => {
                dispatch(resetPagination());
                dispatch(resetSecurityPagination());
            },
        }));
    };

    useEffect(() => {
        let countdownTimer: NodeJS.Timeout;
        if (rateLimit) {
            setIsRateLimit(true);
            const storedStartTime = localStorage.getItem('rateLimitStartTime');
            const startTime = storedStartTime ? parseInt(storedStartTime, 10) : Date.now();
            localStorage.setItem('rateLimitStartTime', startTime.toString());
            const elapsed = Math.floor((Date.now() - startTime) / 1000);
            const remainingTime = 120 - elapsed;

            if (remainingTime > 0) {
                setTimer(remainingTime);
            } else {
                dispatch(clearRateLimit());
                localStorage.removeItem('rateLimitStartTime');
            }

            countdownTimer = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdownTimer);
                        dispatch(clearRateLimit());
                        setIsRateLimit(false);
                        localStorage.removeItem('rateLimitStartTime');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdownTimer);
    }, [rateLimit]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    // const onFinishFailed = (errorInfo: FinishedFailedProps) => {
    //     const { errorFields } = errorInfo;
    //     form.scrollToField(errorFields[0]?.name);
    // };

    const onFinishFailed:FC<FinishedFailedProps> = ({ errorFields }) => {
        form.scrollToField(errorFields[0]?.name);
        return null;
    };

    const handleForgotFinish = (values: valuesProps) => {
        dispatch(forgotPassword({
            email: values.email,
        }));
        setTimeout(() => { setForgetPasswordModalVisible(false); }, 1500);
    };

    const forgotFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    useEffect(() => {
        const { role } = userData;
        const accessRole = role.accessLevel || null;
        console.log('accessRole', accessRole);
        if (isLoggedIn && accessRole === SECURITY_USER) {
            navigation('/security-dashboard');
        } else if (isLoggedIn) {
            navigation('/dashboard');
        } else if (showTwoFactorPage) {
            navigation('/two-factor');
        }
    }, [isLoggedIn, showTwoFactorPage]);

    return (
        <>
            <div className="login-form-box">
                <div className="form">
                    <h3 className="title-text">Login</h3>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <Form.Item
                            label="Email Address"
                            name="email"
                            validateTrigger={['onBlur']}
                            rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                            <Input placeholder="Enter your email" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password.' }]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>
                        <div className="forgot-password-section">
                            <Button onClick={() => setForgetPasswordModalVisible(true)} type="link" className="button-link mlp-25" href="#">
                                Forgot Password?
                            </Button>
                        </div>

                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" block className="mt-3" loading={isFetching} disabled={isRateLimit}>
                                {isRateLimit ? formatTime(timer) : 'Login'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            <Modal centered width={540} footer={null} open={forgetPasswordModalVisible} onOk={() => { setForgetPasswordModalVisible(false); }} onCancel={() => { setForgetPasswordModalVisible(false); }}>
                <h4 className="h-3 title-text-md  text-center">Forgot Password</h4>
                <div className="form-wrap">
                    <Form onFinish={handleForgotFinish} onFinishFailed={forgotFinishFailed} layout="vertical">
                        <Form.Item name="email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }, { required: true, message: 'Please input your E-mail!' }]}>
                            <Input placeholder="Enter your username/email address" />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide" loading={isFetching}>
                                Reset Password
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setForgetPasswordModalVisible(false); }}>
                                Cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>

        </>
    );
};

export default LoginForm;
