import React, { FC, useMemo } from 'react';
import { Modal } from 'antd';
import './viewDetailsModal.scss';
import { DailyVisit } from 'pages/SecurityDashboard/types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { uniqueId } from 'underscore';

interface ViewDetailsModalProps {
    open: boolean;
    handleCancel: () => void;
    data: DailyVisit | null;
}

const ViewDetailsModal: FC<ViewDetailsModalProps> = ({ open, handleCancel, data }) => {
    const navigate = useNavigate();
    const handleVisitorsButtonClick = () => {
        navigate(`/visitors-on-site/${data?.encryptedId}`);
    };

    const formattedReference = useMemo(() => `00${data?.reference}`.slice(-3), [data?.reference]);

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            width={900}
            footer={null}>
            <div className="view-details-modal-container">
                <h2 className="heading">Visit Details</h2>
                <div className="content">
                    <div className="top-section">
                        <div className="reference-wrapper">
                            <h5 className="heading">Reference</h5>
                            <span className="caption">{formattedReference}</span>
                        </div>
                        <div className="site-wrapper">
                            <h5 className="heading">Site</h5>
                            <span className="caption">{data?.siteName}</span>
                        </div>
                        <div className="starting-wrapper">
                            <h5 className="heading">Starting Day</h5>
                            <span className="caption">{moment(data?.startDate).format('D MMMM YYYY')}</span>
                        </div>
                        <div className="info-wrapper">
                            <h5 className="heading">Shift</h5>
                            <span className="caption">{`${data?.shift}, ${data?.timeSlot}`}</span>
                        </div>
                    </div>
                    <div className="top-section">
                        <div className="reference-wrapper">
                            <h5 className="heading">Visitors</h5>
                            {data?.visitors.map((visitor) => <span key={`${visitor}-${uniqueId}`} className="caption">{visitor}</span>)}
                        </div>
                        <div className="site-wrapper">
                            <h5 className="heading">Area Access</h5>
                            <span className="caption">{data?.areaAccess}</span>
                        </div>
                        <div className="info-wrapper">
                            <h5 className="heading">Work Details</h5>
                            <span className="caption">{data?.workDetails}</span>
                        </div>
                    </div>
                </div>
                {data?.showButton && (
                    <div className="button-wrapper">
                        <button className="visitors-on-site-button" onClick={handleVisitorsButtonClick} type="button">Visitors On Site</button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ViewDetailsModal;
