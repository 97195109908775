import React from 'react';
import { Row, Col, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import type { SubHeaderProps } from './types';
import './subHeader.scss';

const SubHeader = (props:SubHeaderProps) => {
    const {
        hasBackLink,
        backLinkCallback,
        backLink,
        leftAction,
        rightAction,
        title,
    } = props;

    return (
        <div className="sub-header">
            <Row>
                <Col className="left" span={8}>
                    {hasBackLink ? (
                        <Button href={backLink} type="link" onClick={backLinkCallback}>
                            <LeftOutlined />
                        </Button>
                    ) : leftAction}
                </Col>
                <Col className="middle" span={8}>
                    <div className="title-text">
                        {title}
                    </div>
                </Col>
                <Col className="right" span={8}>
                    <div>{rightAction}</div>
                </Col>
            </Row>
        </div>
    );
};

SubHeader.defaultProps = {
    hasBackLink: false,
    leftAction: '',
    rightAction: '',
    title: '',
    backLinkCallback: () => console.log('Back'),
    backLink: '#',
};

export default SubHeader;
