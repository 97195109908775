/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import {
    loginUser,
    forgotPassword,
    resetPassword,
    registerUser,
    twoFactorConfirmation,
    twoFactorResend,
    logoutUser,
} from './userApi';
import { UserState, AuthUserDataResponse, RejectedActionPayload } from './types';

const emptyUserState: AuthUserDataResponse = {
    id: 0,
    name: '',
    email: '',
    role: {
        name: '',
        accessLevel: 'none',
    },
};

const initialState = {
    error: null,
    isFetching: false,
    passwordResetCompleted: false,
    isLoggedIn: false,
    showTwoFactorPage: false,
    userData: emptyUserState,
    rateLimit: false,
} as UserState;

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updatePasswordResetCompleted(state, action: PayloadAction<boolean>) {
            state.passwordResetCompleted = action.payload;
        },
        clearRateLimit: (state) => {
            state.rateLimit = false;
        },
    },
    extraReducers: (builder) => {
        // Login user
        builder
            .addCase(loginUser.fulfilled, (
                state:UserState,
                action:PayloadAction<{token?: string; twoFactorToken?: string; user?: AuthUserDataResponse }>,
            ) => {
                state.isFetching = false;
                if (action.payload.token) {
                    state.userData = action.payload.user?.role
                        ? action.payload.user
                        : emptyUserState;
                    state.isLoggedIn = true;
                } else if (action.payload.twoFactorToken) {
                    state.showTwoFactorPage = true;
                }
            });

        // Forgot password
        builder
            .addCase(forgotPassword.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'A password reset email has been requested, if an account exists you will receive an email shortly.',
                });
            });

        // Reset password
        builder
            .addCase(resetPassword.fulfilled, (state:UserState) => {
                state.isFetching = false;
                state.passwordResetCompleted = true;
                notification.success({
                    message: 'Success',
                    description: 'Your password has been reset, please login with your new password.',
                });
            })
            .addCase(resetPassword.rejected, (state: UserState) => {
                state.isFetching = false;
                state.passwordResetCompleted = true;
            });

        // Register user
        builder
            .addCase(registerUser.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'Your account has been created, please login.',
                });
            });

        // Two factor confirmation
        builder
            .addCase(twoFactorConfirmation.fulfilled, (state:UserState, action) => {
                state.isFetching = false;
                state.isLoggedIn = true;
                state.showTwoFactorPage = false;
                state.userData = action.payload.user;
            });

        // Two factor resend
        builder
            .addCase(twoFactorResend.fulfilled, (state:UserState) => {
                state.isFetching = false;
                notification.success({
                    message: 'Success',
                    description: 'A new two factor code has been sent to your email.',
                });
            });

        builder
            .addCase(logoutUser.fulfilled, (state:UserState) => {
                state.isFetching = false;
                state.isLoggedIn = false;
                state.userData = emptyUserState;
                notification.success({
                    message: 'Success',
                    description: 'You have successfully logged out.',
                });
                window.location.href = '/';
            });

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                loginUser.pending,
                forgotPassword.pending,
                resetPassword.pending,
                registerUser.pending,
                twoFactorConfirmation.pending,
                twoFactorResend.pending,
                logoutUser.pending,
            ),
            (state:UserState) => {
                state.isFetching = true;
                state.isLoggedIn = false;
                state.error = null;
                state.userData = emptyUserState;
            },
        );
        builder.addMatcher(
            isAnyOf(
                twoFactorConfirmation.pending,
                twoFactorResend.pending,
            ),
            (state:UserState) => {
                state.isFetching = true;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                loginUser.rejected,
                forgotPassword.rejected,
                // resetPassword.rejected,
                registerUser.rejected,
                twoFactorConfirmation.rejected,
                twoFactorResend.rejected,
                logoutUser.rejected,
            ),
            (state:UserState, action) => {
                state.isFetching = false;
                state.error = action.error;
                const payload = action.payload as RejectedActionPayload;
                state.rateLimit = payload.rateLimit || false;
            },
        );
    },
});
// this is for dispatch
export const {
    updatePasswordResetCompleted,
    clearRateLimit,
} = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
