import React, { FC } from 'react';
import Checkbox from 'components/Elements/Checkbox';
import { CheckboxData } from '../types';
import './checkboxchild.scss';

interface CheckboxChildProps {
    data: CheckboxData;
    onClick: (tier: 'child' | 'parent', value: string) => void;
}

const CheckboxChild: FC<CheckboxChildProps> = ({ data, onClick }) => {
    const { name, description, value, checked } = data;

    const handleCheckboxClick = () => {
        onClick('child', value);
    };
    return (
        <div className="checkbox-child-container">
            <div className="checkbox-child-header">
                <Checkbox checked={checked} onClick={handleCheckboxClick} />
                <h3 className="checkbox-child-title">{name}</h3>
            </div>
            <p className="child-checkbox-description">{description}</p>
        </div>
    );
};

export default CheckboxChild;
