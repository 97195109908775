import React from 'react';
import LoginForm from '../../../components/Form/LoginForm';
import AuthLayout from '../../../components/layout/AuthLayout';
import './login.scss';

const LoginPage: React.FC = () => (
    <AuthLayout>
        <LoginForm />
    </AuthLayout>
);

export default LoginPage;
