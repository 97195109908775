import React, { FC, useState, useEffect } from 'react';
import { PaginationProps, Table as AntTable } from 'antd';
import { TableProps as AntTableProps } from 'antd/lib';
import './table.css';

interface TableProps extends AntTableProps {
    columns: object[];
    data: object[];
    pagination: {
        currentPage: number;
        total: number;
        pageSize: number;
        onChange: (page: number) => void;
    };
    paginationSize?: number;
}

const Table: FC<TableProps> = ({ columns, data, paginationSize = 20, pagination, ...props }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        if (Math.ceil(data.length / paginationSize) < currentPage) {
            setCurrentPage(1);
        }
    }, [data, currentPage]);

    // Calculate the start and end indices for the current slice of data
    const start = (currentPage - 1) * paginationSize;
    const end = start + paginationSize;

    // Get the current page of items
    const currentItems = data.slice(start, end);

    const handlePaginationChange = (page: number) => {
        pagination.onChange(page);
        setCurrentPage(page);
    };

    const handleTotal = (total: number, range: [number, number]): JSX.Element => (
        <div style={{ left: 0, position: 'absolute' }}>
            Result: <span style={{ color: '#EF233C' }}>{range[0].toLocaleString()} - {range[1].toLocaleString()} of {pagination.total.toLocaleString()}</span>
        </div>
    );

    const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
        const lastPage = Math.ceil(pagination.total / pagination.pageSize);
        if (type === 'page') {
            return page === currentPage ? <div className="request-table-pagination">Page <span style={{ color: '#EF233C' }}>{page}</span> of {lastPage}</div> : null;
        }
        return originalElement;
    };

    return (
        <AntTable
            {...props}
            dataSource={currentItems}
            columns={columns}
            pagination={{
                current: pagination.currentPage,
                pageSize: pagination.pageSize,
                total: pagination.total,
                onChange: handlePaginationChange,
                showTotal: (total, range) => handleTotal(total, range),
                itemRender,
                showSizeChanger: false,
            }} />
    );
};

Table.defaultProps = {
    paginationSize: 20,
};

export default Table;
