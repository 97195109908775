import React from 'react';
import './ViewTable.scss';
import { Table } from 'antd';
import { VisitorRequestReview } from 'core/visitRequestReview/types';
import { format } from 'date-fns';

interface ViewTableProps {
    data: VisitorRequestReview;
}

const ViewTable: React.FC<ViewTableProps> = ({ data }) => {
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'EEEE do MMMM');
        return formattedDate;
    };

    const viewColumns = [
        {
            title: 'Dates Requested',
            dataIndex: 'date',
            key: 'date',
            render: (date: string) => formatDate(date),
        },
        {
            title: 'Assigned Security Officer',
            dataIndex: 'securityOfficer',
            key: 'securityOfficer',
            render: (securityOfficer: string) => securityOfficer || 'Pending Schedule',
        },
        {
            title: 'Number of Visitors',
            dataIndex: 'visitorCount',
            key: 'visitorCount',
        },
    ];

    return (
        <div className="view-table-container">
            <Table
                pagination={false}
                columns={viewColumns}
                dataSource={data.dates || []} />
        </div>
    );
};

ViewTable.propTypes = {
};

export default ViewTable;
