import { createAsyncThunk } from '@reduxjs/toolkit';
import lockr from 'lockr';
import { notification } from 'antd';
import { fetchApi, fetchApiAuth } from '../utils/api';
import { LoginResponseProps, ResponseProps, ForgotPasswordResponseProps } from './types';
import { handleError } from '../utils/helpers';
import constants from '../constants';

const {
    LOCALSTORAGE_EXPIRES_KEY,
    AUTH_KEY,
} = constants;

export const registerUser = createAsyncThunk(
    'user/register',
    async (payload:{email: string; password: string; first_name: string; last_name: string;}, { rejectWithValue }) => {
        const { email, password, first_name, last_name } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'register',
                body: {
                    email,
                    password,
                    first_name,
                    last_name,
                },
            });
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const loginUser = createAsyncThunk(
    'user/login',
    async (payload:{email: string; password: string; callback: () => void; }, { rejectWithValue }) => {
        const { email, password, callback } = payload;
        try {
            const response:LoginResponseProps = await fetchApi({
                method: 'POST',
                url: 'login',
                body: { email, password },
            });
            if (response.data.token) {
                const authDate = new Date();
                authDate.setMinutes(authDate.getMinutes() + 30);
                lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
                lockr.set(AUTH_KEY, response.data.token);
                callback();
            } else if (response.data.twoFactorToken) {
                lockr.set('twoFactorToken', response.data.twoFactorToken);
            }
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                if (errorMessage.includes('Too Many Attempts.')) {
                    notification.error({
                        message: 'Too Many Failed Login Attempts.',
                        description: 'Your account is temporarily locked. Please try again later.',
                    });
                    return rejectWithValue({ message: errorMessage, rateLimit: true });
                }
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const forgotPassword = createAsyncThunk(
    'user/forgotPassword',
    async (payload:{email: string;}, { rejectWithValue }) => {
        const { email } = payload;
        try {
            const response:ForgotPasswordResponseProps = await fetchApi({
                method: 'POST',
                url: 'forgot-password',
                body: { email },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }

    },
);

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (payload:{email: string|undefined; password: string; passwordConfirmation: string; token: string|undefined;}, { rejectWithValue }) => {
        const { email, password, passwordConfirmation, token } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'reset-password',
                body: {
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    token,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const logoutUser = createAsyncThunk(
    'user/logout',
    async (payload, { rejectWithValue }) => {
        try {
            const response:ResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'logout',
            });
            lockr.rm(AUTH_KEY);
            lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
            localStorage.clear();
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const twoFactorConfirmation = createAsyncThunk(
    'user/twoFactorConfirmation',
    async (payload:{code: string; callback: () => void;}, { rejectWithValue }) => {
        const { code, callback } = payload;
        try {
            const response:ResponseProps = await fetchApi({
                method: 'POST',
                url: 'two-factor-confirmation',
                body: {
                    twoFactorToken: lockr.get('twoFactorToken'),
                    code,
                },
            });

            if (response.data.token) {
                const authDate = new Date();
                authDate.setMinutes(authDate.getMinutes() + 30);
                lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
                lockr.set(AUTH_KEY, response.data.token);

                callback();
            }
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const twoFactorResend = createAsyncThunk(
    'user/twoFactorResend',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetchApi({
                method: 'POST',
                url: 'two-factor-resend',
                body: {
                    twoFactorToken: lockr.get('twoFactorToken'),
                },
            });
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
