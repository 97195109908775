import React from 'react';
import { RequestDataResponse } from 'core/dashboard/types';
import { isNull } from 'underscore';
import { AuthUserDataResponse } from '../../core/user/types';

const renderRefNumber = (data:number) => {
    if (data) {
        return String(data).padStart(3, '0');
    }

    return '';
};

const renderSite = (_:undefined, data:RequestDataResponse) => data.site?.address;

const renderRequester = (_:undefined, data:RequestDataResponse) => data.requester?.name;

const renderApprover = (_:undefined, data:RequestDataResponse, userData:AuthUserDataResponse) => (data?.vodafoneMitieApprover?.email && userData.email === data.vodafoneMitieApprover.email ? data.vodafoneMitieApprover.name : null) || data.approver?.name || data?.vodafoneMitieApprover?.email || '-';

const renderPeriod = (_: undefined, record: RequestDataResponse) => {
    const { earliest, latest } = record?.requestedPeriod || {};
    const earliestDate = new Date(earliest?.date);
    const latestDate = new Date(latest?.date);

    // Format date to dd/mm/yyyy
    const formatDate = (date: Date) => {
        if (!date) return '-';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        if (!day || !month || !year) return '';
        return `${day}/${month}/${year}`;
    };

    // Format time to 12-hour format
    const formatTime = (time: string | undefined) => {
        if (!time) return '';
        const [hour, minute] = time.split(':');
        if (!hour) return '';
        const hourInt = parseInt(hour, 10);
        const suffix = hourInt >= 12 ? 'PM' : 'AM';
        const formattedHour = hourInt % 12 === 0 ? '12' : String(hourInt % 12);
        return `${formattedHour}:${minute} ${suffix}`;
    };
    const formattedEarliestDate = formatDate(earliestDate);
    const formattedLatestDate = formatDate(latestDate);
    const formattedEarliestTime = formatTime(earliest?.startTime);
    const formattedLatestTime = formatTime(latest?.endTime);

    // Check if the period is on the same day
    if (earliestDate.toDateString() === latestDate.toDateString()) {
        return (
            <div>
                <div>{formattedEarliestDate}</div>
                <div>{`${formattedEarliestTime} - ${formattedLatestTime}`}</div>
            </div>
        );
    } if (earliestDate.toDateString() !== latestDate.toDateString()) {
        return (
            <div>
                <div>{formattedEarliestDate}</div>
                <div>{`Start: ${formattedEarliestTime}`}</div>
                <div>{formattedLatestDate}</div>
                <div>{`End: ${formattedLatestTime}`}</div>
            </div>
        );
    }
    return '-';
};

const renderDateSubmitted = (_:undefined, record:RequestDataResponse) => {
    const date = new Date(record?.dateSubmitted);
    if (!date || isNull(record?.dateSubmitted)) return '-';
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    return (
        formattedDate
    );
};

export { renderRefNumber, renderSite, renderRequester, renderPeriod, renderDateSubmitted, renderApprover };
