import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'antd';
import './DatePickerSearch.scss';
import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isNull } from 'underscore';
import type { PickerRef } from 'rc-picker';
import FooterCalendar from '../FooterCalendar';

interface DatePickerSearchProps {
    onSelectStartDate: (date: string) => void;
    onSelectEndDate: (date: string) => void;
    startDate: string;
    endDate: string;
}

const DatePickerSearch: React.FC<DatePickerSearchProps> = ({ onSelectStartDate, onSelectEndDate, startDate, endDate }) => {
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);
    const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState<boolean>(false);
    const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState<boolean>(false);
    const [isStartDateConfirmed, setIsStartDateConfirmed] = useState<boolean>(false);
    const [isEndDateConfirmed, setIsEndDateConfirmed] = useState<boolean>(false);

    const startDatePickerRef = useRef<PickerRef>(null);
    const endDatePickerRef = useRef<PickerRef>(null);

    const dateFormat = 'DD/MM/YYYY';

    useEffect(() => {
        if (startDate !== '') {
            setSelectedStartDate(dayjs(startDate));
        } else {
            setSelectedStartDate(null);
        }
        if (endDate !== '') {
            setSelectedEndDate(dayjs(endDate));
        } else {
            setSelectedEndDate(null);
        }
    }, [startDate, endDate]);

    const onChangeStartDate: DatePickerProps['onChange'] = (date) => {
        if (isNull(date)) {
            onSelectStartDate('');
        }
        setSelectedStartDate(date);
        setIsStartDateConfirmed(false);
    };

    const onChangeEndDate: DatePickerProps['onChange'] = (date) => {
        if (isNull(date)) {
            onSelectEndDate('');
        }
        setSelectedEndDate(date);
        setIsEndDateConfirmed(false);
    };

    const handleSelectStartDate = () => {
        const formattedStartDate = selectedStartDate?.format('YYYY-MM-DD') || '';
        onSelectStartDate(formattedStartDate);
        setIsStartDateConfirmed(true);
        setIsStartDatePickerOpen(false);
    };

    const handleSelectEndDate = () => {
        const formattedEndDate = selectedEndDate?.format('YYYY-MM-DD') || '';
        onSelectEndDate(formattedEndDate);
        setIsEndDateConfirmed(true);
        setIsEndDatePickerOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            event.stopPropagation();

            // Popup elements for start and end date - refs are the actual select elements in the filter component.
            const startDateElement = document.querySelector('.start-date-popup');
            const endDateElement = document.querySelector('.end-date-popup');

            if (startDateElement && !startDateElement.contains(event.target as Node) && startDatePickerRef.current && !startDatePickerRef.current.nativeElement.contains(event.target as Node)) {
                setIsStartDatePickerOpen(false);
                if (!isStartDateConfirmed) {
                    setSelectedStartDate(startDate ? dayjs(startDate) : null);
                }
            }

            if (endDateElement && !endDateElement.contains(event.target as Node) && endDatePickerRef.current && !endDatePickerRef.current.nativeElement.contains(event.target as Node)) {
                setIsEndDatePickerOpen(false);
                if (!isEndDateConfirmed) {
                    setSelectedEndDate(endDate ? dayjs(endDate) : null);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [startDatePickerRef?.current, endDatePickerRef?.current, isEndDateConfirmed, isStartDateConfirmed]);

    return (
        <div className="filter-date-container">
            <DatePicker
                ref={startDatePickerRef}
                value={selectedStartDate ? dayjs(selectedStartDate, dateFormat) : null}
                placeholder="Start Date"
                format={dateFormat}
                style={{ borderColor: '#2B2D42' }}
                superNextIcon={null}
                superPrevIcon={null}
                className="datepicker-search"
                popupClassName="date-picker-popup start-date-popup"
                renderExtraFooter={() => (
                    <FooterCalendar
                        handleSelectDate={handleSelectStartDate}
                        handleClose={() => setIsStartDatePickerOpen(false)} />
                )}
                open={isStartDatePickerOpen}
                onFocus={() => { setIsStartDatePickerOpen(true); setIsEndDatePickerOpen(false); }}
                onChange={onChangeStartDate} />
            <DatePicker
                ref={endDatePickerRef}
                value={selectedEndDate ? dayjs(selectedEndDate, dateFormat) : null}
                placeholder="End Date"
                format={dateFormat}
                style={{ borderColor: '#2B2D42' }}
                className="datepicker-search"
                superNextIcon={null}
                superPrevIcon={null}
                popupClassName="date-picker-popup end-date-popup"
                renderExtraFooter={() => (
                    <FooterCalendar
                        handleSelectDate={handleSelectEndDate}
                        handleClose={() => setIsEndDatePickerOpen(false)} />
                )}
                open={isEndDatePickerOpen}
                onFocus={() => { setIsEndDatePickerOpen(true); setIsStartDatePickerOpen(false); }}
                onChange={onChangeEndDate} />
        </div>
    );
};

export default DatePickerSearch;
