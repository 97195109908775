import React from 'react';
import ResetPasswordForm from '../../../components/Form/ResetPasswordForm';
import '../Login/login.scss';
import { ResetPasswordProps } from './types';
import AuthLayout from '../../../components/layout/AuthLayout';

const ResetPassword: React.FC<ResetPasswordProps> = ({ match }) => {
    // Probably need a function to check the reset code first before showing the form
    console.log(match);

    return (
        <AuthLayout>
            <ResetPasswordForm />
        </AuthLayout>
    );
};

export default ResetPassword;
