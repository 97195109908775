import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApiAuth } from 'core/utils/api';
import { handleError } from 'core/utils/helpers';
import { notification } from 'antd';
import { NotificationResponse, NotificationUpdateResponse } from './types';

export const fetchNotifications = createAsyncThunk(
    'notification/fetch',
    async (_, { rejectWithValue }) => {
        try {
            const response: NotificationResponse = await fetchApiAuth({
                method: 'GET',
                url: 'notifications',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Notification Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchUnreadNotifications = createAsyncThunk(
    'notification/unread',
    async (_, { rejectWithValue }) => {
        try {
            const response: NotificationResponse = await fetchApiAuth({
                method: 'GET',
                url: 'notifications/unread',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Notification Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const markNotificationAsReadServer = createAsyncThunk(
    'notification/read',
    async (id: string, { rejectWithValue }) => {
        try {
            const response: NotificationUpdateResponse = await fetchApiAuth({
                method: 'PUT',
                url: 'notifications/read',
                body: { id },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Notification Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const markAllNotificationsAsReadServer = createAsyncThunk(
    'notification/readAll',
    async (_, { rejectWithValue }) => {
        try {
            const response: NotificationUpdateResponse = await fetchApiAuth({
                method: 'POST',
                url: 'notifications/read-all',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Notification Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchRecentNotifications = createAsyncThunk(
    'notification/recent',
    async (_, { rejectWithValue }) => {
        try {
            const response: NotificationResponse = await fetchApiAuth({
                method: 'GET',
                url: 'notifications/recent',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Notification Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
