import React from 'react';

import TwoFactorAuthForm from '../../../components/Form/TwoFactorAuthForm';
import AuthLayout from '../../../components/layout/AuthLayout';

const TwoFactorAuth: React.FC = () => (
    <AuthLayout>
        <TwoFactorAuthForm />
    </AuthLayout>
);

export default TwoFactorAuth;
