import React, { FC } from 'react';
import './warningBox.scss';
import { WarningIcon } from '../CustomSVGIcon';

interface WarningBoxProps {
    children: React.ReactNode;
}

const WarningBox: FC<WarningBoxProps> = ({ children }) => (
    <div className="warning-box-container">
        <div className="icon-wrapper">
            <WarningIcon />
        </div>
        <p className="warning-box-content">{children}</p>
    </div>
);

export default WarningBox;
