import React, { FC } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import type { TooltipProps as AntdTooltipProps } from 'antd/lib/tooltip';
import './tooltip.scss';

type ExtendedTooltipProps = AntdTooltipProps & {
    textColor?: string;
 }

const Tooltip: FC<ExtendedTooltipProps> = ({ title, placement = 'top', color = 'white', textColor = '#000', children, ...props }) => (
    <AntdTooltip title={title} placement={placement} color={color} overlayInnerStyle={{ color: textColor }} {...props}>{children}</AntdTooltip>
);

Tooltip.defaultProps = {
    textColor: '#000',
};

export default Tooltip;
