import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedStates, AppDispatch } from 'core/types';
import './uploadedFileList.scss';
import { Attachment } from 'core/visitRequestForm/types';
import { CancelIcon, FileIcon } from 'components/Elements/CustomSVGIcon';
import { deleteAttachment } from 'core/visitRequestForm/visitRequestFormApi';
import { useParams } from 'react-router-dom';

const UploadedFileList: FC = () => {
    const { visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { userData } = useSelector((state: CombinedStates) => state.user);
    const uploadedFiles = visitRequest?.attachments || [];

    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams<{ id: string }>();

    const getFileDisplay = (file: Attachment) => {
        const handleRemove = () => {
            if (id && file.id) {
                dispatch(deleteAttachment({ id, attachmentId: file.id }));
            }
        };

        return (
            <li className="uploaded-file-display">
                <div className="icon-wrapper">
                    <FileIcon />
                    <span>{file.originalFilename}</span>
                </div>
                {userData.email === visitRequest.requester?.email && (
                    <div className="remove-file-button" onClick={handleRemove}>
                        <CancelIcon s="#C0D1D8" />
                    </div>
                )}
            </li>
        );
    };

    return <ul className="uploaded-files-list-container">{uploadedFiles.map((file: Attachment) => getFileDisplay(file))}</ul>;

};

export default UploadedFileList;
