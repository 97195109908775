import React, { FC } from 'react';
import './visitorDisplay.scss';
import type { Visitor } from 'core/visitRequestReview/types';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from 'components/Elements/CustomSVGIcon';
import { Collapse } from 'antd';

interface VisitorDisplayProps {
    visitor: Visitor;
    onDelete: () => void;
}

const VisitorDisplay: FC<VisitorDisplayProps> = ({ visitor, onDelete }) => {

    const renderIcon = (isActive: boolean | undefined) => {
        if (isActive) {
            return <ChevronUpIcon />;
        }
        return <ChevronDownIcon />;
    };

    const data = [
        {
            label: visitor.name,
            key: visitor.name,
            children: (
                <div key={visitor.id}>
                    <div className="visitor-details">
                        <div className="detail-wrapper">
                            <h5 className="label">Phone Number:</h5>
                            <span className="detail">{visitor.phoneNumber}</span>
                        </div>
                        <div className="detail-wrapper">
                            <h5 className="label">Email Address:</h5>
                            <span className="detail">{visitor.email}</span>
                        </div>
                        <div className="detail-wrapper">
                            <h5 className="label">Company Name:</h5>
                            <span className="detail">{visitor.companyName}</span>
                        </div>
                    </div>
                </div>
            ) },
    ];

    return (
        <div className="visitor-content">
            <Collapse
                expandIconPosition="end"
                expandIcon={({ isActive }) => renderIcon(isActive)}
                items={data} />
            <div className="delete-wrapper" onClick={onDelete}>
                <DeleteIcon />
            </div>
        </div>
    );
};

export default VisitorDisplay;
