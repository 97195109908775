import React, { FC } from 'react';
import { WarningIcon, LeftChevron, RightChevron, OpenDaysIcon, ApprovedVisitsIcon } from 'components/Elements/CustomSVGIcon';
import { Calendar, DateObject } from 'react-multi-date-picker';
import type { SiteAvailability } from 'pages/VisitorRequestForm/DateSelectionForm/types';
import Tooltip from 'components/Elements/Tootltip';
import './availabilityModal.scss';

interface SiteAvailabilityProps {
    siteAvailability: SiteAvailability;
    holidays: string[];
}

const AvailabilityModal: FC<SiteAvailabilityProps> = ({ siteAvailability, holidays }) => {

    const openDays = siteAvailability?.site?.openDays?.map((day) => day.day) || [];

    const mapADay = (date: DateObject) => {
        const approvedVisit = siteAvailability.approvedVisits.find((visit) => visit.date === date.format('YYYY-MM-DD'));
        if (approvedVisit) {
            const tooltipContent = <div><span>{approvedVisit.shift}</span><br /><span>{approvedVisit.startTime} - {approvedVisit.endTime}</span></div>;
            return { children: <Tooltip title={tooltipContent}><span className="visit-day">{date.day}</span></Tooltip> };
        }
        if (openDays.includes(date.format('dddd')) && !holidays.includes(date.format('MM-DD-YYYY'))) {
            return { children: <span className="open-day">{date.day}</span> };
        }
        return { children: <span>{date.day}</span> };
    };

    return (
        <div className="availability-modal-container">
            <h2 className="heading">Open Days & Upcoming Bookings</h2>
            <h5 className="sub-heading">{siteAvailability.site?.fullAddress}</h5>
            <div className="info-wrapper">
                <WarningIcon />
                <span className="caption">Hover over a highlighted date to see details</span>
            </div>
            <div className="availability-calendar-container">
                <Calendar
                    className="availability-calendar"
                    readOnly
                    highlightToday={false}
                    showOtherDays
                    monthYearSeparator=" "
                    renderButton={(direction: string, handleClick: React.MouseEventHandler<HTMLButtonElement>) => (
                        <button className="calendar-arrow" type="button" onClick={handleClick}>
                            {direction === 'right' ? <RightChevron /> : <LeftChevron />}
                        </button>
                    )}
                    mapDays={(date) => mapADay(date.date)} />
            </div>
            <div className="icons-wrapper">
                <OpenDaysIcon />
                <span className="caption">Open Days</span>
                <div className="icon">
                    <ApprovedVisitsIcon />
                </div>
                <span className="caption">Approved Visits</span>
            </div>
        </div>
    );
};

export default AvailabilityModal;
