import React from 'react';
import { Button } from 'antd';
import './FooterCalendar.scss';

interface FooterCalendarProps {
    handleSelectDate: () => void;
    handleClose: () => void;
}

const FooterCalendar: React.FC<FooterCalendarProps> = ({ handleSelectDate, handleClose }) => (
    <div className="footer-calendar" style={{ marginBlock: 10, display: 'grid' }}>
        <Button type="primary" onClick={handleSelectDate}>Select</Button>
        <Button onClick={handleClose} className="picker-close-button">Close</Button>
    </div>
);

export default FooterCalendar;
