import React, { FC } from 'react';
import './confirmApprove.scss';
import { Button, Modal } from 'antd';
import { ConfirmApproveIcon } from 'components/Elements/CustomSVGIcon';

interface ConfirmApproveProps {
    visible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmApprove: FC<ConfirmApproveProps> = ({ visible, onConfirm, onCancel }) => (
    <div className="approve-box-container">
        <Modal
            open={visible}
            onCancel={() => onCancel()}
            footer={null}
            width={650}
            wrapClassName="approve-modal">
            <div className="approve-modal-icon">
                <ConfirmApproveIcon />
            </div>
            <div className="approve-modal-title">Visitor Request Approved</div>
            <p>Thank you for approving this visitor request. The requested dates will be sent to the scheduling teams shortly.</p>
            <div className="approve-modal-buttons">
                <Button type="primary" className="approve-confirm-button" onClick={() => onConfirm()}>View New Request</Button>
                <Button type="default" className="approve-cancel-button" onClick={() => onCancel()}>Back to Dashboard</Button>
            </div>
        </Modal>
    </div>
);

export default ConfirmApprove;
