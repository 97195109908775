import React, { useState, FC } from 'react';
import TextInput from 'components/Elements/TextInput';
import { ClearItem, SearchIcon } from 'components/Elements/CustomSVGIcon';
import CheckboxParent from './CheckboxParent';
import { CheckboxData } from './types';
import './nestedCheckboxSearch.scss';

interface NestedCheckboxSearchProps {
    data: CheckboxData[];
    onChange: (data: CheckboxData[]) => void;
}

const Search: FC<NestedCheckboxSearchProps> = ({ data, onChange }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const getFilteredData = (): CheckboxData[] => (data as CheckboxData[]).filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()) || (item.children && item.children.some(child => child.name.toLowerCase().includes(searchTerm.toLowerCase()))));
    const filteredData = getFilteredData();
    const selectedItems = data.reduce((res: CheckboxData[], item: CheckboxData) => {
        if (item.checked) res.push(item);
        if (item.children) {
            const childChecked = item.children.filter(child => child.checked);
            res.push(...childChecked);
        }
        return res;
    }, []);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleParentClick = (value: string) => {
        const updatedData = (data as CheckboxData[]).map(item => {
            const newItem = { ...item };
            if (newItem.value === value) {
                newItem.checked = !newItem.checked;
                if (newItem.children) {
                    newItem.children = newItem.children.map(child => {
                        const newChild = { ...child };
                        if (!newItem.checked) newChild.checked = false;
                        return newChild;
                    });
                }
            } else {
                newItem.checked = false;
                if (newItem.children) {
                    newItem.children = newItem.children.map(child => {
                        const newChild = { ...child };
                        newChild.checked = false;
                        return newChild;
                    });
                }
            }
            return newItem;
        });
        onChange(updatedData);
    };

    const handleChildClick = (value: string) => {
        const updatedData = (data as CheckboxData[]).map(item => {
            const newItem = { ...item };
            if (newItem.children) {
                newItem.children = newItem.children.map(child => {
                    const newChild = { ...child };
                    if (newChild.value === value) {
                        newChild.checked = !newChild.checked;
                    } else {
                        newChild.checked = false;
                    }
                    return newChild;
                });
            }
            return newItem;
        });
        onChange(updatedData);
    };

    const handleClick = (tier: 'parent' | 'child', value: string) => {
        if (tier === 'parent') {
            return handleParentClick(value);
        }
        return handleChildClick(value);
    };

    const getDisplayItem = (item: CheckboxData) => {
        const handleRemoveItemClick = () => {
            handleParentClick(item.value);
        };

        return (
            <div className="selected-item">
                <p className="label">{item.name}</p>
                <div className="icon-wrapper" onClick={handleRemoveItemClick}>
                    <ClearItem />
                </div>
            </div>
        );
    };

    const getResults = (): JSX.Element => (
        <ul className="checkbox-results">
            {(filteredData as CheckboxData[]).map(item => (
                <li key={item.value}>
                    <div className="checkbox-list-item">
                        <CheckboxParent data={item} onClick={handleClick} />
                    </div>
                </li>
            ))}
        </ul>
    );

    return (
        <div className="form-search-container">
            <div className="search-content">
                <div className="input-wrapper">
                    <TextInput value={searchTerm} leftIcon={<SearchIcon />} onChange={handleSearchChange} />
                </div>
                {selectedItems.length > 0
                    ? (
                        <div className="items-list-container">
                            {selectedItems.map((item) => getDisplayItem(item))}
                        </div>
                    ) : null}
                {getResults()}
            </div>
        </div>
    );
};

export default Search;
