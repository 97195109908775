import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown, Spin, notification } from 'antd';
import Icon from '@ant-design/icons';
import { ADMIN_USER, SCORCHSOFT_USER, VISITOR_REQUEST_STATUS, ADMIN_ACCESS_LEVELS } from 'core/utils/value';
import Status from 'components/Form/Status';
import { unwrapResult } from '@reduxjs/toolkit';
import { PushSuccess, ScheduleSuccess, TableFilters, VisitRequestResubmitActionPayload, VisitRequestReviewResponsePayload } from 'core/visitRequestReview/types';
import { RightBoxIcon, TriangleDownIcon, TriangleUpIcon } from 'components/Elements/CustomSVGIcon';
import FormDetails from 'components/Form/FormDetails';
import { getVisitRequestReview, resubmitActionRequest, updateVisitRequestReview, checkRequestSession, overruleSession, fetchApprovers, pushToApprover, scheduleRequest, releaseSession } from 'core/visitRequestReview/visitRequestReviewApi';
import { AppDispatch, CombinedStates } from 'core/types';
import ActionModal, { ActionModalHandlers } from 'components/Modals/ActionModal';
import ConfirmApprove from 'components/Modals/ConfirmApprove';
import { isEmpty } from 'underscore';
import { setLastRequestAmended } from 'core/visitRequestForm/visitRequestFormSlice';
import { cancelAmendmentRequest } from 'core/visitRequestForm/visitRequestFormApi';
import Main from '../../components/layout/Main';
import './VisitorRequestView.scss';
import ActionDropdown from './components/ActionDropdown';
import ViewTable from './components/ViewTable';
import LockedRequestModal from './components/LockedRequestModal';
import { Actions } from './components/ActionDropdown/requestActions';
import PushActionModal, { PushActionModalHandlers } from './components/PushActionModal';
import ScheduleModal, { ScheduleModalPayload } from './components/ScheduleModal';

interface ActionProps {
    statusId: number;
    title: string;
    subTitle?: string;
    description?: string;
    confirmButton: string;
    cancelButton: string;
    action: string;
    placeholder?: string;
}

const VisitorRequestView: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const location = useLocation();
    const { filters, fromForm } = location.state as { filters: TableFilters, fromForm: boolean } || {};

    const { isFetching, visitRequestReview, success, isUpdating, statusData, lockedStatus, isPushing, isScheduling, metadata } = useSelector((state: CombinedStates) => state.visitRequestReview);
    const { lastRequestAmended } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { userData } = useSelector((state: CombinedStates) => state.user);
    const toolbar = [
        { label: 'Dashboard', link: ADMIN_ACCESS_LEVELS.includes(userData?.role?.accessLevel) ? '/dashboard' : '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];
    const [status, setStatus] = useState<number>(1);
    const [actionStatus, setActionStatus] = useState<ActionProps | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalApprovedVisible, setModalApprovedVisible] = useState<boolean>(false);
    const [modalPushVisible, setModalPushVisible] = useState<boolean>(false);
    const [isOverruleModalOpen, setIsOverruleModalOpen] = useState<boolean>(false);
    const [isScheduledModalOpen, setIsScheduledModalOpen] = useState<boolean>(false);
    const statusLabels = ['Pending Approval', 'Rejected or Queried', 'Approved', 'Completed'];
    const actionButtonIcon = isDropdownOpen ? TriangleUpIcon : TriangleDownIcon;
    const pushActionModalRef = useRef<PushActionModalHandlers>(null);
    const actionModalRef = useRef<ActionModalHandlers>(null);

    const refreshApprovers = () => {
        if (visitRequestReview.encryptedId !== null) {
            dispatch(fetchApprovers({ id: visitRequestReview.encryptedId }));
        } else {
            notification.error({
                message: 'Error',
                description: 'Could not fetch approvers.',
            });
        }
    };

    const handleConfirmRedirect = (nextRequest: boolean) => {
        if (nextRequest && metadata?.nextRequestId) {
            navigation(`/visitor-view-request/${metadata.nextRequestId}`, { state: { filters } });
        } else if (!nextRequest) {
            let query = '';

            if (filters) {
                const { siteText, searchTerms, statusText, startDate, endDate } = filters;
                const params = new URLSearchParams();

                if (siteText && !isEmpty(siteText)) {
                    params.append('siteText', siteText.toString());
                }
                if (searchTerms && !isEmpty(searchTerms)) {
                    params.append('searchTerms', searchTerms.toString());
                }
                if (statusText && !isEmpty(statusText)) {
                    params.append('statusText', statusText.toString());
                }
                if (startDate) {
                    params.append('startDate', startDate.toString());
                }
                if (endDate) {
                    params.append('endDate', endDate.toString());
                }

                query = params.toString();
            }

            if (query) {
                navigation(`/dashboard?${query}`);
            } else {
                navigation('/dashboard');
            }
        }

        setModalApprovedVisible(false);
    };

    const handlePushAction = (selectedIds: string[]) => {
        if (selectedIds.length === 0 || selectedIds !== null) {
            setModalPushVisible(false);
            dispatch(pushToApprover({ id: String(visitRequestReview.encryptedId), approverIds: selectedIds }))
                .then(unwrapResult)
                .then((payload: PushSuccess) => {
                    if (payload.success) {
                        pushActionModalRef.current?.clear();
                    }
                });
        } else {
            notification.warning({
                message: 'Warning',
                description: 'Please select a new approver.',
            });
        }
    };

    const handleScheduleAction = (payload: ScheduleModalPayload) => {
        if ((payload.securityOfficerId === null || payload.dateId === null) && payload.tempSecurityName === '') {
            notification.warning({
                message: 'Warning',
                description: 'Please select a security officer and a date.',
            });
            return;
        }

        // dispatch event
        dispatch(scheduleRequest({
            id: String(visitRequestReview.encryptedId),
            data: {
                dateId: payload.dateId,
                securityOfficerId: payload.securityOfficerId,
                tempSecurityName: payload.tempSecurityName,
            },
        })).then(unwrapResult).then((_payload: ScheduleSuccess) => {
            if (_payload.statusData.inActiveActions.schedule) {
                setIsScheduledModalOpen(false);
            }
        });
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(getVisitRequestReview({
                id,
                filters,
            }));
        }
    };

    const checkSession = () => {
        if (id !== undefined) {
            dispatch(checkRequestSession({
                id,
            }));
        }
    };

    const handleBackLinkPress = () => {
        dispatch(releaseSession({ id }));
        if (fromForm) {
            navigation('/dashboard');
        } else {
            navigation(-1);
        }
    };

    useEffect(() => {
        if (lastRequestAmended !== null && lastRequestAmended !== undefined) {
            dispatch(cancelAmendmentRequest(lastRequestAmended));
        }

        handleInitialLoad();
        const interval = setInterval(() => {
            checkSession();
        }, 20000);

        window.onpopstate = () => {
            if (!lockedStatus?.isLocked && (lockedStatus?.lockedBy !== '' || lockedStatus?.lockedBy !== null || lockedStatus?.lockedBy !== undefined)) {
                dispatch(releaseSession({ id }));
            }
        };

        window.scrollTo({ top: 0, behavior: 'auto' });

        return () => {
            clearInterval(interval);
            dispatch(releaseSession({ id }));
        };
    }, [id]);

    useEffect(() => {
        if (!isFetching && !isUpdating) {
            setStatus(statusData.currentStatusStep);
        }
    }, [isUpdating, isFetching, statusData.currentStatusStep]);

    useEffect(() => {
        if (lockedStatus?.isLocked) {
            setIsOverruleModalOpen(true);
        }
        if (lockedStatus?.overriden) {
            navigation('/dashboard', { replace: true });
        }
    }, [lockedStatus]);

    const handleOverrule = () => {
        if (id !== undefined) {
            dispatch(overruleSession({ id }));
        }
        setIsOverruleModalOpen(false);
    };

    const handleResubmit = async () => {
        if (id !== undefined) {
            dispatch(resubmitActionRequest({
                id: String(id),
            })).then(unwrapResult).then((payload: VisitRequestResubmitActionPayload) => {
                const { visitRequestId } = payload;

                if (visitRequestId) {
                    navigation(`/visitor-request/${visitRequestId}/step/2`);
                }
            });
        }
    };

    const handleSelectAction = (action: string) => {
        setIsDropdownOpen(false);

        if (action === Actions.APPROVE) {
            setModalVisible(true);
            if (!visitRequestReview.hasCostApprove) {
                setActionStatus(VISITOR_REQUEST_STATUS.cost_approval);
            } else {
                setActionStatus(VISITOR_REQUEST_STATUS.confirm_approval);
            }
        }

        if (action === Actions.REJECT) {
            actionModalRef.current?.clear();
            setModalVisible(true);
            setActionStatus(VISITOR_REQUEST_STATUS.rejected);
        }

        if (action === Actions.QUERY) {
            actionModalRef.current?.clear();
            setModalVisible(true);
            setActionStatus(VISITOR_REQUEST_STATUS.query);
        }

        if (action === Actions.CANCEL) {
            actionModalRef.current?.clear();
            setModalVisible(true);
            setActionStatus(VISITOR_REQUEST_STATUS.cancel);
        }

        if (action === Actions.AMEND) {
            if (visitRequestReview.dateSubmitted !== undefined && visitRequestReview.dateSubmitted !== null) {
                dispatch(setLastRequestAmended(id));
            }
            navigation(`/visitor-request/${id}/step/2`, { state: { amending: true } });
        }

        if (action === Actions.RESUBMIT) {
            handleResubmit();
        }

        if (action === Actions.PUSH) {
            setModalPushVisible(true);
        }

        if (action === Actions.SCHEDULE) {
            setIsScheduledModalOpen(true);
        }
    };

    const renderActionsDropdown = () => (
        <ActionDropdown
            onMouseLeave={() => setIsDropdownOpen(false)}
            user={userData}
            requester={visitRequestReview.requester}
            approver={visitRequestReview.approver}
            costApprover={visitRequestReview.costApprover}
            hasCostApprove={visitRequestReview.hasCostApprove}
            availableApprovers={visitRequestReview.availableApprovers}
            onSelectAction={(action) => handleSelectAction(action)}
            statusData={statusData} />
    );

    const handleConfirmAction = (action: string | undefined, message: string | undefined) => {
        if (id !== undefined && action !== null) {
            dispatch(updateVisitRequestReview({
                id,
                data: { action, message },
            })).then(unwrapResult).then((payload: VisitRequestReviewResponsePayload) => {
                if (payload.visitRequest && payload.statusData) {
                    actionModalRef.current?.clear();
                }
            });
        }
        setModalVisible(false);
    };

    useEffect(() => {
        if (success) {
            if (actionStatus?.action === 'approve' && status !== 1) {
                setModalApprovedVisible(true);
            }
            setStatus(actionStatus?.statusId || 1);
        }
    }, [success]);

    const infoCard = [
        {
            id: 1,
            content: visitRequestReview.requester?.name,
            title: 'Requester',
        },
        {
            id: 2,
            content: visitRequestReview.site?.fullAddress,
            title: 'Site',
        },
        {
            id: 3,
            content: visitRequestReview.dateSubmitted,
            title: 'Date Submitted',
        },
        {
            id: 4,
            content: visitRequestReview.visitors?.length,
            title: 'Number of Visitors',
        },
        {
            id: 5,
            content: 'Signed',
            title: 'Declaration',
        },
        {
            id: 6,
            content: `${visitRequestReview.dates?.length} Days`,
            title: 'Dates Requested',
        },
    ];

    const scheduleMappedDates = visitRequestReview.dates?.map(date => ({
        id: date.id,
        date: date.date,
        startTime: date.startTime,
        endTime: date.endTime,
        securityOfficer: date?.securityOfficer,
        securityOfficerId: date?.securityOfficerId,
    }));

    return (
        <Main
            className="login"
            title={<div>Visitor Request</div>}
            subHeaderTitle="Visitor Request"
            subHeaderHasBackLink
            subHeaderBackLinkCallback={handleBackLinkPress}
            subHeaderRightAction={(
                <Button type="link" className="next-request-button" onClick={() => metadata?.nextRequestId && navigation(`/visitor-view-request/${metadata.nextRequestId}`, { state: { filters } })}>
                    <span style={{ textDecorationLine: 'none', color: !metadata?.nextRequestId ? '#C0D1D8' : '', cursor: !metadata?.nextRequestId ? 'not-allowed' : '' }}>
                        Next Request
                    </span>
                    <RightBoxIcon s={!metadata?.nextRequestId ? '#C0D1D8' : ''} />
                </Button>
            )}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            showFooter={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2020</div>}
            floatingFooter>
            <Spin spinning={isUpdating || isFetching}>
                <div className="main-visitor-container">
                    <div className="visitor-request-view">
                        <Status currentStatus={Number(status)} labels={statusLabels} />
                        <div style={{ marginTop: 50 }}>
                            <h1>Overview</h1>
                            <div className="overview-container">
                                <div className="overview-card-container">
                                    {infoCard.slice(0, userData.role.accessLevel === ADMIN_USER || SCORCHSOFT_USER ? 4 : 6).map(card => (
                                        <div key={card.id} className="overview-card">
                                            <div className="overview-content">
                                                <span className="overview-text">{card.title}</span>
                                                <span className="overview-content-text">{card.content || 'N/A'}</span>
                                                {card.id === 6 && <Button type="link" className="show-date-button">Show All Dates</Button>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* Antd dropdown - consistency */}
                                <Dropdown
                                    dropdownRender={renderActionsDropdown}
                                    trigger={['click']}
                                    align={{ offset: [-104, 10] }}
                                    open={isDropdownOpen}>
                                    <Button
                                        type="primary"
                                        style={{
                                            width: 204,
                                            marginTop: 10,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            paddingLeft: 40,
                                            paddingRight: 22,
                                        }}
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                        Actions
                                        <span className="vertical-line" />
                                        <Icon
                                            component={actionButtonIcon as unknown as React.ForwardRefExoticComponent<unknown>} />
                                    </Button>
                                </Dropdown>
                            </div>
                            {statusData.currentStatusStep !== 2 && statusData.currentStatusStep !== 1 && (
                                <div>
                                    <ViewTable data={visitRequestReview} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#EDF2F4', alignItems: 'center', display: 'grid', width: '100vw', marginTop: 50 }}>
                        <FormDetails details={visitRequestReview} id={id} isCostApproverByPass={visitRequestReview.costApproverByPass} hasCostApprove={visitRequestReview.hasCostApprove} />
                    </div>
                    <div>
                        <ActionModal
                            ref={actionModalRef}
                            visible={modalVisible}
                            onConfirm={(action, message) => handleConfirmAction(action, message)}
                            onCancel={() => setModalVisible(false)}
                            action={actionStatus} />
                        <ConfirmApprove
                            visible={modalApprovedVisible}
                            onConfirm={() => handleConfirmRedirect(true)}
                            onCancel={() => handleConfirmRedirect(false)} />
                        <div />
                    </div>
                </div>
                <PushActionModal
                    ref={pushActionModalRef}
                    isModalOpen={modalPushVisible}
                    handleCancel={() => setModalPushVisible(false)}
                    parentWorkType={visitRequestReview.pushActionData?.parentWorkType}
                    childrenWorkTypes={visitRequestReview.pushActionData?.childrenWorkTypes}
                    defaultApprover={visitRequestReview.pushActionData?.defaultApprover}
                    approvers={visitRequestReview.availableApprovers}
                    fetchData={refreshApprovers}
                    loading={isPushing}
                    handlePushToApprover={handlePushAction} />
                <ScheduleModal
                    shiftData={scheduleMappedDates}
                    isModalOpen={isScheduledModalOpen}
                    handleCancel={() => setIsScheduledModalOpen(false)}
                    handleComplete={(payload) => handleScheduleAction(payload)}
                    loading={isScheduling}
                    securityTeam={visitRequestReview.securityOfficers} />
                <LockedRequestModal
                    isModalOpen={isOverruleModalOpen}
                    handleCancel={() => setIsOverruleModalOpen(false)}
                    handleOverrule={handleOverrule} />
            </Spin>
        </Main>
    );
};

export default VisitorRequestView;

VisitorRequestView.propTypes = {};
