import React, { FC } from 'react';
import './visitorList.scss';
import FormSection from '../FormSection';

interface VisitorListProps {
    visitors: {
        id: number;
        name: string;
    }[];
}

const VisitorList: FC<VisitorListProps> = ({ visitors }) => (
    <FormSection title="Visitors Added">
        <div className="visitor-list-container">
            {visitors.map((visitor) => <div className="visitor" key={`vl-${visitor}`}>{visitor.name}</div>)}
        </div>
    </FormSection>
);

export default VisitorList;
