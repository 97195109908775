import axios, { AxiosResponse, ResponseType } from 'axios';
// import qs from 'qs';
import lockr from 'lockr';
import { isUndefined, isEmpty, isObject } from 'underscore';
import appConstants from '../constants';
// load mock data if enabled
import './mock';

const {
    API_URL,
    AUTH_KEY,
    BACKEND,
    GOOGLE_MAPS_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = appConstants;

// ----- Helpers ----- //
// Get Api Url
const getApiUrl = () => `${API_URL}/api`;

// Get hostname url
const getBaseUrl = () => API_URL;

// defaults for axios
const api = axios.create({
    baseURL: getApiUrl(),
});

// Get locational data
const locationData = (name:string) => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${name}&key=${GOOGLE_MAPS_KEY}`);

interface payloadProps {
    [key: string]: []|object|null|undefined|string|number;
}
// Format params for api call
const formatParams = (payload: payloadProps, key:number|string) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }
    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = ():object => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //
interface OptsProps {
    method: string;
    url: string;
    body?: object;
    params?: object;
    headers?: object;
    responseType?: ResponseType;
    data?: string|object|null|undefined;
}
function fetchApi(opts:OptsProps, headers:object|null = null):Promise<AxiosResponse> {

    const data = (opts.method.toUpperCase() === 'GET') ? null : opts.body;
    const processedData = data; // BACKEND === 'yii' && data ? qs.stringify(data) : data;
    const laravelHeaders = BACKEND !== 'yii' ? {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    } : {};

    const options:OptsProps = {
        method: opts.method,
        url: opts.url,
        responseType: opts.responseType || 'json',
        params: opts.params || {},
        headers: { ...laravelHeaders, ...opts.headers },
    };

    if (opts.method.toUpperCase() !== 'GET') {
        options.data = processedData;
    }

    if (!isEmpty(headers)) {
        options.headers = { ...options.headers, ...headers };
    }

    return api(options);
}

function fetchApiAuth(opts:OptsProps):Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
        if (isUndefined(getAuthKey())) {
            reject(new Error('not-authorised'));
        } else {
            const authDate = new Date();
            lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
            resolve(fetchApi(opts, getAuthHeaders()));
        }
    });
}

export {
    getApiUrl,
    getBaseUrl,
    locationData,
    formatParams,
    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
};
