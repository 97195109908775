import React, { FC, useEffect, useState } from 'react';
import type { Area, Date, SiteAccess } from 'core/visitRequestForm/types';
import type { DropdownOption } from 'components/Elements/Dropdown/types';
import CheckboxDropdown from 'components/Elements/CheckboxDropdown';
import CheckboxSelector from 'components/Elements/CheckboxSelector';
import { Modal, notification } from 'antd';
import Checkbox from 'components/Elements/Checkbox';
import moment from 'moment';
import { changeSelectedDate } from 'core/visitRequestForm/visitRequestFormApi';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedStates, AppDispatch } from 'core/types';
import FormSection from '../FormSection';
import './visitorSiteAccess.scss';
import SavedShiftDate from './SavedShiftDate';
import type { SavedShiftDateObject } from './types';
import ChangeSelectedDateModal from './ChangeSelectedDateModal';

interface VisitorSiteAccessProps {
    savedShiftDates: SiteAccess[];
    siteAreas: Area[];
    handleSave: (values: SiteAccess[]) => void;
}

const VisitorSiteAccess: FC<VisitorSiteAccessProps> = ({ savedShiftDates, siteAreas, handleSave }) => {

    const [applyToAll, setApplyToAll] = useState<boolean>(true);
    const [selectedVisitors, setSelectedVisitors] = useState<number[]>([]);
    const [selectedDates, setSelectedDates] = useState<DropdownOption[]>([]);
    const [selectedSiteAreas, setSelectedSiteAreas] = useState<DropdownOption[]>([]);
    const [selectedDateToChange, setSelectedDateToChange] = useState<number | null>(null);
    const { visitRequest, applyToAllVisitors } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { dates, site, visitors } = visitRequest;

    const dispatch = useDispatch<AppDispatch>();

    let savedShiftObjects: SavedShiftDateObject[] = [];
    if (savedShiftDates.length && dates && siteAreas && visitors) {
        savedShiftObjects = savedShiftDates.map((shiftDate) => {
            const date = dates.find((dateObj: Date) => dateObj.id === shiftDate.dateShiftId);
            if (!date) {
                return {
                    id: shiftDate.dateShiftId,
                    date: 'Not found',
                    rawDate: 'Not found',
                    time: 'Not found',
                    areas: 'Not found',
                    visitors: 'Not found',
                };
            }
            const rawDate = date.date;
            const formattedDate = moment(rawDate).format('dddd Do MMMM');
            const formattedTime = `${moment(date.startTime, 'HH:mm').format('h A')} - ${moment(date.endTime, 'HH:mm').format('h A')}`;
            const areas = siteAreas.filter((area) => shiftDate.siteAreaIds.includes(area.id));
            const areasList = areas.map((area) => area.name).join(', ');
            const shiftVisitors = visitors.filter((visitor) => shiftDate.visitorIds.includes(visitor.id));
            const visitorsList = shiftVisitors.map((visitor) => visitor.name).join(', ');
            return {
                id: shiftDate.dateShiftId,
                date: formattedDate,
                rawDate,
                time: formattedTime,
                areas: areasList,
                visitors: visitorsList,
            };
        });
        // Sort by raw date
        savedShiftObjects.sort((a, b) => new Date(a.rawDate).getTime() - new Date(b.rawDate).getTime());
    }

    let shiftOptions: DropdownOption[] = [];
    if (dates?.length) {
        const savedDateIds = savedShiftDates?.map((shiftDate) => shiftDate.dateShiftId);
        const sortedDates = [...dates]?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        shiftOptions = sortedDates?.filter((date) => !savedDateIds.includes(date.id)).map((date) => ({
            label: `${moment(date.date).format('dddd Do MMMM')}, ${moment(date.startTime, 'HH:mm').format('h A')} - ${moment(date.endTime, 'HH:mm').format('h A')}`,
            value: date.id.toString(),
        }));
    }

    let siteAreaOptions: DropdownOption[] = [];
    if (siteAreas?.length) {
        siteAreaOptions = siteAreas.map((area) => ({
            label: area.name,
            value: area.id.toString(),
        }));
    }
    siteAreaOptions.unshift({
        label: 'All Areas',
        value: 'all',
    });

    let visitorOptions: DropdownOption[] = [];
    if (visitors?.length) {
        visitorOptions = visitors.map((visitor) => ({
            label: visitor.name,
            value: visitor.id.toString(),
        })).sort((a, b) => a.label.localeCompare(b.label));
    }

    const handleDateSelect = (values: DropdownOption[]) => {
        setSelectedDates(values);
    };

    const handleSiteAreaSelect = (values: DropdownOption[]) => {
        if (selectedSiteAreas.some(area => area.value === 'all') && !values.some(value => value.value === 'all')) {
            setSelectedSiteAreas([]);
        } else if (values.some(value => value.value === 'all')) {
            if (selectedSiteAreas.some(area => area.value === 'all')) {
                setSelectedSiteAreas(values.filter(value => value.value !== 'all'));
            } else {
                const unsecureSiteAreas = siteAreas.filter((area) => !area.hasSecuredHosting)
                    .map((area) => ({ label: area.name, value: area.id.toString() }));
                unsecureSiteAreas.unshift({ label: 'All Areas', value: 'all' });
                setSelectedSiteAreas(unsecureSiteAreas);
            }
        } else {
            setSelectedSiteAreas(values);
        }
    };

    const handleSelectVisitorChange = (values: string[]) => {
        setSelectedVisitors(values.map((value) => parseInt(value, 10)));
    };

    const handleApplyToAllClick = () => {
        setApplyToAll(!applyToAll);
    };

    useEffect(() => {
        if (applyToAllVisitors) {
            setSelectedDates(shiftOptions);
        }
    }, [applyToAllVisitors]);

    const validateSelections = () => {
        let error = { message: '', description: '' };
        if (selectedDates.length === 0) {
            error = {
                message: 'Date Not Selected',
                description: 'Please select at least one date to proceed',
            };
        } else if (selectedSiteAreas.length === 0) {
            error = {
                message: 'Site Area Not Selected',
                description: 'Please select at least one site area to proceed',
            };
        } else if (!applyToAll && selectedVisitors.length === 0) {
            error = {
                message: 'Visitors Not Selected',
                description: 'Please select at least one visitor to proceed',
            };
        }
        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return false;
        }
        return true;
    };

    const getUniqueShiftDates = (newShiftDates: SiteAccess[]) => {
        const mergedShifts = [...newShiftDates, ...savedShiftDates];
        return Array.from(new Map(mergedShifts.map(shift => [shift.dateShiftId, shift])).values());
    };

    const resetState = () => {
        setSelectedDates([]);
        setSelectedSiteAreas([]);
        setSelectedVisitors([]);
        setApplyToAll(true);
    };

    const handleSaveAreaAccess = () => {
        if (!validateSelections()) {
            return;
        }
        const newSavedShiftDates = selectedDates.map((date) => ({
            dateShiftId: parseInt(date.value, 10),
            siteAreaIds: selectedSiteAreas
                .filter(area => area.value !== 'all')
                .map((area) => parseInt(area.value, 10)),
            visitorIds: applyToAll ? visitorOptions.map((visitor) => parseInt(visitor.value, 10)) : selectedVisitors,
        }));
        handleSave(getUniqueShiftDates(newSavedShiftDates));
        resetState();
    };

    const onRemove = (dateId: number) => {
        const newSavedShiftDates = savedShiftDates.filter((shiftDate) => shiftDate.dateShiftId !== dateId);
        handleSave(newSavedShiftDates);
    };

    const onEdit = (dateId: number) => {
        const savedShiftDate = savedShiftDates.find((shiftDate) => shiftDate.dateShiftId === dateId);
        if (savedShiftDate) {
            const shiftOption = dates.find((date) => date.id === dateId);
            const editSiteAreas = siteAreaOptions.filter((area) => savedShiftDate.siteAreaIds.includes(parseInt(area.value, 10)));
            const newSelectedDate = shiftOption ? [{ label: `${moment(shiftOption.date).format('dddd Do MMMM')}, ${moment(shiftOption.startTime, 'HH:mm').format('h A')} - ${moment(shiftOption.endTime, 'HH:mm').format('h A')}`, value: shiftOption.id.toString() }] : [];
            setSelectedDates(newSelectedDate);
            if (savedShiftDate) {
                setSelectedVisitors(savedShiftDate.visitorIds);
            }
            if (siteAreas.length > 0) {
                setSelectedSiteAreas(editSiteAreas);
            }
            onRemove(dateId);
        }
    };

    const handleChangeSelectedDateClick = (item: DropdownOption) => {
        setSelectedDateToChange(parseInt(item.value, 10));
    };

    const handleSaveChangeSelectedDate = (newDate: string) => {
        if (newDate && selectedDateToChange !== null) {
            dispatch(changeSelectedDate({ id: selectedDateToChange, newDate }));
            setSelectedDateToChange(null);
        }
        setSelectedDateToChange(null);
    };

    return (
        <>
            <Modal
                open={selectedDateToChange !== null}
                onCancel={() => setSelectedDateToChange(null)}
                footer={null}
                width={810}
                className="change-date-modal">
                {selectedDateToChange && (
                    <ChangeSelectedDateModal
                        selectedDateId={selectedDateToChange}
                        onSave={handleSaveChangeSelectedDate} />
                )}
            </Modal>
            <FormSection title="Visitor Access to Site Areas">
                <div className="visitor-site-access-container">
                    <div className="selected-site-wrapper">
                        <h3 className="heading">Selected Site</h3>
                        <div className="detail-wrapper site-type">
                            <h5 className="label">Site Type: </h5>
                            <p className="caption">{site?.type}</p>
                        </div>
                        <div className="detail-wrapper site-address">
                            <h5 className="label">Site Address:</h5>
                            <p className="caption">{site?.fullAddress}</p>
                        </div>
                    </div>
                    {savedShiftObjects.map((shiftDate: SavedShiftDateObject) => <SavedShiftDate key={shiftDate.id} shiftDate={shiftDate} onEdit={onEdit} onRemove={onRemove} />)}
                    <div className="select-shift-container">
                        <div className="select-shift-wrapper">
                            <h5 className="heading dropdown-heading">Select Shift for Visit</h5>
                            <CheckboxDropdown
                                options={shiftOptions}
                                onSelect={handleDateSelect}
                                values={selectedDates}
                                endLabel="Change Selected Date"
                                handleEndLabel={handleChangeSelectedDateClick} />
                        </div>
                        <div className="site-areas-wrapper">
                            <h5 className="heading dropdown-heading">Site Areas</h5>
                            <div className="dropdown-wrapper">
                                <CheckboxDropdown
                                    options={siteAreaOptions}
                                    onSelect={handleSiteAreaSelect}
                                    values={selectedSiteAreas} />
                            </div>
                        </div>
                        <div className="apply-to-all-container">
                            <Checkbox checked={applyToAll} onClick={handleApplyToAllClick} />
                            <span className="label">Apply this site area access to all users added to this Visitor Request Form</span>
                        </div>

                        {!applyToAll && (
                            <div className="select-visitors-container">
                                <h5 className="heading">Select Visitors</h5>
                                <CheckboxSelector
                                    options={visitorOptions}
                                    values={selectedVisitors.map((visitor) => visitor.toString())}
                                    onChange={handleSelectVisitorChange} />
                            </div>
                        )}

                        <div className="button-wrapper">
                            <button type="button" className="save-button" onClick={handleSaveAreaAccess}>Save Area Access</button>
                        </div>

                    </div>
                </div>
            </FormSection>
        </>
    );
};

export default VisitorSiteAccess;
