/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { redirect } from 'react-router-dom';
import { notification } from 'antd';
import { VisitorRequestReview, VisitRequestReviewState, DataProps, ResubmitProps, CheckSessionProps, AddCommentProps, FetchApprovers, PushSuccess, ScheduleSuccess, ReleaseSuccess } from './types';
import { getVisitRequestReview, resubmitActionRequest, updateVisitRequestReview, checkRequestSession, overruleSession, addComment, fetchApprovers, pushToApprover, scheduleRequest, releaseSession } from './visitRequestReviewApi';

const emptyVisitRequestState: VisitorRequestReview = {
    id: null,
    encryptedId: '',
    site: null,
    approvers: [],
    requester: null,
    approver: null,
    costApproverByPass: false,
    costApprover: null,
    hasCostApprove: false,
    status: '',
    currentStatusStep: 0,
    shifts: null,
    visitors: null,
    visitorsNumber: 0,
    dates: null,
    dateAndTime: null,
    siteAccess: null,
    workTypeName: null,
    workTypes: null,
    workDetails: null,
    workDescription: null,
    comments: [],
    totalHours: 0,
    declaration: {
        signature: null,
        agreed: false,
    },
    availableApprovers: [],
    pushActionData: {
        defaultApprover: null,
        parentWorkType: null,
        childrenWorkTypes: null,
    },
    dateSubmitted: '',
    securityOfficers: [],
    attachments: [],
    lastCheckedIn: [],
};

const initialState = {
    error: null,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isCheckingSession: false,
    isFetchingApprovers: false,
    isScheduling: false,
    isPushing: false,
    isReleasing: false,
    lockedStatus: {
        isLocked: false,
        lockedBy: '',
        overriden: false,
        requestId: null,
    },
    performingAction: false,
    visitRequestReview: emptyVisitRequestState,
    statusData: {
        currentStatusStep: 0,
        inActiveActions: {},
    },
    metadata: {
        nextRequestId: null,
        pastRequestId: null,
    },
    success: false,
} as VisitRequestReviewState;

export const visitRequestReviewSlice = createSlice({
    name: 'visitRequestReview',
    initialState,
    reducers: {
        resetLockedStatus: (state: VisitRequestReviewState) => {
            state.lockedStatus = {
                isLocked: false,
                lockedBy: '',
                overriden: false,
                requestId: null,
            };
        },
    },
    extraReducers: (builder) => {
        // Load visit request review data
        builder
            .addCase(getVisitRequestReview.pending, (state: VisitRequestReviewState) => {
                state.isFetching = true;
                state.error = null;
                state.lockedStatus = {
                    isLocked: false,
                    lockedBy: '',
                    overriden: false,
                    requestId: null,
                };
            });
        builder
            .addCase(getVisitRequestReview.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<DataProps>,
            ) => {
                state.isFetching = false;
                state.visitRequestReview = action.payload.visitRequest;
                state.statusData = action.payload.statusData;
                state.metadata = action.payload.metadata;
                state.lockedStatus.isLocked = action.payload.lockedData.isLocked;
                state.lockedStatus.lockedBy = action.payload.lockedData.lockedBy;
            });
        builder
            .addCase(getVisitRequestReview.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
                state.visitRequestReview = emptyVisitRequestState;
            });

        // Update visit request review data
        builder
            .addCase(updateVisitRequestReview.pending, (state: VisitRequestReviewState) => {
                state.isUpdating = true;
                state.error = null;
                state.success = false;
            });
        builder
            .addCase(updateVisitRequestReview.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<DataProps>, // here
            ) => {
                state.isUpdating = false;
                state.visitRequestReview = action.payload.visitRequest;
                state.statusData = action.payload.statusData;
                if (action.payload.metadata) {
                    state.metadata = action.payload.metadata;
                }
                state.success = true;
            });
        builder
            .addCase(updateVisitRequestReview.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isUpdating = false;
                state.error = action;
                state.success = false;
            });

        // Resubmit action
        builder
            .addCase(resubmitActionRequest.pending, (state: VisitRequestReviewState) => {
                state.performingAction = true;
                state.error = null;
            });
        builder
            .addCase(resubmitActionRequest.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<ResubmitProps>,
            ) => {
                const { visitRequestId, step } = action.payload;

                state.performingAction = false;
                notification.success({
                    message: 'Success',
                    description: 'The visit request has been copied for resubmission.',
                });
                redirect(`/visitor-request/${visitRequestId}/step/${step}`);
            });
        builder
            .addCase(resubmitActionRequest.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.performingAction = false;
                state.error = action;
            });

        // Check request session
        builder
            .addCase(checkRequestSession.pending, (state: VisitRequestReviewState) => {
                state.isCheckingSession = true;
                state.error = null;
                state.lockedStatus.overriden = false;
            });
        builder
            .addCase(checkRequestSession.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<CheckSessionProps>,
            ) => {
                state.lockedStatus.isLocked = false;
                state.isCheckingSession = false;

                state.lockedStatus.lockedBy = action.payload.overruledBy;
                state.lockedStatus.overriden = !action.payload.success;
                state.lockedStatus.requestId = state.visitRequestReview.id;
            });
        builder
            .addCase(checkRequestSession.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isCheckingSession = false;
                state.error = action;
            });

        // Overrule Session:
        builder
            .addCase(overruleSession.pending, (state: VisitRequestReviewState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(overruleSession.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<CheckSessionProps>,
            ) => {
                state.isFetching = false;
                state.error = null;
                if (!action.payload.success) {
                    state.lockedStatus.isLocked = false;
                    state.lockedStatus.lockedBy = '';
                }
            });
        builder
            .addCase(overruleSession.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
            });

        // Fetching approvers:
        builder
            .addCase(fetchApprovers.pending, (state: VisitRequestReviewState) => {
                state.isFetchingApprovers = true;
                state.error = null;
            });
        builder
            .addCase(fetchApprovers.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<FetchApprovers>,
            ) => {
                state.isFetchingApprovers = false;
                state.visitRequestReview.availableApprovers = action.payload.approvers;
            });
        builder
            .addCase(fetchApprovers.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isFetchingApprovers = false;
                state.error = action;
            });

        // Push to approver:
        builder
            .addCase(pushToApprover.pending, (state: VisitRequestReviewState) => {
                state.isPushing = true;
                state.error = null;
            });
        builder
            .addCase(pushToApprover.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<PushSuccess>,
            ) => {
                state.isPushing = false;
                state.visitRequestReview.approvers = action.payload.approvers;

                notification.success({
                    message: 'Success',
                    description: action.payload.message,
                });
            });
        builder
            .addCase(pushToApprover.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isPushing = false;
                state.error = action;
            });

        // add comment:
        builder
            .addCase(addComment.pending, (state: VisitRequestReviewState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(addComment.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<AddCommentProps>,
            ) => {
                state.isFetching = false;
                state.visitRequestReview = action.payload.visitRequest;
            });
        builder
            .addCase(addComment.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
            });

        // Schedule:
        builder
            .addCase(scheduleRequest.pending, (state: VisitRequestReviewState) => {
                state.isScheduling = true;
                state.error = null;
            });
        builder
            .addCase(scheduleRequest.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<ScheduleSuccess>,
            ) => {
                state.isScheduling = false;
                const { dates } = state.visitRequestReview;
                const copiedDates = dates ? [...dates] : [];
                const { date, status, statusData } = action.payload;

                // remove date with id of the new date
                const newDates = copiedDates.filter(d => d.id !== date.id);
                const finalDates = [...newDates, date];

                // sort according to date and startTime, using moment and asserting they are strings
                finalDates.sort((a, b) => {
                    const aDate = new Date(a.date.toString()).getTime();
                    const bDate = new Date(b.date.toString()).getTime();
                    return aDate - bDate;
                });

                state.visitRequestReview.dates = finalDates;
                state.visitRequestReview.status = status;
                state.statusData = statusData;

                notification.success({
                    message: 'Success',
                    description: action.payload.message,
                });
            });
        builder
            .addCase(scheduleRequest.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isScheduling = false;
                state.error = action;
            });

        // Release:
        builder
            .addCase(releaseSession.pending, (state: VisitRequestReviewState) => {
                state.isReleasing = true;
                state.error = null;
            });
        builder
            .addCase(releaseSession.fulfilled, (
                state: VisitRequestReviewState,
                action: PayloadAction<ReleaseSuccess>,
            ) => {
                state.isReleasing = !(action.payload.success);
            });
        builder
            .addCase(releaseSession.rejected, (
                state: VisitRequestReviewState,
                action: object,
            ) => {
                state.isReleasing = false;
                state.error = action;
            });
    },
});

export default visitRequestReviewSlice.reducer;
export const { resetLockedStatus } = visitRequestReviewSlice.actions;
