import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CombinedStates } from 'core/types';
import { Button } from 'antd';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import Stepper from 'components/Form/Stepper';
import { BackArrowIcon, NextArrowIcon } from 'components/Elements/CustomSVGIcon';
import LoadingOverlay from 'components/Loading/LoadingOverlay';
import { ADMIN_ACCESS_LEVELS } from 'core/utils/value';

import { LayoutProps } from './types';
import Main from '../Main';

import './requestFormLayout.scss';

const RequestFormLayout: React.FC<LayoutProps> = ({ children, currentStep = 1, onBackClick, onNextClick, isBackEnabled, isNextEnabled, nextText }) => {
    const { state: navigationState } = useLocation();
    const { isUpdating, isFetching } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { userData } = useSelector((state: CombinedStates) => state.user);

    const toolbar = [
        { label: 'Dashboard', link: ADMIN_ACCESS_LEVELS.includes(userData?.role?.accessLevel) ? '/dashboard' : '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];

    const stepperLabels = ['Site Selection', 'Select Date', 'Add Visitors', 'Site Access', 'Work Type', 'Review'];

    const backLink = (
        <Button type="link" onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    useEffect(() => {
        // scroll to top on render
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);

    return (
        <Main
            className="request-form"
            title={<div>Visitor Request Form</div>}
            subHeaderTitle="Visitor Request Form"
            subHeaderHasBackLink
            subHeaderBackLink="/dashboard"
            subHeaderRightAction={(
                <div className="request-header-date">
                    {moment().format('DD MMM YYYY')}
                </div>
            )}
            showFooter={false}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2020</div>}
            floatingFooter
            backLink={backLink}
            breadcrumb={navigation}>
            <div className="main-outer-content">
                <LoadingOverlay loading={isFetching} message="Loading..." />
                <Stepper currentStep={currentStep} labels={stepperLabels} />
                { children }
                <div className="visitor-request-buttons-wrapper">
                    <Button type="primary" ghost onClick={() => onBackClick && onBackClick(navigationState)} disabled={!isBackEnabled}>
                        <span>
                            <BackArrowIcon style={{ marginRight: '10px' }} s={isBackEnabled ? '#da0812' : '#C0D1D8'} />
                            Back
                        </span>
                    </Button>
                    <Button type="primary" onClick={() => onNextClick(navigationState)} disabled={!isNextEnabled}>
                        <span>
                            {nextText}
                            { !isUpdating
                                ? <NextArrowIcon style={{ marginLeft: '10px' }} s={isNextEnabled ? '#ffffff' : '#C0D1D8'} />
                                : <LoadingOutlined style={{ marginLeft: '10px', marginRight: '0px' }} /> }
                        </span>
                    </Button>
                </div>
            </div>
        </Main>
    );
};

RequestFormLayout.propTypes = {
    nextText: PropTypes.string,
};

RequestFormLayout.defaultProps = {
    nextText: 'Next',
};

export default RequestFormLayout;
