import React, { FC, useState, useEffect } from 'react';
import './accessCardsModal.scss';
import TextInput from 'components/Elements/TextInput';
import { CircularAddIcon, SearchIcon } from 'components/Elements/CustomSVGIcon';
import { useDispatch, useSelector } from 'react-redux';
import { addNewAccessCard, getAccessCards } from 'core/securityDashboard/securityDashboardApi';
import { AppDispatch, CombinedStates } from 'core/types';
import type { TemporaryAccessCard } from 'core/securityDashboard/types';
import Checkbox from 'components/Elements/Checkbox';
import { SaveAccessCardValues } from 'pages/VisitorsOnSite/types';
import { Empty, Spin } from 'antd';

interface AccessCardsModalProps {
    onSave: (values: SaveAccessCardValues) => void;
    onCancel: () => void;
    selectedVisitorIds: string[];
    visitRequestId: string | undefined;
}

const AccessCardsModal: FC<AccessCardsModalProps> = ({ onSave, onCancel, selectedVisitorIds, visitRequestId }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [applyToAll, setApplyToAll] = useState<boolean>(false);
    const [selectedCardId, setSelectedCardId] = useState<string>('');
    const [error, setError] = useState<string>('');

    const dispatch = useDispatch<AppDispatch>();
    const { temporaryAccessCards, isFetching } = useSelector((state: CombinedStates) => state.securityDashboard);
    const { availableKeys } = temporaryAccessCards;
    const filteredCards = availableKeys.filter((key) => key.description.toLowerCase().includes(searchValue.toLowerCase()));

    useEffect(() => {
        if (visitRequestId !== undefined) {
            dispatch(getAccessCards({
                visitRequestId,
            }));
        }
    }, [visitRequestId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleApplyToAllClick = () => {
        setApplyToAll(!applyToAll);
    };

    const handleAddNewCardClick = () => {
        dispatch(addNewAccessCard({
            description: searchValue,
            visitRequestId,
        }));
        setSearchValue('');
    };

    const handleSelectedCardChange = (cardId: string) => {
        setError('');
        if (selectedCardId === cardId) {
            setSelectedCardId('');
        } else {
            setSelectedCardId(cardId);
        }
    };

    const handleSaveClick = () => {
        if (selectedCardId === '') {
            setError('Please select an access card');
            return;
        }
        onSave({ selectedCardId, applyToAll });
        setSelectedCardId('');
        setApplyToAll(false);
    };

    const getAccessCardEntry = (card: TemporaryAccessCard) => {
        const handleCheckboxClick = () => {
            handleSelectedCardChange(card.id.toString());
        };

        return (
            <div key={card.id} className="access-card-entry">
                <Checkbox checked={selectedCardId === card.id.toString()} onClick={handleCheckboxClick} />
                <span className="label">{card.description}</span>
            </div>
        );
    };

    const renderAccessCards = () => {
        if (filteredCards.length === 0) {
            return (
                <Empty description="No access cards found" />
            );
        }

        return filteredCards.map((card: TemporaryAccessCard) => getAccessCardEntry(card));
    };

    return (
        <div className="access-cards-modal-container">
            <h1 className="heading">Temporary Access Card</h1>
            <div className="content-wrapper">
                <p className="caption mb-8">Select Access Card</p>
                <TextInput
                    value={searchValue}
                    placeholder="Search Access Card"
                    onChange={handleSearchChange}
                    leftIcon={<SearchIcon />} />
                <div className="access-cards-list-container">
                    {searchValue && (
                        <div className="add-new-card-container">
                            <div className="new-card-wrapper"><span className="add-label">Add</span>
                                <span className="new-card-name">&quot;{searchValue}&quot;</span>
                            </div>
                            <div className="icon-wrapper" onClick={handleAddNewCardClick}>
                                <CircularAddIcon />
                            </div>
                        </div>

                    )}
                    <div className="access-cards-list">
                        {isFetching
                            ? (
                                <div className="fetching-wrapper">
                                    <Spin />
                                </div>
                            )
                            : renderAccessCards()}
                    </div>
                </div>
                {selectedVisitorIds.length > 0 ? (
                    <div className="apply-to-all-wrapper">
                        <Checkbox checked={applyToAll} onClick={handleApplyToAllClick} />
                        <span className="label">Apply this Temporary Access Card to all selected visitors</span>
                    </div>
                ) : null}
                <div className="button-wrapper">
                    <button
                        onClick={handleSaveClick}
                        className="save-button"
                        type="button">Save
                    </button>
                    {error && <p className="error">{error}</p>}
                    <button
                        onClick={onCancel}
                        className="cancel-button"
                        type="button">Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccessCardsModal;
