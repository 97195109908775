import React, { FC } from 'react';
import Select, { SelectProps, SelectState } from 'react-dropdown-select';
import './checkboxDropdown.scss';
import Checkbox from '../Checkbox';
import type { DropdownOption } from '../Dropdown/types';

interface DropdownProps {
    options: DropdownOption[];
    onSelect: (values: DropdownOption[]) => void;
    values: DropdownOption[];
    endLabel?: string;
    handleEndLabel?: (item: DropdownOption) => void;
}

const customStyles = {
    minHeight: '50px',
    borderRadius: '4px',
    border: '0.5px solid #2B2D42',
};

const Dropdown: FC<DropdownProps> = ({ options, onSelect, values = [], endLabel, handleEndLabel }) => {

    const removeItem = (item: DropdownOption) => {
        const newValues = values.filter((value) => value.value !== item.value);
        onSelect(newValues);
    };

    const addItem = (item: DropdownOption) => {
        const newValues = [...values, item];
        onSelect(newValues);
    };

    const handleOnChange = () => {
        // console.log('handleOnChange');
    };

    const getContentRenderer = (props: SelectProps<DropdownOption>, state: SelectState<DropdownOption>) => (
        <div className="custom-content-renderer">
            {state.values.length} of {props.options.length} selected
        </div>
    );

    const getItem = (item: DropdownOption) => {
        const valueLabels = values.map((value) => value.label);
        const included = valueLabels.includes(item.label);

        const handleOnClick = () => {
            if (included) {
                removeItem(item);
            } else {
                addItem(item);
            }
        };

        const handleEndLabelClick = () => {
            if (handleEndLabel) {
                handleEndLabel(item);
            }
        };

        return (
            <div className="item-wrapper">
                <Checkbox checked={included} onClick={handleOnClick} />
                <div className="labels-wrapper">
                    <span className="item-label">{item.label}</span>
                    <div onClick={handleEndLabelClick}>
                        <span className="end-label">{endLabel}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Select
                contentRenderer={({ props, state }) => getContentRenderer(props, state)}
                searchable={false}
                style={customStyles}
                options={options}
                onChange={handleOnChange}
                values={values}
                multi
                color="#da0812"
                itemRenderer={({ item }) => getItem(item)} />
        </div>
    );
};

Dropdown.defaultProps = {
    endLabel: '',
    handleEndLabel: () => { console.log('end label clicked'); },
};

export default Dropdown;
