import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';
import { CSVResponseProps, ResponseProps } from './types';
import { handleError, makeUrl } from '../utils/helpers';

export const dashboardVisitRequest = createAsyncThunk(
    'dashboard/visitRequest',
    async (payload, { rejectWithValue }) => {
        try {
            const response:ResponseProps = await fetchApiAuth({
                method: 'GET',
                url: 'visit-request/all',
            });
            return response.data;
        } catch (error:unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const createRequest = createAsyncThunk(
    'dashboard/createRequest',
    async (payload:{id: string|undefined; site: string; requester: string; status: string|undefined;}, { rejectWithValue }) => {
        const { id, site, requester, status } = payload;
        try {
            const response:ResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'visit-request/create',
                body: {
                    id,
                    site,
                    requester,
                    status,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const dashboardFilterRequest = createAsyncThunk(
    'dashboard/filterRequest',
    async (payload:{q?: string|string[]|undefined; site?: Array<string>|string|undefined; status?: Array<string>|string|undefined; startDate?:string|string[]|undefined, endDate?:string|string[]|undefined, page?:number|undefined, sort:string|null}, { rejectWithValue }) => {
        const { ...filterSearchUrl } = payload;
        const url = makeUrl('/visit-request/all', filterSearchUrl);
        try {
            const response:ResponseProps = await fetchApiAuth({
                method: 'GET',
                url,
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const exportCompletedVisits = createAsyncThunk(
    'dashboard/exportCompletedVisits',
    async (payload: { q?: string|string[]|undefined; site?: Array<string>|string|undefined; status?: Array<string>|string|undefined; startDate?:string|string[]|undefined, endDate?:string|string[]|undefined; hourlyRate: string }, { rejectWithValue }) => {
        const { q, hourlyRate, site, startDate, endDate } = payload;
        try {
            const response: CSVResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'export-completed-visits',
                body: {
                    search: q, // convert array to string
                    hourlyRate,
                    site,
                    startDate,
                    endDate,
                },
            });
            // create blob and download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv');
            document.body.appendChild(link);
            link.click();
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
