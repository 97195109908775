import React, { FC, useEffect, useState } from 'react';
import Checkbox from 'components/Elements/Checkbox';
import CheckboxDropdown from 'components/Elements/CheckboxDropdown';
import Dropdown from 'components/Elements/Dropdown';
import moment from 'moment';
import Button from 'components/Elements/Button';
import Tooltip from 'components/Elements/Tootltip';
import WarningBox from 'components/Elements/WarningBox';
import { TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import { DropdownOption } from 'components/Elements/Dropdown/types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isEmpty } from 'underscore';
import { WarningIcon } from '../../Elements/CustomSVGIcon';
import FormSection from '../FormSection';
import './shiftSelector.scss';
import SavedShift from './SavedShiftDisplay';
import { SaveShiftValues } from './types';

interface TimeSlot {
    label: string;
    startTime: string;
    endTime: string;
}

interface ShiftOption {
    name: string;
    value: string;
    timeSlots: TimeSlot[];
}

interface VisitDate {
    shiftType?: string | undefined | number;
    blockDate?: string | undefined | null;
    id: number | null;
    date: string;
    saved: boolean;
    shiftName?: string | undefined;
    startTime?: string | undefined;
    endTime?: string | undefined;
}

interface ShiftSelectorProps {
    visitDates: VisitDate[];
    shiftOptions: ShiftOption[];
    handleSaveShift: (values: SaveShiftValues) => void;
    handleRemoveShift: (date: string) => void;
    handleEditShift: (date: string) => void;
    totalHours: number;
    openDays: { day: string, startTime: string, endTime: string }[] | undefined;
    onChangetotalHours: (hours: number) => void;
    enabled24_7: boolean;
}

const ShiftSelector: FC<ShiftSelectorProps> = ({ visitDates, shiftOptions, handleSaveShift, handleRemoveShift, handleEditShift, totalHours, openDays, onChangetotalHours, enabled24_7 }) => {
    const originalShiftOption = { label: shiftOptions[0]?.name || '', value: shiftOptions[0]?.value || '' };
    dayjs.extend(customParseFormat);
    const [isSameShift, setIsSameShift] = useState<boolean>(true);
    const [selectedShift, setSelectedShift] = useState<DropdownOption[]>([originalShiftOption]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<DropdownOption[]>([]);
    const [selectedDates, setSelectedDates] = useState<DropdownOption[]>([]);
    const [isTimeCritical, setIsTimeCritical] = useState<boolean>(false);
    const [customShiftStartTime, setCustomShiftStartTime] = useState<Dayjs | null>(dayjs('00:00:00', 'HH:mm:ss'));
    const [customShiftEndTime, setCustomShiftEndTime] = useState<Dayjs | null>(dayjs('12:00:00', 'HH:mm:ss'));
    const [customTimeSlotStartTime, setCustomTimeSlotStartTime] = useState<Dayjs | null>(dayjs('00:00:00', 'HH:mm:ss'));
    const [timeCriticalReason, setTimeCriticalReason] = useState<string>('');
    const [isCustomShift, setIsCustomShift] = useState<boolean>(false);
    const [calculateTotalHours, setCalculateTotalHours] = useState<number>(totalHours);

    useEffect(() => {
        if (isCustomShift || selectedTimeSlot[0]?.label === 'Custom') {
            setIsSameShift(false);
        }
    }, [isCustomShift, selectedTimeSlot, visitDates]);

    useEffect(() => {
        setSelectedShift([originalShiftOption]);
    }, [originalShiftOption.label]);

    // calculate total hours function excluding open days
    const calculateTotalHour = (selectedShifts: VisitDate[]) => {
        const newTotalHours = selectedShifts.reduce((acc, curr) => {
            if (curr.startTime && curr.endTime) {
                const start = moment(curr.startTime, 'HH:mm');
                const end = moment(curr.endTime, 'HH:mm');
                if (start.hour() > end.hour()) {
                    end.add(1, 'day');
                }
                const durationMinutes = moment.duration(end.diff(start)).asMinutes();
                const hoursDecimal = durationMinutes / 60;
                return acc + hoursDecimal;
            }
            return acc;
        }, 0);
        // calculate overlapping hours between open days and selected dates
        if (!isEmpty(openDays)) {
            const overlappingHours = selectedShifts.reduce((acc, curr) => {
                let openDay;
                openDay = openDays?.find((day) => day.day === moment(curr.date).format('dddd'));

                // check if curr date is after midnight and the time after midnight is open day
                if (curr.startTime && curr.endTime && !openDay) {
                    if (curr.startTime > curr.endTime) {
                    // add one day to curr date and check if it is open day
                        const nextDay = moment(curr.date).add(1, 'day').format('dddd');
                        openDay = openDays?.find((day) => day.day === nextDay);
                    }
                }
                if (openDay && curr.startTime && curr.endTime) {
                    const start = moment(curr.startTime, 'HH:mm');
                    const end = moment(curr.endTime, 'HH:mm');
                    const openStart = moment(openDay.startTime, 'HH:mm');
                    const openEnd = moment(openDay.endTime, 'HH:mm');
                    if (openDay.day !== moment(curr.date).format('dddd')) {
                        if (openEnd.isBefore(end) && openEnd.hour() !== 0) {
                            end.set('hour', openEnd.hour());
                            end.set('minute', openEnd.minute());
                        }
                        start.set('hour', openStart.hour());
                    }
                    if (openStart.hour() !== 0) {
                        if (start.isBefore(openStart)) {
                            start.set('hour', openStart.hour());
                            start.set('minute', openStart.minute());
                        }
                    }
                    if (openEnd.hour() !== 0) {
                        if (end.isAfter(openEnd) || start.hour() > end.hour()) {
                            if (openEnd.isBetween(start, end)) {
                                end.set('hour', openEnd.hour());
                                end.set('minute', openEnd.minute());
                            } else {
                                // skip this time slot
                                return acc;
                            }
                        }
                    }
                    if (start.hour() > end.hour()) {
                        // check if next day is an open day
                        const nextDay = moment(curr.date).add(1, 'day').format('dddd');
                        const nextOpenDay = openDays?.find((day) => day.day === nextDay);
                        if (nextOpenDay) {
                            end.set('hour', 24);
                        }
                    }
                    if (start.hour() > openStart.hour() && openStart.hour() === openEnd.hour() && openStart.minute() === openEnd.minute()) {
                        start.set('hour', 24);
                    }
                    if (openStart.hour() === openEnd.hour() && openStart.minute() === openEnd.minute() && curr.startTime && curr.endTime) {
                        start.set('hour', parseInt(curr.startTime, 10));
                        end.set('hour', parseInt(curr.endTime, 10));
                    }
                    if (openStart.hour() === openEnd.hour() && openStart.minute() === openEnd.minute()) {
                        start.set('hour', parseInt(curr.startTime, 10));
                        end.set('hour', parseInt(curr.endTime, 10));
                    }
                    if (end.isBefore(start)) {
                        const nextDay = moment(curr.date).add(1, 'day').format('dddd');
                        const nextOpenDay = openDays?.find((day) => day.day === nextDay);
                        if (nextOpenDay) {
                            end.add(1, 'day'); // Add one day to the end time
                        }
                    }
                    const durationMinutes = moment.duration(end.diff(start)).asMinutes();
                    const hoursDecimal = durationMinutes / 60;
                    return acc + hoursDecimal;
                }
                return acc;
            }, 0);

            if (newTotalHours >= overlappingHours) {
                const overlap = newTotalHours - overlappingHours;
                return Number(overlap.toFixed(2));
            }
            return Number(newTotalHours.toFixed(2));
        }
        return Number(newTotalHours.toFixed(2));
    };

    useEffect(() => {
        const newTotalHours = calculateTotalHour(visitDates);
        onChangetotalHours(newTotalHours);
        setCalculateTotalHours(newTotalHours);
    }, [visitDates]);

    const handleIsSameShiftChange = () => {
        setIsSameShift(!isSameShift);
        if (!isSameShift) {
            const todayDate = new Date();
            const dates = visitDates.filter((date) => {
                const [month, day, year] = date.date.split('-');
                const slotDate = `${year}-${month}-${day}`;
                const dateTime = new Date(`${slotDate}T${selectedTimeSlot[0]?.startTime}`);
                const isTimeInThePast = dateTime > todayDate;
                return isTimeInThePast && !date.saved;
            }).map((date) => ({ value: date.date, label: date.date }));
            const selectedDate = visitDates.filter((date) => !date.saved).map((date) => ({ value: date.date, label: date.date }));
            setSelectedDates([]);
            const values = {
                shift: selectedShift[0]?.label,
                shiftType: selectedShift[0]?.value,
                timeSlot: selectedTimeSlot[0]?.value,
                startTime: selectedTimeSlot[0]?.startTime,
                endTime: selectedTimeSlot[0]?.endTime,
                dates: dates.map((date) => date.value),
                selectedDates: selectedDate.map((date) => date.value),
                isTimeCritical,
                timeCriticalReason,
                customShiftStartTime: customShiftStartTime?.format('HH:mm') || '',
                customShiftEndTime: customShiftEndTime?.format('HH:mm') || '',
                customTimeSlotStartTime: customTimeSlotStartTime?.format('HH:mm') || '',
            };
            handleSaveShift(values);
        }
        setIsTimeCritical(false);
    };

    const handleShiftChange = (shift: DropdownOption[]) => {
        const customShift = shift[0]?.label === 'Custom';
        setIsCustomShift(customShift);

        setSelectedTimeSlot([]);
        setSelectedShift(shift);
    };
    const handleTimeSlotChange = (timeSlot: DropdownOption[]) => {
        setSelectedTimeSlot(timeSlot);
        if (isSameShift && timeSlot[0]?.label !== 'Custom' && !isEmpty(timeSlot)) {
            const todayDate = new Date();
            const dates = visitDates.filter((date) => {
                const [month, day, year] = date.date.split('-');
                const slotDate = `${year}-${month}-${day}`;
                const dateTime = new Date(`${slotDate}T${timeSlot[0]?.startTime}`);
                const isTimeInThePast = dateTime > todayDate;
                return isTimeInThePast && !date.saved;
            }).map((date) => ({ value: date.date, label: date.date }));
            const selectedDate = visitDates.filter((date) => !date.saved).map((date) => ({ value: date.date, label: date.date }));

            setSelectedDates([]);
            const values = {
                shift: selectedShift[0]?.label,
                shiftType: selectedShift[0]?.value,
                timeSlot: timeSlot[0]?.value,
                startTime: timeSlot[0]?.startTime,
                endTime: timeSlot[0]?.endTime,
                dates: dates.map((date) => date.value),
                selectedDates: selectedDate.map((date) => date.value),
                isTimeCritical,
                timeCriticalReason,
                customShiftStartTime: customShiftStartTime?.format('HH:mm') || '',
                customShiftEndTime: customShiftEndTime?.format('HH:mm') || '',
                customTimeSlotStartTime: customTimeSlotStartTime?.format('HH:mm') || '',
            };
            handleSaveShift(values);
        }
    };

    const handleSelectedDatesChange = (values: DropdownOption[]) => {
        setSelectedDates(values);
    };

    const handleCustomShiftStartTimeChange = (time: Dayjs | null) => {
        setCustomShiftStartTime(time);
        // Automatically set the end time to 12 hours after the start time
        const endTime = time ? time.add(12, 'hour') : null;
        setCustomShiftEndTime(endTime);
    };

    const handleCustomShiftEndTimeChange = (time: Dayjs | null) => {
        setCustomShiftEndTime(time);
    };

    const handleCustomTimeSlotStartTimeChange = (time: Dayjs | null) => {
        setCustomTimeSlotStartTime(time);
    };

    const handleTimeCriticalReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTimeCriticalReason(e.target.value);
    };

    const handleSaveShiftClick = () => {
        const todayDate = new Date();
        const dates = selectedDates.filter((date) => {
            const [month, day, year] = date.value.split('-');
            const slotDate = `${year}-${month}-${day}`;
            const time = selectedTimeSlot[0]?.startTime || (selectedShift[0]?.value === '30' ? customShiftStartTime?.format('HH:mm') : customTimeSlotStartTime?.format('HH:mm'));
            const dateTime = new Date(`${slotDate}T${time}`);
            const isTimeInThePast = dateTime > todayDate;
            return isTimeInThePast;
        }).map((date) => ({ value: date.value, label: date.label }));

        const foundDates = visitDates.filter((date) => dates.map((selectedDate) => selectedDate.value).includes(date.date));
        const values = {
            id: foundDates[0] ? foundDates[0].id : null,
            shift: selectedShift[0]?.label,
            shiftType: selectedShift[0]?.value,
            timeSlot: selectedTimeSlot[0]?.value,
            startTime: selectedTimeSlot[0]?.startTime,
            endTime: selectedTimeSlot[0]?.endTime,
            dates: dates.map((date) => date.value),
            selectedDates: selectedDates.map((date) => date.value),
            isTimeCritical,
            timeCriticalReason,
            customShiftStartTime: customShiftStartTime?.format('HH:mm') || '',
            customShiftEndTime: customShiftEndTime?.format('HH:mm') || '',
            customTimeSlotStartTime: customTimeSlotStartTime?.format('HH:mm') || '',
        };
        handleSaveShift(values);
        setSelectedDates([]);
    };

    const handleRemoveTime = (date: string) => {
        setSelectedDates([]);
        setIsSameShift(false);
        handleRemoveShift(date);
    };

    const handleEditTime = (date: string) => {
        const foundDate = visitDates.find((visitDate) => visitDate.date === date);
        const foundShift = shiftOptions.find((shiftOption) => shiftOption.name === foundDate?.shiftName);
        const foundTimeSlot = shiftOptions
            .find((shiftOption) => shiftOption.name === foundDate?.shiftName)
            ?.timeSlots.find((timeSlot) => {
                const timeSlotParts = timeSlot.startTime.split(':').slice(0, 2).join(':');
                return timeSlotParts === foundDate?.startTime;
            });

        if (foundDate && foundShift) {
            const selectedTime = foundShift?.name === '24/7' && !foundTimeSlot ? [{ label: 'Custom', value: 'Custom', startTime: '', endTime: '' }]
                : [{ label: foundTimeSlot?.label || '', value: foundTimeSlot?.label || '', startTime: foundTimeSlot?.startTime || '', endTime: foundTimeSlot?.endTime || '' }];
            setSelectedShift([{ label: foundDate.shiftName || '', value: foundDate.shiftType?.toLocaleString() || '' }]);
            setSelectedTimeSlot(selectedTime);
            if (foundShift?.name === '24/7' && !foundTimeSlot) {
                setCustomTimeSlotStartTime(dayjs(`${foundDate.date} ${foundDate.startTime}`));
            }
            if (foundShift?.name === 'Custom' && !foundTimeSlot) {
                setCustomShiftStartTime(dayjs(`${foundDate.date} ${foundDate.startTime}`));
                const endTime = dayjs(`${foundDate.date} ${foundDate.startTime}`).add(12, 'hour');
                setCustomShiftEndTime(endTime);
                setSelectedShift([{ label: foundDate.shiftName || '', value: foundDate.shiftType?.toLocaleString() || '' }]);
            }
        }
        if (foundDate) {
            if (foundDate.blockDate) {
                setSelectedDates([{ value: foundDate.blockDate, label: moment(foundDate.blockDate).format('dddd Do MMMM') }]);
            } else {
                setSelectedDates([{ value: foundDate.date, label: moment(foundDate.date).format('dddd Do MMMM') }]);
            }
        }
        setIsSameShift(false);
        handleEditShift(date);
    };
    const shiftDropdownOptions = shiftOptions.map((option) => ({ label: option.name, value: option.value }));
    const foundShift = shiftOptions.find((shiftOption) => shiftOption.name === selectedShift[0]?.label);
    const timeSlotOptions = foundShift?.timeSlots.map((timeSlot) => ({ label: timeSlot.label, value: timeSlot.label, startTime: timeSlot.startTime, endTime: timeSlot.endTime }));
    if (selectedShift[0]?.label === '24/7') {
        timeSlotOptions?.push({ label: 'Custom', value: 'Custom', startTime: '', endTime: '' });
    }
    const unsavedDates = visitDates.filter((date) => !date.saved);
    const selectDatesOptions = unsavedDates.map((date) => {
        const formatted = moment(date.date).format('dddd Do MMMM');
        return { value: date.date, label: formatted };
    });
    const savedDates = visitDates.filter((date) => date.saved);
    const shiftNames = savedDates.map((date) => date.shiftName);
    const uniqueNames = [...new Set(shiftNames)];
    const getSavedDates = () => {
        const savedDateSections = uniqueNames.map((shiftName, index) => (
            <SavedShift
                shiftName={shiftName || ''}
                onEdit={(date) => handleEditTime(date)}
                onRemove={(date) => handleRemoveTime(date)}
                savedDates={savedDates.filter((date) => date.shiftName === shiftName)}
                // eslint-disable-next-line react/no-array-index-key
                key={`${shiftName}-${index}`} />
        ));
        return savedDateSections;
    };

    const getAMorPM = () => {
        if (customShiftStartTime && customShiftStartTime.hour() !== 0) {
            const hour = customShiftStartTime.hour();
            return hour < 12 ? 'AM' : 'PM';
        }
        if (customTimeSlotStartTime && customTimeSlotStartTime.hour() !== 0) {
            const hour = customTimeSlotStartTime.hour();
            return hour < 12 ? 'AM' : 'PM';
        }
        return 'AM';
    };

    return (
        <FormSection title="Select Times">
            {savedDates.length > 0 && <div className="saved-dates-container">{getSavedDates()}</div>}
            <div className="total-hour">
                <div className="title-hour">Total Hours Billed:</div>
                <div>{calculateTotalHours}</div>
            </div>
            <div className="select-times-container">
                {(visitDates.length > 1)
                && (!isCustomShift && selectedTimeSlot[0]?.label !== 'Custom')
                && (
                    <div className="checkbox-header">
                        <Checkbox checked={isSameShift} onClick={handleIsSameShiftChange} />
                        <span className="checkbox-label">Request the same shift for all selected dates</span>
                    </div>
                )}
                <div className="content">
                    <div className="heading-wrapper">
                        <h3 className="heading">Choose Shift</h3>
                    </div>
                    <div className="shift-dropdown">
                        <Dropdown
                            options={shiftDropdownOptions}
                            values={selectedShift}
                            onSelect={handleShiftChange} />
                    </div>
                    {isCustomShift ? (
                        <div className="custom-shift-container">
                            <WarningBox>The maximum shift is 12 hours, therefore the Shift End time will automatically be set 12 hours from the time you set for the shift to begin.</WarningBox>
                            <div className="times-wrapper">
                                <div>
                                    <h5 className="label">Shift Start</h5>
                                    <div className="time-picker-wrapper">
                                        <TimePicker
                                            className="time-input"
                                            format="h:mm"
                                            suffixIcon={null}
                                            use12Hours
                                            size="large"
                                            allowClear={false}
                                            // Below props are desired but needConfirm is not a prop of TimePicker despite being in API docs
                                            // open={false}
                                            // needConfirm={false}
                                            value={customShiftStartTime}
                                            onChange={handleCustomShiftStartTimeChange} />
                                        <div className="am-pm-toggle">{getAMorPM()}</div>
                                    </div>
                                </div>
                                <div>
                                    <h5 className="label">Shift End</h5>
                                    <div className="time-picker-wrapper">
                                        <TimePicker
                                            className={enabled24_7 ? 'time-input' : 'time-input-disabled'}
                                            format="h:mm"
                                            suffixIcon={null}
                                            disabled={!enabled24_7}
                                            use12Hours
                                            size="large"
                                            allowClear={false}
                                            // Below props are desired but needConfirm is not a prop of TimePicker despite being in API docs
                                            // open={false}
                                            // needConfirm={false}
                                            value={customShiftEndTime}
                                            onChange={handleCustomShiftEndTimeChange} />
                                        <div className={enabled24_7 ? 'am-pm-toggle' : 'am-pm-toggle-disabled'}>{customShiftEndTime && customShiftEndTime.hour() < 12 ? 'AM' : 'PM'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="heading-wrapper">
                                <h3 className="heading">Time Slots</h3>
                                <Tooltip title="The minimum time slot available for booking is 8 hours, so please select the window which you wil be visiting in.">
                                    <div className="warning-icon-wrapper">
                                        <WarningIcon />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="shift-dropdown">
                                <Dropdown
                                    options={timeSlotOptions || []}
                                    values={selectedTimeSlot}
                                    onSelect={handleTimeSlotChange} />
                            </div>
                        </>
                    )}
                    {selectedTimeSlot[0]?.label === 'Custom' && (
                        <div className="custom-time-slot-container">
                            <WarningBox>Please select the time you would like the 24/7 shift to start on the first day. All days after this will be default rotating 7AM - 7PM and 7PM - 7AM shifts.</WarningBox>
                            <div className="times-wrapper">
                                <div>
                                    <h5 className="label">Shift Start</h5>
                                    <div className="time-picker-wrapper">
                                        <TimePicker
                                            className="time-input"
                                            format="h:mm"
                                            suffixIcon={null}
                                            use12Hours
                                            size="large"
                                            allowClear={false}
                                            // Below props are desired but needConfirm is not a prop of TimePicker despite being in API docs
                                            // open={false}
                                            // needConfirm={false}
                                            value={customTimeSlotStartTime}
                                            onChange={handleCustomTimeSlotStartTimeChange} />
                                        <div className="am-pm-toggle">{getAMorPM()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isSameShift && (
                        <>
                            <h3 className="heading">Select Dates for Chosen Shift</h3>
                            <div className="shift-dropdown">
                                <CheckboxDropdown
                                    options={selectDatesOptions}
                                    values={selectedDates}
                                    onSelect={handleSelectedDatesChange} />
                            </div>
                        </>
                    )}
                    <div className="time-critical-container">
                        {/* <div className="check-label-wrapper">
                            <Checkbox checked={isTimeCritical} onClick={handleIsTimeCriticalChange} />
                            <span className="label">This booking is time critical. Please only tick if it is urgent</span>
                        </div> */}
                        {isTimeCritical && (
                            <div className="message-container">
                                <div className="time-critical-content">
                                    <h5 className="heading">Business Critical Reasoning</h5>
                                    <textarea className="message-area" placeholder="Please provide a reason for the time critical request" onChange={handleTimeCriticalReasonChange} />
                                </div>
                            </div>
                        )}
                    </div>
                    {!isSameShift && (
                        <div className="button-wrapper">
                            <Button className="save-button" onClick={handleSaveShiftClick}>Save Shift</Button>
                            {/* <Button className="add-button">+ Add Another Shift</Button> */}
                        </div>
                    )}
                </div>
            </div>
        </FormSection>
    );
};

export default ShiftSelector;
