import React, { useEffect, useState } from 'react';
import RequestFormLayout from 'components/layout/RequestFormLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { loadVisitRequestForm, updateVisitRequestForm, uploadAttachment } from 'core/visitRequestForm/visitRequestFormApi';
import WorkTypeSelector from 'components/Form/WorkTypeSelector';
import WorkDetails from 'components/Form/WorkDetails';
import { CheckboxData } from 'components/Form/NestedCheckboxSearch/types';
import type { UploadFile } from 'antd/lib/upload/interface';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';

const WorkTypeSelectionForm: React.FC = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { state: navState } = useLocation();
    const { stepData, visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const [workDetails, setWorkDetails] = useState<CheckboxData[]>([]);
    const [workReference, setWorkReference] = useState<string>('');
    const [descriptionOfWorks, setDescriptionOfWorks] = useState<string>('');
    const [comments, setComments] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const amending = navState?.amending;

    const handleBackClick = (navigationState: Record<string, unknown>) => {
        navigation(`/visitor-request/${id}/step/4`, { state: navigationState });
    };

    const getSelectedWorkTypes = () => {
        const selectedParent = workDetails.find((workType) => workType.checked);
        let selectedChild;
        if (selectedParent?.children) {
            selectedChild = selectedParent.children.find((child) => child.checked);
        }
        return [{ id: selectedChild?.id }, { id: selectedParent?.id, siteWorkTypeId: selectedParent?.siteWorkTypeId }];
    };

    const handleNextClick = (navigationState: Record<string, unknown>) => {
        const selectedWorkTypes = getSelectedWorkTypes();
        let error = { message: '', description: '' };

        if (selectedWorkTypes.some(workType => workType.id === undefined)) {
            error = {
                message: 'Work Type Not Selected',
                description: 'Please select a parent and child work type to proceed',
            };
        } else if (descriptionOfWorks === '') {
            error = {
                message: 'Description of Works is Required',
                description: 'Please provide a brief description of the works you will be completing',
            };
        } else if (workReference === '') {
            error = {
                message: 'Work Reference is Required',
                description: 'Please provide a reference for the work you will be completing',
            };
        }

        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return;
        }

        const collatedWorkDetails = {
            description: descriptionOfWorks,
            workReference,
            comments,
        };

        if (id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 5,
                data: {
                    workTypes: selectedWorkTypes,
                    workDetails: collatedWorkDetails,
                    amending,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation(`/visitor-request/${id}/step/6`, { state: navigationState });
                }
            });
        }
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 5,
                amending,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit requst to proceed',
            });
        }
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    useEffect(() => {
        if (descriptionOfWorks === '' && visitRequest.workDetails?.description) {
            setDescriptionOfWorks(visitRequest.workDetails?.description || '');
        }
        if (workReference === '' && visitRequest.workDetails?.workReference) {
            setWorkReference(visitRequest.workDetails?.workReference || '');
        }
        if (comments === '' && visitRequest.additionalComments) {
            setComments(visitRequest.additionalComments || '');
        }
    }, [visitRequest.workDetails]);

    useEffect(() => {
        if (stepData.workTypes) {
            const initialWorkTypes = stepData.workTypes.map(workType => ({
                ...workType,
                value: workType.id.toString(),
                checked: false,
                children: workType.children.map(siteWorkType => ({
                    ...siteWorkType,
                    value: siteWorkType.id.toString(),
                    checked: false,
                })),
            }));
            setWorkDetails(initialWorkTypes);

            if (visitRequest.workTypes) {
                const updatedWorkTypes = stepData.workTypes.map(workType => ({
                    ...workType,
                    value: workType.id.toString(),
                    checked: visitRequest.workTypes?.some((work) => work.workTypeId === workType.id) || false,
                    children: workType.children.map(siteWorkType => ({
                        ...siteWorkType,
                        value: siteWorkType.id.toString(),
                        checked: visitRequest.workTypes?.some((work) => work.workTypeId === siteWorkType.id) || false,
                    })),
                }));

                updatedWorkTypes.forEach((workType, index) => {
                    if (workType.children.length > 0) {
                        const childrenCount = workType.children.filter(child => child.checked).length;
                        if (childrenCount === workType.children.length) {
                            const updatedWorkType = { ...workType };
                            updatedWorkType.checked = true;
                            updatedWorkTypes[index] = updatedWorkType;
                        }
                    }
                });
                setWorkDetails(updatedWorkTypes);
            }
        }
    }, [stepData.workTypes]);

    const handleWorkTypeSelectorChange = (data: CheckboxData[]) => {
        setWorkDetails(data);
    };

    const handleAddAttachment = (file: UploadFile) => {
        if (id !== undefined) {
            dispatch(uploadAttachment({ id, attachment: file }));
        }
    };

    return (
        <RequestFormLayout
            currentStep={5}
            isNextEnabled
            isBackEnabled
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}>
            <>
                <WorkTypeSelector workTypeData={workDetails} handleChange={handleWorkTypeSelectorChange} />
                {workDetails.length > 0 ? (
                    <WorkDetails
                        descriptionOfWorks={descriptionOfWorks}
                        workReference={workReference}
                        comments={comments}
                        onDescriptionOfWorksChange={(value) => setDescriptionOfWorks(value)}
                        onWorkReferenceChange={(value) => setWorkReference(value)}
                        onFileUploadChange={handleAddAttachment}
                        onCommentsChange={(value) => setComments(value)} />
                ) : null}
            </>
        </RequestFormLayout>
    );
};

export default WorkTypeSelectionForm;
