import RequestFormLayout from 'components/layout/RequestFormLayout';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import ManuallyAddVisitor from 'components/Form/ManuallyAddVisitor';
import { loadVisitRequestForm, updateVisitRequestForm, uploadVisitorCsv } from 'core/visitRequestForm/visitRequestFormApi';
import CSVUploader from 'components/Form/CSVUploader/CSVUploader';
import SearchVisitors from 'components/Form/SearchVisitors';
import { ClickListData } from 'components/Form/ListSearch/types';
import VisitorsAdded from 'components/Form/VisitorsAdded';
import type { AddVisitorValues } from 'components/Form/ManuallyAddVisitor/types';
import { isEmpty } from 'underscore';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import { updateVisitors } from 'core/visitRequestForm/visitRequestFormSlice';
import type { AddedVisitor } from './types';

const VisitorSelectionForm: FC = () => {
    const navigation = useNavigate();
    const { state: navState } = useLocation();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { stepData, visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const [addedVisitors, setAddedVisitors] = useState<AddedVisitor[]>([]);
    const [showAddManually, setShowAddManually] = useState<boolean>(false);
    const amending = navState?.amending;

    const handleBackClick = (navigationState: Record<string, unknown>) => {
        navigation(`/visitor-request/${id}/step/2`, { state: navigationState });
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 3,
                amending,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit request to proceed',
            });
        }
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    useEffect(() => {
        if (visitRequest.visitors) {
            setAddedVisitors(visitRequest.visitors);
        }
    }, [visitRequest.visitors]);

    let visitorsSearchList: ClickListData[] = [];

    if (stepData.visitors !== undefined) {
        visitorsSearchList = stepData.visitors.map((visitor) => ({
            value: visitor.id,
            leftString: visitor.name,
            rightString: visitor.email,
        })).sort((a, b) => a.leftString.toLowerCase().localeCompare(b.leftString.toLowerCase()));
    }

    const handleNextClick = (navigationState: Record<string, unknown>) => {
        let error = { message: '', description: '' };
        if (isEmpty(addedVisitors)) {
            error = {
                message: 'Visitor Not Selected',
                description: 'Please select a visitor to proceed',
            };
        }

        if (error.message !== '' && error.description !== '') {
            notification.error(error);
            return;
        }

        if (!isEmpty(addedVisitors) && id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 3,
                data: {
                    visitors: addedVisitors,
                    amending,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation(`/visitor-request/${id}/step/4`, { state: navigationState });
                }
            });
        }
    };

    const handleSearchVisitorSelect = (value: string | number) => {
        const foundVisitor = stepData.visitors?.find((visitor) => visitor.id === value);
        if (foundVisitor) {
            setAddedVisitors((prev) => {
                if (!prev.some(visitor => visitor.visitorId === foundVisitor.visitorId)) {
                    return [...prev,
                        {
                            id: foundVisitor.id,
                            name: foundVisitor.name,
                            email: foundVisitor.email,
                            visitorId: foundVisitor.id,
                            companyName: foundVisitor.companyName || '',
                            phoneNumber: foundVisitor.phoneNumber || '',
                        },
                    ];
                }
                return prev;
            });
        }
    };

    const handleRemoveVisitor = (visitor: AddedVisitor) => {
        setAddedVisitors((prev) => prev.filter((v) => v.id !== visitor.id));
        const newVisitors = addedVisitors.filter((v) => v.id !== visitor.id);
        dispatch(updateVisitors(newVisitors));
    };

    const handleEnterManuallyClick = () => {
        setShowAddManually(true);
    };

    const handleAddVisitorManually = (values: AddVisitorValues) => {
        const newVisitor: AddedVisitor = {
            name: values.fullName,
            email: values.email,
            companyName: values.companyName,
            phoneNumber: values.phoneNumber,
            isTemporary: !values.saveVisitor || false,
        };
        setAddedVisitors((prev) => [...prev, newVisitor]);
        dispatch(updateVisitors([...addedVisitors, newVisitor]));
        setShowAddManually(false);
    };

    const handleUploadCsv = (file: FormData) => {
        const copiedFile = file;

        copiedFile.append('amending', amending ? 'true' : 'false');
        if (id !== undefined) {
            dispatch(uploadVisitorCsv({
                id,
                data: copiedFile,
            }));
        }
    };

    const addedVisitorsIds: number[] = [];
    if (addedVisitors.length > 0) {
        addedVisitors.forEach((visitor) => {
            if (visitor.visitorId) {
                addedVisitorsIds.push(visitor.visitorId);
            }
        });
    }

    return (
        <RequestFormLayout
            currentStep={3}
            isNextEnabled
            isBackEnabled
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}>
            <>
                <CSVUploader onUpload={handleUploadCsv} />
                <SearchVisitors
                    savedVisitors={visitorsSearchList}
                    onSelect={handleSearchVisitorSelect}
                    onClickEnterManually={handleEnterManuallyClick}
                    selectedVisitors={addedVisitorsIds} />
                {showAddManually && <ManuallyAddVisitor addVisitorSubmit={handleAddVisitorManually} />}
                {addedVisitors.length > 0 && (
                    <VisitorsAdded
                        addedVisitors={addedVisitors}
                        removeVisitor={handleRemoveVisitor} />
                )}
            </>
        </RequestFormLayout>
    );
};

export default VisitorSelectionForm;
