import { DateObject } from 'react-multi-date-picker';
import moment from 'moment';

export const convertDateStringToRanges = (dateStrings: string[]) => {
    if (dateStrings.length > 0) {

        const sortedDates = dateStrings.sort((date1, date2) => (moment(date1, 'MM-DD-YYYY').isAfter(moment(date2, 'MM-DD-YYYY')) ? 1 : -1));

        const ranges = [];
        let start = new Date(moment(sortedDates[0], 'MM-DD-YYYY').format('YYYY-MM-DD'));
        let end = new Date(moment(sortedDates[0], 'MM-DD-YYYY').format('YYYY-MM-DD'));

        for (let i = 1; i < sortedDates.length; i++) {
            end = new Date(moment(sortedDates[i], 'MM-DD-YYYY').format('YYYY-MM-DD'));

            if (moment(sortedDates[i], 'MM-DD-YYYY').diff(moment(sortedDates[i - 1], 'MM-DD-YYYY'), 'days') > 1) {
                ranges.push([start, new Date(moment(sortedDates[i - 1], 'MM-DD-YYYY').format('YYYY-MM-DD'))]);
                start = end;
            }
        }
        ranges.push([start, end]);
        return ranges;
    }
    return [];
};

export const convertRangesToDateString = (dateArray: DateObject[]) => {
    const dates: string[] = [];
    dateArray.forEach((range) => {
        // @ts-expect-error type error in npm package for date ranges
        const start = new Date(range[0]);
        // @ts-expect-error type error in npm package for date ranges
        const end = new Date(range[1]);

        dates.push(moment(start).format('MM-DD-YYYY'));

        // If the range is more than a day, add the intervening dates
        const dayAfterStart = new Date(+start);
        dayAfterStart.setDate(dayAfterStart.getDate() + 1);

        while (dayAfterStart.getTime() < end.getTime()) {
            dates.push(moment(dayAfterStart).format('MM-DD-YYYY'));
            dayAfterStart.setDate(dayAfterStart.getDate() + 1);
        }

        // If start and end dates are not the same, add end date
        if (start.getTime() !== end.getTime()) {
            dates.push(moment(end).format('MM-DD-YYYY'));
        }
    });
    // Sort the dates array
    dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return dates;
};

export const categoriseDatesByMonth = (dates: string[]) => {
    const dateObjects = dates.map(dateString => new Date(dateString));
    const monthYears = dateObjects.map(date => moment(date).format('MMMM YYYY'));
    const uniqueMonths = Array.from(new Set(monthYears));
    const categorisedDates = uniqueMonths.map(monthYear => {
        const datesInMonth = dateObjects.filter(date => moment(date).format('MMMM YYYY') === monthYear);
        return {
            monthYear,
            dates: datesInMonth,
        };
    });
    return categorisedDates;
};
