/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { redirect } from 'react-router-dom';
import { StepData, Visitor, VisitorRequest, VisitRequestFormState } from './types';
import { createVisitRequest, loadVisitRequestForm, updateVisitRequestForm, changeSelectedDate, uploadAttachment, deleteAttachment, amendActionRequest, uploadVisitorCsv } from './visitRequestFormApi';

const emptyVisitRequestState: VisitorRequest = {
    encryptedId: null,
    id: null,
    site: null,
    requester: null,
    approver: null,
    costApproverByPass: false,
    attachments: [],
    status: '',
    currentStep: 0,
    visitors: null,
    dates: [],
    totalHours: 0,
    siteAccess: [],
    additionalComments: '',
    workTypeName: null,
    workTypes: null,
    workDetails: {
        description: null,
        workReference: null,
    },
    declaration: {
        signature: null,
        agreed: false,
    },
};

const initialState = {
    error: null,
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isAmending: false,
    isUploading: false,
    status: 0,
    visitRequest: emptyVisitRequestState,
    stepData: {
        sites: [],
        openDays: [],
        approvedVisits: [],
        totalHours: 0,
        enabled24_7: false,
    },
    applyToAllVisitors: true,
    lastRequestAmended: null,
} as VisitRequestFormState;

export const visitRequestFormSlice = createSlice({
    name: 'visitRequestForm',
    initialState,
    reducers: {
        setApplyToAllVisitors: (state, action) => {
            const { payload } = action;
            state.applyToAllVisitors = payload;
        },
        updateVisitors: (state, action) => {
            const { payload } = action;
            state.visitRequest.visitors = payload;
        },
        setLastRequestAmended: (state, action) => {
            const { payload } = action;
            state.lastRequestAmended = payload;
        },
    },
    extraReducers: (builder) => {
        // Create a visit request
        builder
            .addCase(createVisitRequest.pending, (state: VisitRequestFormState) => {
                state.isCreating = true;
                state.error = null;
            });
        builder
            .addCase(createVisitRequest.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ visitRequest: VisitorRequest, stepData: StepData }>,
            ) => {
                state.isCreating = false;
                state.visitRequest = action.payload.visitRequest;
                state.stepData = action.payload.stepData;
                state.status = 200;

                if (action.payload.visitRequest.id) {
                    notification.success({
                        message: 'Success',
                        description: 'Visitor request created successfully',
                    });
                    redirect(`/visitor-request/${action.payload.visitRequest.id}/step/1`);
                }
            });
        builder
            .addCase(createVisitRequest.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isCreating = false;
                state.error = action;
                state.visitRequest = emptyVisitRequestState;
            });

        // Load visit request form data
        builder
            .addCase(loadVisitRequestForm.pending, (state: VisitRequestFormState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(loadVisitRequestForm.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ visitRequest: VisitorRequest, stepData: StepData }>, // here
            ) => {
                state.isFetching = false;
                state.visitRequest = action.payload.visitRequest;
                state.stepData = action.payload.stepData;
                state.status = 200;
            });
        builder
            .addCase(loadVisitRequestForm.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
                state.visitRequest = emptyVisitRequestState;
            });

        builder
            .addCase(updateVisitRequestForm.pending, (state: VisitRequestFormState) => {
                state.isUpdating = true;
                state.error = null;
            });
        builder
            .addCase(updateVisitRequestForm.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ visitRequest: VisitorRequest, stepData: StepData }>,
            ) => {
                state.isUpdating = false;
                state.visitRequest = action.payload.visitRequest;
                state.stepData = action.payload.stepData;
                state.status = 200;
            });
        builder
            .addCase(updateVisitRequestForm.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isUpdating = false;
                state.error = action;
            });

        // Change selected date:

        builder
            .addCase(changeSelectedDate.pending, (state: VisitRequestFormState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(changeSelectedDate.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ message: string, visitRequest: VisitorRequest }>,
            ) => {
                state.isFetching = false;
                state.visitRequest = action.payload.visitRequest;
                state.status = 200;
            });
        builder
            .addCase(changeSelectedDate.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
            });

        // Upload attachment:

        builder
            .addCase(uploadAttachment.pending, (state: VisitRequestFormState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(uploadAttachment.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ message: string, visitRequest: VisitorRequest }>,
            ) => {
                state.isFetching = false;
                state.visitRequest = action.payload.visitRequest;
                state.status = 200;
            });
        builder
            .addCase(uploadAttachment.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
            });

        // Delete attachment:

        builder
            .addCase(deleteAttachment.pending, (state: VisitRequestFormState) => {
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(deleteAttachment.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ message: string, visitRequest: VisitorRequest }>,
            ) => {
                state.isFetching = false;
                state.visitRequest = action.payload.visitRequest;
                state.status = 200;
            });
        builder
            .addCase(deleteAttachment.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isFetching = false;
                state.error = action;
            });

        // Amend action:
        builder
            .addCase(amendActionRequest.pending, (state: VisitRequestFormState) => {
                state.isAmending = true;
                state.error = null;
            });
        builder
            .addCase(amendActionRequest.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ message: string, success: boolean }>,
            ) => {
                state.isAmending = false;
                state.lastRequestAmended = null;

                notification.success({
                    message: 'Success',
                    description: action.payload.message,
                });
            });
        builder
            .addCase(amendActionRequest.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isAmending = false;
                state.error = action;
            });

        // Visitor CSV upload:
        builder
            .addCase(uploadVisitorCsv.pending, (state: VisitRequestFormState) => {
                state.isUploading = true;
                state.error = null;
            });
        builder
            .addCase(uploadVisitorCsv.fulfilled, (
                state: VisitRequestFormState,
                action: PayloadAction<{ visitors: Visitor[] }>,
            ) => {
                state.isUploading = false;

                // add visitors to existing visitors
                const newVisitors = [
                    ...state.visitRequest.visitors || [],
                    ...action.payload.visitors,
                ];

                // If id is present, remove duplicates
                const uniqueVisitors = newVisitors.reduce((acc, current) => {
                    const x = acc.find((item) => item.id === current.id);
                    if (!x) {
                        return acc.concat([current]);
                    }
                    return acc;
                }, [] as Visitor[]);

                state.visitRequest.visitors = uniqueVisitors;
                state.status = 200;
            });
        builder
            .addCase(uploadVisitorCsv.rejected, (
                state: VisitRequestFormState,
                action: object,
            ) => {
                state.isUploading = false;
                state.error = action;
            });
    },
});

export const {
    setApplyToAllVisitors,
    updateVisitors,
    setLastRequestAmended,
} = visitRequestFormSlice.actions;

export default visitRequestFormSlice.reducer;
