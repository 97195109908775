import { combineReducers } from 'redux';
import global from './global/globalSlice';
import user from './user/userSlice';
import dashboard from './dashboard/dashboardSlice';
import securityDashboard from './securityDashboard/securityDashboardSlice';
import visitRequestForm from './visitRequestForm/visitRequestFormSlice';
import visitRequestReview from './visitRequestReview/visitRequestReviewSlice';
import notification from './notification/notificationSlice';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    dashboard,
    securityDashboard,
    visitRequestForm,
    visitRequestReview,
    notification,
});
export default appReducers;
