import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@ant-design/icons';
import { logoutUser } from '../../core/user/userApi';
import './logout.scss';
import { AppDispatch, CombinedStates } from '../../core/types';
import { LogoutIcon } from '../Elements/CustomSVGIcon';

const Logout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        isFetching,
    } = useSelector((state:CombinedStates) => state.user);

    const handleLogout = (e:React.MouseEvent) => {
        e.preventDefault();
        dispatch(logoutUser());
    };

    return (
        <Button
            icon={(
                <Icon
                    component={LogoutIcon as React.ForwardRefExoticComponent<unknown>} />
            )}
            onClick={handleLogout}
            type="primary"
            htmlType="submit"
            className="wide"
            style={{ paddingRight: 0 }}
            loading={isFetching}>
            Logout
        </Button>
    );
};

export default Logout;
