import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApiAuth } from 'core/utils/api';
import { notification } from 'antd';
import { handleError } from 'core/utils/helpers';
import { UploadFile } from 'antd/lib/upload/interface';
import { VisitRequestFormResponse, ChangeSelectedDateResponse, AmendActionResponse, VisitRequestVisitorCSVUploadResponse } from './types';
import { setLastRequestAmended } from './visitRequestFormSlice';

export const createVisitRequest = createAsyncThunk(
    'visitRequestForm/create',
    async (_, { rejectWithValue }) => {
        try {
            const response: VisitRequestFormResponse = await fetchApiAuth({
                method: 'POST',
                url: 'visit-request/create',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const loadVisitRequestForm = createAsyncThunk(
    'visitRequestForm/load',
    async (payload: { id: string | number, step: number, amending?: boolean }, { rejectWithValue }) => {
        try {
            const { id, step, amending = false } = payload;
            const response: VisitRequestFormResponse = await fetchApiAuth({
                method: 'GET',
                url: `visit-request/${id}/step/${step}?amending=${amending}`,
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const updateVisitRequestForm = createAsyncThunk(
    'visitRequestForm/update',
    async (payload: { id: string | number, step: number, data: object }, { rejectWithValue }) => {
        const { id, data } = payload;
        try {
            const response: VisitRequestFormResponse = await fetchApiAuth({
                method: 'PUT',
                url: `visit-request/${id}`,
                body: { ...data },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const uploadVisitorCsv = createAsyncThunk(
    'visitRequestForm/uploadCsv',
    async (payload: { id: string | number, data: FormData }, { rejectWithValue }) => {
        const { id, data } = payload;
        try {
            const response: VisitRequestVisitorCSVUploadResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/upload-csv`,
                headers: { 'Content-Type': 'multipart/form-data;' },
                body: data,
            });

            notification.success({
                message: 'Success',
                description: 'The visitors were uploaded successfully.',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const amendActionRequest = createAsyncThunk(
    'visitRequestReview/amend',
    async (payload: { id: string | number, sections: string[], details: string }, { rejectWithValue }) => {
        const { id, sections, details } = payload;

        try {
            const response: AmendActionResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/action`,
                body: {
                    action: 'amend',
                    sections,
                    details,
                },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const cancelAmendmentRequest = createAsyncThunk(
    'visitRequestReview/cancelAmend',
    async (id: string | number, { rejectWithValue, dispatch }) => {
        try {
            const response: { data: { success: boolean, message: string } } = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/action`,
                body: {
                    action: 'cancel-amend',
                },
            });

            notification.success({
                message: 'Success',
                description: 'The visit request amendment has been cancelled.',
            });

            dispatch(setLastRequestAmended(null));

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const changeSelectedDate = createAsyncThunk(
    'visitRequestReview/changeSelectedDate',
    async (payload: { id: number, newDate: string }, { rejectWithValue }) => {
        const { id, newDate } = payload;
        try {
            const response: ChangeSelectedDateResponse = await fetchApiAuth({
                method: 'PUT',
                url: 'visit-request/change-selected-date',
                body: {
                    visitRequestDateId: id,
                    newDate,
                },
            });

            notification.success({
                message: 'Success',
                description: 'The date has been changed.',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const uploadAttachment = createAsyncThunk(
    'visitRequestReview/uploadAttachment',
    async (payload: { id: string, attachment: UploadFile }, { rejectWithValue }) => {
        const { id, attachment } = payload;
        const formData = new FormData();
        try {
            const file = attachment.originFileObj;
            if (file) {
                formData.append('file', file);
            }
            const response = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/upload-attachment`,
                body: formData,
            });

            notification.success({
                message: 'Success',
                description: 'File uploaded successfully.',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const deleteAttachment = createAsyncThunk(
    'visitRequestReview/deleteAttachment',
    async (payload: { id: string, attachmentId: number }, { rejectWithValue }) => {
        const { id, attachmentId } = payload;
        try {
            const response = await fetchApiAuth({
                method: 'DELETE',
                url: `visit-request/${id}/delete-attachment/${attachmentId}`,
            });

            notification.success({
                message: 'Success',
                description: 'File successfully removed.',
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
