import React, { FC, useState } from 'react';
import { DropdownOption } from 'components/Elements/Dropdown/types';
import Dropdown from 'components/Elements/Dropdown';
import './hourlyRateModal.scss';

export interface HourlyRateModalProps {
    onCancel: () => void;
    onExport: (hourlyRate: DropdownOption) => void;
    defaultOption: DropdownOption;
}

const HourlyRateModal: FC<HourlyRateModalProps> = ({ onCancel, onExport, defaultOption }) => {
    const [hourlyRate, setHourlyRate] = useState<DropdownOption>(defaultOption);

    const options = [
        { value: 'Standard Hourly', label: 'Standard Hourly' },
        { value: 'Less Than 72 Hours Hourly', label: 'Less Than 72 Hours Hourly' },
    ];

    const handleDropdownSelect = (values: DropdownOption[]) => {
        const newValue = values[0] || { value: '1', label: 'Option 1' };
        setHourlyRate(newValue);
    };

    const handleExportClick = () => {
        onExport(hourlyRate);
        setHourlyRate(defaultOption);
    };

    const handleCancelClick = () => {
        setHourlyRate(defaultOption);
        onCancel();
    };

    return (
        <div className="hourly-rate-modal-container">
            <h2 className="heading">Confirm Hourly Rate</h2>
            <h5 className="sub-heading">Please select the hourly rate below to generate a cost breakdown of the selected visits.</h5>
            <div className="content-container">
                <h3>Hourly Rate</h3>
                <div className="dropdown-wrapper" style={{ marginLeft: 24, width: 350 }}>
                    <Dropdown options={options} onSelect={handleDropdownSelect} values={[hourlyRate]} />
                </div>
                <div className="button-wrapper">
                    <button onClick={handleExportClick} type="button" className="export-button">Export Data</button>
                    <button onClick={handleCancelClick} type="button" className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default HourlyRateModal;
