import { StatusData } from 'core/visitRequestReview/types';
import { SvgProps } from 'components/Elements/CustomSVGIcon/types';
import { ADMIN_USER, SCORCHSOFT_USER } from 'core/utils/value';
import { AuthUserDataResponse } from 'core/user/types';
import { ApproveIcon, CancelIcon, ClockIcon, EditIcon, PushIcon, QueryIcon, RejectIcon, RestartIcon } from '../../../../components/Elements/CustomSVGIcon/index';

type AvailableApprover = {
    id: number;
    name: string;
};

export interface RequestAction {
    id: number,
    icon: React.ComponentType<SvgProps>,
    label: string,
    action: string,
    disabled: (statusData: StatusData,
        isCostApprover: boolean) => boolean,
    available: (
        user: AuthUserDataResponse,
        requester: AuthUserDataResponse | undefined | null,
        approver: AuthUserDataResponse | undefined | null,
        availableApprovers?: AvailableApprover[] | [] | undefined | null,
        costApprover?: AuthUserDataResponse | undefined | null,
    ) => boolean,
    iconStroke: (condition: boolean) => string,
}

export interface RequestActionPayload {
    id: number,
    Icon: React.ComponentType<SvgProps>,
    label: string,
    action: string,
    disabled: boolean,
    iconStroke: string,
}

export enum Actions {
    COST_APPROVE = 'cost_approve',
    APPROVE = 'approve',
    QUERY = 'query',
    REJECT = 'reject',
    SCHEDULE = 'schedule',
    PUSH = 'push',
    AMEND = 'amend',
    RESUBMIT = 'resubmit',
    CANCEL = 'cancel',
}

const actions: RequestAction[] = [
    {
        id: 1,
        icon: ApproveIcon,
        label: 'Approve',
        action: Actions.APPROVE,
        disabled: (statusData, isCostApprover) => statusData.inActiveActions.approve || isCostApprover,
        available: (
            user,
            _requester,
            approver,
            _availableApprovers,
            costApprover,
        ) => (user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER)
            || (user.email === approver?.email)
            || (_availableApprovers?.find(({ id }) => id === user.id)) !== undefined
            || (user.email === costApprover?.email),
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 2,
        icon: QueryIcon,
        label: 'Query',
        action: Actions.QUERY,
        disabled: (statusData) => statusData.inActiveActions.query,
        available: (
            user,
            _requester,
            approver,
            _availableApprovers,
            costApprover,
        ) => (user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER)
            || (user.email === approver?.email)
            || (_availableApprovers?.find(({ id }) => id === user.id)) !== undefined
            || (user.email === costApprover?.email),
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 3,
        icon: RejectIcon,
        label: 'Reject',
        action: Actions.REJECT,
        disabled: (statusData, isCostApprover) => statusData.inActiveActions.reject || isCostApprover,
        available: (
            user,
            _requester,
            approver,
            _availableApprovers,
            costApprover,
        ) => (user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER)
            || (user.email === approver?.email)
            || (_availableApprovers?.find(({ id }) => id === user.id)) !== undefined
            || (user.email === costApprover?.email),
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 4,
        icon: ClockIcon,
        label: 'Schedule',
        action: Actions.SCHEDULE,
        disabled: (statusData, isCostApprover) => statusData.inActiveActions.schedule || isCostApprover,
        available: (
            user,
            _requester,
            _approver,
            _availableApprovers,
        ) => user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER,
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 5,
        icon: PushIcon,
        label: 'Push to Approver',
        action: Actions.PUSH,
        disabled: (statusData, isCostApprover) => statusData.inActiveActions.push || isCostApprover,
        available: (
            user,
            _requester,
            _approver,
            _availableApprovers,
        ) => user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER,
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 6,
        icon: EditIcon,
        label: 'Amend Request',
        action: Actions.AMEND,
        disabled: (statusData) => statusData.inActiveActions.amend,
        available: (
            user,
            requester,
            _approver,
            _availableApprovers,
        ) => user.email === requester?.email,
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 7,
        icon: RestartIcon,
        label: 'Resubmit Request',
        action: Actions.RESUBMIT,
        disabled: (statusData) => statusData.inActiveActions.resubmit,
        available: (
            user,
            requester,
            _approver,
            _availableApprovers,
        ) => user.email === requester?.email,
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
    {
        id: 8,
        icon: CancelIcon,
        label: 'Cancel',
        action: Actions.CANCEL,
        disabled: (statusData) => statusData.inActiveActions.cancel,
        available: (
            user,
            requester,
            _approver,
            _availableApprovers,
        ) => user.email === requester?.email,
        iconStroke: (condition) => (condition ? '#C0D1D8' : ''),
    },
];

interface GetRequestActionsProps {
    statusData: StatusData,
    user: AuthUserDataResponse,
    requester: AuthUserDataResponse | undefined | null,
    approver: AuthUserDataResponse | undefined | null,
    availableApprovers: AvailableApprover[] | [] | undefined | null,
    costApprover: AuthUserDataResponse | undefined | null,
    hasCostApprove: boolean,
}

// Return array of Actions or empty array
export const getRequestActions = ({ statusData, user, requester, approver, availableApprovers, costApprover, hasCostApprove }: GetRequestActionsProps): RequestActionPayload[] => {
    // filter actions based on available condition
    const filteredActions = actions.filter((action) => action.available(user, requester, approver, availableApprovers, costApprover));
    let isCostApprover: boolean;
    if (costApprover === null) {
        isCostApprover = false;
    } else if (user.role.accessLevel === ADMIN_USER || user.role.accessLevel === SCORCHSOFT_USER) {
        isCostApprover = false;
    } else if (!hasCostApprove) {
        isCostApprover = user?.email !== costApprover?.email;
    } else {
        isCostApprover = user?.email === costApprover?.email;
    }

    const composedActions = filteredActions.map((action) => (
        {
            id: action.id,
            Icon: action.icon,
            label: action.label,
            action: action.action,
            disabled: action.disabled(statusData, isCostApprover),
            iconStroke: action.iconStroke(action.disabled(statusData, isCostApprover)),
        }
    ));

    return composedActions.length > 0 ? composedActions.sort((a, b) => a.id - b.id) : [];
};
