import React, { FC, useState } from 'react';
import WarningBox from 'components/Elements/WarningBox';
import Checkbox from 'components/Elements/Checkbox';
import CheckboxSelector from 'components/Elements/CheckboxSelector';
import FormSection from '../FormSection';
import './siteAccessNotifications.scss';

interface SiteAccessNotificationsProps {
   visitors: {
        id: number;
        name: string;
    }[];
    selectedVisitors: number[];
    onSelect: (values: number[]) => void;
}

const SiteAccessNotifications: FC<SiteAccessNotificationsProps> = ({ visitors, selectedVisitors, onSelect }) => {
    const [nofitfyAll, setNotifyAll] = useState(false);
    const checkboxOptions = visitors.sort((a, b) => a.name.localeCompare(b.name)).map((visitor) => ({ label: visitor.name, value: visitor.id.toString() }));

    const handleCheckboxClick = () => {
        setNotifyAll(!nofitfyAll);
        if (nofitfyAll) {
            return onSelect([]);
        }
        return onSelect(visitors.map((visitor) => visitor.id));
    };

    const handleVisitorSelect = (values: string[]) => {
        onSelect(values.map((value) => parseInt(value, 10)));
    };

    return (
        <FormSection title="Notifications">
            <div className="sa-notifications-container">
                <div className="warning-wrapper">
                    <WarningBox>If you tick this box, all engineers listed as a visitor will be notified about the request including the reference and approval statuses throughout the process.</WarningBox>
                </div>
                <div className="checkbox-wrapper">
                    <Checkbox checked={nofitfyAll} onClick={handleCheckboxClick} />
                    <span className="label">Notify all visitors via email of request</span>
                </div>
                {!nofitfyAll && (
                    <div className="select-visitors-container">
                        <h5 className="heading">Select Visitors</h5>
                        <CheckboxSelector
                            options={checkboxOptions}
                            values={selectedVisitors.map((visitor) => visitor.toString())}
                            onChange={handleVisitorSelect} />
                    </div>
                )}
            </div>
        </FormSection>
    );
};

export default SiteAccessNotifications;
