import React, { FC } from 'react';
import './searchVisitors.scss';
import type { ClickListData } from 'components/Form/ListSearch/types';
import FormSection from '../FormSection';
import ListSearch from '../ListSearch';

interface SearchVisitorsProps {
    savedVisitors: ClickListData[];
    onSelect: (value: string | number) => void;
    onClickEnterManually: () => void;
    selectedVisitors: number[];
}

const SearchVisitors: FC<SearchVisitorsProps> = ({ savedVisitors, onSelect, onClickEnterManually, selectedVisitors }) => (
    <div className="search-add-visitor-form-container">
        <FormSection title="Search Saved Visitors">
            <div className="list-search-wrapper">
                <ListSearch
                    listData={savedVisitors}
                    onSelect={onSelect}
                    selectedValues={selectedVisitors}
                    showSelectedValues />
            </div>
            <div className="button-wrapper">
                <button onClick={onClickEnterManually} type="button">Enter Visitor Details Manually</button>
            </div>
        </FormSection>
    </div>

);

export default SearchVisitors;
