import React, { forwardRef, useImperativeHandle } from 'react';
import './actionModal.scss';
import { Button, Modal, Input, notification } from 'antd';

const { TextArea } = Input;

interface ActionProps {
    statusId: number;
    title: string;
    subTitle?: string;
    description?: string;
    confirmButton: string;
    cancelButton: string;
    action: string;
    placeholder?: string;
}
interface ActionModalProps {
    visible: boolean;
    action: ActionProps | null;
    onConfirm: (action:string|undefined, message?: string|undefined) => void;
    onCancel: () => void;
}

export interface ActionModalHandlers {
    clear(): void;
}

const ActionModal: React.ForwardRefRenderFunction<ActionModalHandlers, ActionModalProps> = ({ visible, onConfirm, onCancel, action }, ref) => {
    const [value, setValue] = React.useState<string>('');

    useImperativeHandle(ref, () => ({
        clear: () => setValue(''),
    }));

    const handleSendMessage = () => {
        if (!value && action && action.subTitle) {
            notification.error({
                message: 'Error',
                description: 'Please enter a message',
            });
        } else {
            onConfirm(action?.action, value);
            setValue('');
        }
    };

    return (
        <div className="action-box-container">
            <Modal
                open={visible}
                onCancel={() => onCancel()}
                footer={null}
                width={650}
                title={action?.title}
                wrapClassName="action-modal">
                <p>{action?.description}</p>
                {
                    action?.subTitle && (
                        <div>
                            <div>{action?.subTitle}</div>
                            <TextArea
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder={action?.placeholder}
                                autoSize={{ minRows: 3, maxRows: 5 }} />
                        </div>
                    )
                }
                <div className="action-modal-buttons">
                    <Button type="primary" className="action-confirm-button" onClick={() => handleSendMessage()}>{action?.confirmButton}</Button>
                    <Button type="default" className="action-cancel-button" onClick={() => onCancel()}>{action?.cancelButton}</Button>
                </div>
            </Modal>
        </div>
    );
};

export default forwardRef(ActionModal);
