/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { addNewAccessCard, assignAccessCard, assignKeyReference, checkInVisitors, checkOutVisitors, getAccessCards, markCardReturned, securityDashboardRequest, unreturnedCardsAndKeysRequest, visitorsTodayRequest } from './securityDashboardApi';
import { SecurityDashboardState, SecurityDataProps, SecurityVisitRequest, TemporaryAccessCards, VisitorsDataProps, VisitorUnreturnedCardsAndKeys } from './types';

const emptyRequestState: SecurityVisitRequest = {
    encrypted_id: '',
    id: 0,
    work_description: '',
    visitors_count: 0,
    visitors: [],
    site_address: '',
    status: '',
    dates: [],
};

const emptyTemporaryAccessCards = {
    availableKeys: [],
    inUseKeys: [],
    unreturnedKeys: [],
};

const initialState = {
    error: null,
    isFetching: false,
    securityVisitRequests: [emptyRequestState],
    pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
    },
    visitorsToday: [],
    visitorsTodayPagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
    },
    temporaryAccessCards: emptyTemporaryAccessCards,
    unreturnedCardsAndKeys: [],
} as SecurityDashboardState;

export const securityDashboardSlice = createSlice({
    name: 'securityDashboard',
    initialState,
    reducers: {
        resetPagination: (state: SecurityDashboardState) => {
            state.pagination = {
                currentPage: 1,
                pageSize: 20,
                total: 0,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(securityDashboardRequest.fulfilled, (
                state: SecurityDashboardState,
                action: PayloadAction<SecurityDataProps>,
            ) => {
                state.isFetching = false;
                if (action.payload.visitRequests) {
                    state.securityVisitRequests = action.payload.visitRequests;
                    state.pagination = {
                        currentPage: action.payload.meta.current_page || 1,
                        pageSize: action.payload.meta.per_page || 20,
                        total: action.payload.meta.total || 0,
                    };
                } else {
                    state.securityVisitRequests = [];
                }
            });

        builder
            .addCase(getAccessCards.fulfilled, (
                state: SecurityDashboardState,
                action: PayloadAction<TemporaryAccessCards>,
            ) => {
                state.isFetching = false;
                if (action.payload) {
                    state.temporaryAccessCards = action.payload;
                } else {
                    state.temporaryAccessCards = emptyTemporaryAccessCards;
                }
            });

        builder
            .addCase(addNewAccessCard.fulfilled, (
                state: SecurityDashboardState,
                action: PayloadAction<TemporaryAccessCards>,
            ) => {
                state.isFetching = false;
                if (action.payload) {
                    state.temporaryAccessCards = action.payload;
                } else {
                    state.temporaryAccessCards = emptyTemporaryAccessCards;
                }
            });

        builder
            .addCase(unreturnedCardsAndKeysRequest.fulfilled, (
                state: SecurityDashboardState,
                action: PayloadAction<VisitorUnreturnedCardsAndKeys[]>,
            ) => {
                state.isFetching = false;
                if (action.payload) {
                    state.unreturnedCardsAndKeys = action.payload;
                } else {
                    state.unreturnedCardsAndKeys = [];
                }
            });

        // Fulfilled state for unreturned cards and keys queries
        builder.addMatcher(
            isAnyOf(
                unreturnedCardsAndKeysRequest.fulfilled,
                markCardReturned.fulfilled,
            ),
            (
                state: SecurityDashboardState,
                action: PayloadAction<VisitorUnreturnedCardsAndKeys[]>,
            ) => {
                state.isFetching = false;
                if (action.payload) {
                    state.unreturnedCardsAndKeys = action.payload;
                } else {
                    state.unreturnedCardsAndKeys = [];
                }
            },
        );

        // Fulfilled state for visitorsTodayRequest
        builder.addMatcher(
            isAnyOf(
                visitorsTodayRequest.fulfilled,
                checkInVisitors.fulfilled,
                checkOutVisitors.fulfilled,
                assignAccessCard.fulfilled,
                assignKeyReference.fulfilled,
            ),
            (
                state: SecurityDashboardState,
                action: PayloadAction<VisitorsDataProps>,
            ) => {
                state.isFetching = false;
                if (action.payload.visitorsToday) {
                    state.visitorsToday = action.payload.visitorsToday;
                    state.visitorsTodayPagination = {
                        currentPage: action.payload.meta.current_page || 1,
                        pageSize: action.payload.meta.per_page || 20,
                        total: action.payload.meta.total || 0,
                    };
                } else {
                    state.visitorsToday = [];
                }
            },
        );

        // Pending state for all requests
        builder.addMatcher(
            isAnyOf(
                securityDashboardRequest.pending,
                visitorsTodayRequest.pending,
                checkInVisitors.pending,
                checkOutVisitors.pending,
                getAccessCards.pending,
                addNewAccessCard.pending,
                assignAccessCard.pending,
                assignKeyReference.pending,
                unreturnedCardsAndKeysRequest.pending,
                markCardReturned.pending,
            ),
            (state: SecurityDashboardState) => {
                state.isFetching = true;
                state.error = null;
            },
        );

        // Error state for all requests
        builder.addMatcher(
            isAnyOf(
                securityDashboardRequest.rejected,
                visitorsTodayRequest.rejected,
                checkInVisitors.rejected,
                checkOutVisitors.rejected,
                getAccessCards.rejected,
                addNewAccessCard.rejected,
                assignAccessCard.rejected,
                assignKeyReference.rejected,
                unreturnedCardsAndKeysRequest.rejected,
                markCardReturned.rejected,
            ),
            (state: SecurityDashboardState, action) => {
                state.isFetching = false;
                state.error = action.error;
            },
        );
    },
});

// this is for configureStore
export default securityDashboardSlice.reducer;
export const { resetPagination } = securityDashboardSlice.actions;
