/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Notification, NotificationState } from './types';
import { fetchNotifications, fetchRecentNotifications, fetchUnreadNotifications, markAllNotificationsAsReadServer, markNotificationAsReadServer } from './notificationApi';

const initialState = {
    bulkUpdatedNotifications: [],
    notifications: [] as Notification[],
    cachedNotifications: [] as Notification[],
    isFetching: false,
    error: null,
    unreadCount: 0,
} as NotificationState;

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        markNotificationAsRead: (state: NotificationState, action: PayloadAction<string>) => {
            const notification = state.notifications.find((n) => n.id === action.payload);
            if (notification) {
                state.cachedNotifications = [...state.cachedNotifications, { ...notification }];
            }
            state.notifications = state.notifications.filter((n) => n.id !== action.payload);
            state.unreadCount -= 1;

        },
        undoMarkNotificationAsRead: (state: NotificationState, action: PayloadAction<string>) => {
            const notification = state.cachedNotifications.find((n) => n.id === action.payload);
            if (notification) {
                state.notifications = [{ ...notification }, ...state.notifications];
                notification.readAt = null;
            }
            state.unreadCount += 1;
        },
        markAllNotificationsAsRead: (state: NotificationState) => {
            const { notifications, bulkUpdatedNotifications } = state;
            notifications.forEach((n) => {
                n.readAt = moment().toISOString();
                state.bulkUpdatedNotifications = [...bulkUpdatedNotifications, n.id];
            });

            state.cachedNotifications = [...notifications];
            state.notifications = [];
            state.unreadCount = 0;
        },
        undoMarkAllNotificationsAsRead: (state: NotificationState) => {
            const { bulkUpdatedNotifications, cachedNotifications } = state;
            let count = state.unreadCount;
            cachedNotifications.forEach((n) => {
                n.readAt = null;
                state.bulkUpdatedNotifications = bulkUpdatedNotifications.filter((id) => id !== n.id);
                count += 1;
            });
            state.notifications = [...cachedNotifications];
            state.bulkUpdatedNotifications = [];
            state.cachedNotifications = [];
            state.unreadCount = count;
        },
    },
    extraReducers: (builder) => {
        // fetchNotifications
        builder
            .addCase(fetchNotifications.pending, (state: NotificationState) => {
                state.bulkUpdatedNotifications = [];
                state.isFetching = true;
                state.error = null;
            });
        builder
            .addCase(fetchNotifications.fulfilled, (state: NotificationState, action: PayloadAction<Notification[]>) => {
                state.isFetching = false;
                state.notifications = action.payload;
                state.unreadCount = action.payload.filter((n) => !n.readAt).length;
            });
        builder
            .addCase(fetchNotifications.rejected, (state: NotificationState, action: object) => {
                state.isFetching = false;
                state.error = action;
            });
        builder
            .addCase(fetchUnreadNotifications.pending, (state: NotificationState) => {
                state.isFetching = true;
                state.error = null;
                state.bulkUpdatedNotifications = [];
            });
        builder.addCase(fetchUnreadNotifications.fulfilled, (state: NotificationState, action: PayloadAction<Notification[]>) => {
            state.isFetching = false;
            state.notifications = action.payload;
            state.cachedNotifications = [];
            state.unreadCount = action.payload.filter((n) => !n.readAt).length;
        });
        builder.addCase(fetchUnreadNotifications.rejected, (state: NotificationState, action: object) => {
            state.isFetching = false;
            state.error = action;
        });

        builder
            .addCase(fetchRecentNotifications.fulfilled, (state: NotificationState, action: PayloadAction<Notification[]>) => {
                const { notifications } = state;
                const newNotifications = action.payload.filter((n) => !notifications.find((on) => on.id === n.id));
                state.notifications = [...newNotifications, ...notifications];
                state.unreadCount = state.notifications.filter((n) => !n.readAt).length;
            });

        builder.addMatcher(
            isAnyOf(
                markNotificationAsReadServer.pending,
                markAllNotificationsAsReadServer.pending,
                fetchRecentNotifications.pending,
            ),
            (state: NotificationState) => {
                state.isFetching = true;
                state.error = null;
            },
        );

        builder.addMatcher(
            isAnyOf(
                markNotificationAsReadServer.fulfilled,
                markAllNotificationsAsReadServer.fulfilled,
            ),
            (state: NotificationState) => {
                state.isFetching = false;
            },
        );

        builder.addMatcher(
            isAnyOf(
                markNotificationAsReadServer.rejected,
                markAllNotificationsAsReadServer.rejected,
            ),
            (state: NotificationState, action) => {
                // call reducers to undo the changes
                if (action.meta.arg) {
                    notificationSlice.caseReducers.undoMarkNotificationAsRead(state, {
                        type: 'undoMarkNotificationAsRead',
                        payload: action.meta.arg,
                    });
                } else {
                    notificationSlice.caseReducers.undoMarkAllNotificationsAsRead(state);
                }

                state.isFetching = false;
                state.error = action.error;
            },
        );
    },
});

export const {
    markNotificationAsRead,
    undoMarkNotificationAsRead,
    markAllNotificationsAsRead,
    undoMarkAllNotificationsAsRead,
} = notificationSlice.actions;

export default notificationSlice.reducer;
