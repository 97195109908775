import React from 'react';
import { Row, Col, Layout, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { map } from 'underscore';
import NotificationList from 'components/Notification/NotificationList';
import SubHeader from '../SubHeader';
import { HeaderLogo } from '../../Elements/CustomSVGIcon';
import Menu from '../Menu';
import type { HeaderProps } from './types';
import './header.css';
import Logout from '../../Logout';
import { CombinedStates } from '../../../core/types';
import { ToolbarItem } from '../Main/types';
import { ADMIN_USER, SCORCHSOFT_USER, SECURITY_USER } from '../../../core/utils/value';

const Header = (props:HeaderProps) => {
    const {
        subHeaderTitle,
        subHeaderHasBackLink,
        subHeaderLeftAction,
        subHeaderRightAction,
        subHeaderBackLinkCallback,
        subHeaderBackLink,
        hideSubHeader,
        menu,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
        toolbar,
    } = props;

    const {
        userData,
    } = useSelector((state:CombinedStates) => state.user);

    const navigate = useNavigate();
    const location = useLocation();

    const currentPath = location.pathname;
    const allowedAccessLevels = [SECURITY_USER, SCORCHSOFT_USER, ADMIN_USER];

    console.log('currentPath', currentPath);
    const toolbarItems = toolbar ? map(toolbar, (item:unknown, index) => {
        const i = index;
        const className = currentPath === (item as ToolbarItem).link ? 'active' : '';
        return (
            <Button key={i} type="primary" className={className} onClick={() => navigate((item as ToolbarItem).link)}>
                {(item as ToolbarItem).label}
            </Button>
        );
    }) : null;

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row justify="space-between" align="middle" style={{ height: 'inherit', width: 1320 }}>
                    <Col span={4}>
                        <Row align="middle" style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>
                            <HeaderLogo />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row align="middle">
                            <Col>
                                {allowedAccessLevels.includes(userData?.role?.accessLevel) ? (
                                    <span className="header-custom-links">
                                        {toolbarItems}
                                    </span>
                                ) : null }
                                {menu ? <Menu items={menu} placement={menuPlacement} width={menuWidth} /> : null}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={7}>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    <Col span={5} style={{ textAlign: 'right' }}>
                        {userData?.role?.accessLevel !== SECURITY_USER ? (
                            <NotificationList />) : null }
                        <Logout />
                    </Col>
                </Row>
            </Layout.Header>
            { hideSubHeader ? null : (
                <SubHeader
                    title={subHeaderTitle}
                    hasBackLink={subHeaderHasBackLink}
                    backLinkCallback={subHeaderBackLinkCallback}
                    backLink={subHeaderBackLink}
                    leftAction={subHeaderLeftAction}
                    rightAction={subHeaderRightAction} />
            )}
            {afterHeader}
        </div>
    );
};

Header.defaultProps = {
    logo: '',
    beforeHeader: null,
    afterHeader: null,
    subHeaderTitle: '',
    subHeaderHasBackLink: false,
    subHeaderLeftAction: '',
    subHeaderRightAction: '',
    subHeaderBackLinkCallback: () => console.log('Back'),
    subHeaderBackLink: '#',
    hideSubHeader: false,
};

export default Header;
