import React, { FC, useState } from 'react';
import TextInput from 'components/Elements/TextInput';
import Checkbox from 'components/Elements/Checkbox';
import type { SaveKeyReferenceValues } from 'pages/VisitorsOnSite/types';
import './keyReferenceModal.scss';

interface KeyReferenceModalProps {
    onSave: (values: SaveKeyReferenceValues) => void;
    onCancel: () => void;
    selectedVisitorIds: string[];
}

const KeyReferenceModal: FC<KeyReferenceModalProps> = ({ onSave, onCancel, selectedVisitorIds }) => {
    const [keyReference, setKeyReference] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [applyToAll, setApplyToAll] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string>('');
    const [keyReferenceError, setKeyReferenceError] = useState<string>('');

    const handleKeyReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 40) {
            setKeyReferenceError('You have reached the maximum character limit of 40!');
        } else {
            setKeyReferenceError('');
            setKeyReference(e.target.value);
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length > 140) {
            setDescriptionError('You have reached the maximum character limit of 140!');
        } else {
            setDescriptionError('');
            setDescription(e.target.value);
        }
    };

    const handleApplyToAllClick = () => {
        setApplyToAll(!applyToAll);
    };

    const handleSaveClick = () => {
        if (keyReference === '') {
            setError('Please enter a key reference');
            return;
        }
        onSave({ keyReference, description, applyToAll });
        setError('');
        setKeyReference('');
        setDescription('');
        setApplyToAll(false);
    };

    return (
        <div className="key-reference-modal-container">
            <h1 className="heading">Issue Key</h1>
            <div className="content-wrapper">
                <div className="reference-wrapper">
                    <p className="caption mb-8">Key Reference</p>
                    <TextInput
                        value={keyReference}
                        onChange={handleKeyReferenceChange}
                        maxLength={40} />
                    {keyReferenceError && <p className="error">{keyReferenceError}</p>}
                </div>
                <div className="description-wrapper">
                    <p className="caption mb-8">Description</p>
                    <textarea
                        value={description}
                        onChange={handleDescriptionChange}
                        maxLength={180} />
                    {descriptionError && <p className="error">{descriptionError}</p>}
                </div>

                {selectedVisitorIds.length > 0 ? (
                    <div className="apply-to-all-wrapper">
                        <Checkbox checked={applyToAll} onClick={handleApplyToAllClick} />
                        <span className="label">Apply this Key Reference to all selected visitors</span>
                    </div>
                ) : null}

                <div className="button-wrapper">
                    <button
                        onClick={handleSaveClick}
                        className="save-button"
                        type="button">Save
                    </button>
                    {error && <p className="error">{error}</p>}
                    <button
                        onClick={onCancel}
                        className="cancel-button"
                        type="button">Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default KeyReferenceModal;
