import React, { useMemo, useState } from 'react';
import useNotifications from 'core/notification/useNotifications';
import { Button, Dropdown, Empty, Menu } from 'antd';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CombinedStates } from 'core/types';
import { BellIcon, BellIconActive } from 'components/Elements/CustomSVGIcon';

import './notificationList.css';
import NotificationItem from '../NotificationItem';

const NotificationList: React.FC = () => {
    const navigate = useNavigate();
    const { unreadCount } = useSelector((state: CombinedStates) => state.notification);
    const { notifications, markAsRead, markAllAsRead } = useNotifications();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const icon = unreadCount > 0 ? BellIconActive : BellIcon;

    const renderNotificationList = useMemo(() => {
        if (notifications.length === 0) {
            return (
                <Menu.Item key="empty" style={{ padding: '20px' }}>
                    <Empty description="">
                        <p className="notifications-empty-text">You have no notifications</p>
                    </Empty>
                </Menu.Item>
            );
        }

        return notifications.map((notification, index) => {
            const nextNotification = notifications[index + 1] || { readAt: true };
            const isNextUnread = !nextNotification.readAt;

            return (
                <NotificationItem
                    notification={notification}
                    markAsRead={markAsRead}
                    key={`elem-${notification.id}`}
                    closeDropdown={() => setDropdownVisible(false)}
                    navigation={navigate}
                    isNextUnread={isNextUnread} />
            );
        });
    }, [notifications]);

    const renderDropdown = () => (
        <div>
            <div className="notification-overlay" />
            <Menu
                onClick={(info) => {
                    info.domEvent.stopPropagation();
                }}
                onMouseLeave={() => setDropdownVisible(false)}
                style={{
                    maxHeight: '670px',
                    width: '500px',
                    top: -10,
                    padding: '0px',
                    borderRadius: '4px',
                    overflow: 'scroll',
                }}>
                <div className="notification-header">
                    <p className="notification-header__title">Notifications</p>
                    <Button type="link" className="mark-as-read-button button-link" onClick={markAllAsRead}>Mark all as read</Button>
                </div>
                {renderNotificationList}
            </Menu>
        </div>
    );

    return (
        <Dropdown dropdownRender={renderDropdown} trigger={['click']} open={dropdownVisible} placement="bottomRight">
            <Button
                type="primary"
                onClick={() => setDropdownVisible(!dropdownVisible)}
                icon={(
                    <Icon
                        component={icon as unknown as React.ForwardRefExoticComponent<unknown>} />
                )}>
                Notifications
            </Button>
        </Dropdown>
    );
};

export default NotificationList;
