import React, { FC } from 'react';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon, AltCheckboxFill } from '../CustomSVGIcon';
import './checkbox.scss';

interface CheckboxProps {
    onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
    checked: boolean;
    disabled?: boolean;
    icon?: 'tick' | 'block';
}

const Checkbox: FC<CheckboxProps> = ({ onClick, checked, disabled, icon = 'tick' }) => {

    const getCheckedIcon = () => {
        switch (icon) {
        case 'block':
            return <AltCheckboxFill />;
        default:
            return <CheckboxCheckedIcon />;
        }
    };
    return (
        <div className={`checkbox-container ${checked ? 'active' : 'inactive'}`} onClick={!disabled ? onClick : undefined}>{checked ? getCheckedIcon() : <CheckboxUncheckedIcon />}</div>
    );
};

Checkbox.defaultProps = {
    disabled: false,
    icon: 'tick',
};

export default Checkbox;
