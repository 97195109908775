import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApiAuth } from 'core/utils/api';
import { notification } from 'antd';
import { handleError } from 'core/utils/helpers';
import { ScheduleModalPayload } from 'pages/VisitorRequestView/components/ScheduleModal';
import { ReleaseSuccess, TableFilters, VisitRequestAddCommentResponse, VisitRequestCheckSessionResponse, VisitRequestReleaseResponse, VisitRequestResubmitActionResponse, VisitRequestReviewResponse, VisitRequestScheduleResponse } from './types';

export const getVisitRequestReview = createAsyncThunk(
    'visitRequestReview/get',
    async (payload: { id: string, filters: TableFilters }, { rejectWithValue }) => {
        try {
            const { id, filters } = payload;
            const response: VisitRequestReviewResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request-review/${id}/fetch`,
                body: { filters },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const updateVisitRequestReview = createAsyncThunk(
    'visitRequestReview/update',
    async (payload: { id: string | number, data: object }, { rejectWithValue }) => {
        const { id, data } = payload;
        try {
            const response: VisitRequestReviewResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request-review/${id}`,
                body: { ...data },
            });
            notification.success({
                message: 'Success',
                description: 'Visit request has been updated successfully',
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const resubmitActionRequest = createAsyncThunk(
    'visitRequestReview/resubmit',
    async (payload: { id: string | number }, { rejectWithValue }) => {
        const { id } = payload;
        try {
            const response: VisitRequestResubmitActionResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/action`,
                body: { action: 'resubmit' },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const checkRequestSession = createAsyncThunk(
    'visitRequestReview/checkRequestSession',
    async (payload: { id: string | number }, { rejectWithValue }) => {
        const { id } = payload;
        try {
            const response: VisitRequestCheckSessionResponse = await fetchApiAuth({
                method: 'GET',
                url: `visit-request/${id}/check-session`,
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const overruleSession = createAsyncThunk(
    'visitRequestReview/overruleSession',
    async (payload: { id: string | number }, { rejectWithValue }) => {
        const { id } = payload;
        try {
            const response: VisitRequestCheckSessionResponse = await fetchApiAuth({
                method: 'PUT',
                url: `visit-request/${id}/overrule`,
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const releaseSession = createAsyncThunk(
    'visitRequestReview/releaseSession',
    async (payload: { id: string | undefined }, { rejectWithValue }) => {
        const { id } = payload;
        try {
            const response: VisitRequestReleaseResponse<ReleaseSuccess> = await fetchApiAuth({
                method: 'PUT',
                url: `visit-request/${id}/release`,
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const fetchApprovers = createAsyncThunk(
    'visitRequestReview/fetchApprovers',
    async (payload: { id: string | number }, { rejectWithValue }) => {
        const { id } = payload;
        try {
            const response = await fetchApiAuth({
                method: 'GET',
                url: `visit-request-review/${id}/approvers`,
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const pushToApprover = createAsyncThunk(
    'visitRequestReview/pushToApprover',
    async (payload: { id: string | number, approverIds: string[] }, { rejectWithValue }) => {
        const { id, approverIds } = payload;
        try {
            const response = await fetchApiAuth({
                method: 'POST',
                url: `visit-request-review/${id}/push-approver`,
                body: { approverIds },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const addComment = createAsyncThunk(
    'visitRequestReview/addComment',
    async (payload: { id: string, newComment: string }, { rejectWithValue }) => {
        const { id, newComment } = payload;
        try {
            const response: VisitRequestAddCommentResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request/${id}/add-comment`,
                body: { newComment },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const scheduleRequest = createAsyncThunk(
    'visitRequestReview/schedule',
    async (payload: { id: string | number, data: ScheduleModalPayload }, { rejectWithValue }) => {
        const { id, data } = payload;
        try {
            const response: VisitRequestScheduleResponse = await fetchApiAuth({
                method: 'POST',
                url: `visit-request-review/${id}/schedule`,
                body: { ...data },
            });

            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const downloadAttachment = createAsyncThunk(
    'visitRequestReview/downloadAttachment',
    async (payload: { id: string | number, attachmentId: string | number, fileName: string }, { rejectWithValue }) => {
        const { id, attachmentId, fileName } = payload;
        try {
            const response = await fetchApiAuth({
                method: 'GET',
                responseType: 'blob',
                url: `visit-request-review/${id}/attachment/${attachmentId}`,
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            return true;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
