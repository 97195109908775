import React, { FC } from 'react';
import { Modal } from 'antd';
import './lockedRequestModal.scss';
import { LockedIcon } from 'components/Elements/CustomSVGIcon';

interface LockedRequestModalProps {
    isModalOpen: boolean;
    handleCancel: () => void;
    handleOverrule: () => void;
}

const LockedRequestModal: FC<LockedRequestModalProps> = ({ isModalOpen = true, handleCancel, handleOverrule }) => (
    <Modal
        footer={null}
        width={900}
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}>
        <div className="locked-request-modal-container">
            <div className="icon-wrapper">
                <LockedIcon />
            </div>
            <div className="caption">This ticket is currently locked as another user is viewing this request. You can take control of the ticket via the button on the right.</div>
            <button type="button" className="overrule-button" onClick={handleOverrule}>Overrule</button>
        </div>
    </Modal>

);

export default LockedRequestModal;
