import React from 'react';
import { Empty, Menu } from 'antd';
import { StatusData } from 'core/visitRequestReview/types';
import { AuthUserDataResponse } from 'core/user/types';
import { RequestActionPayload, getRequestActions } from './requestActions';

import './actionDropdown.scss';

type AvailableApprover = {
    id: number;
    name: string;
};

interface ActionDropdownProps {
    user: AuthUserDataResponse;
    onSelectAction: (action: string) => void;
    statusData: StatusData;
    onMouseLeave: () => void;
    requester: AuthUserDataResponse | undefined | null;
    approver: AuthUserDataResponse | undefined | null;
    availableApprovers: AvailableApprover[] | [] | undefined | null;
    costApprover: AuthUserDataResponse | undefined | null;
    hasCostApprove: boolean;
}

const ActionDropdown:React.FC<ActionDropdownProps> = ({ user, onSelectAction, statusData, onMouseLeave, requester, approver, availableApprovers, costApprover, hasCostApprove }) => {
    const renderItems = () => {
        const actions: RequestActionPayload[] = getRequestActions({
            user,
            statusData,
            requester,
            approver,
            availableApprovers,
            costApprover,
            hasCostApprove,
        });

        if (actions.length === 0) {
            return (
                <Menu.Item key="empty" style={{ padding: '20px', width: '300px' }}>
                    <Empty description="">
                        <p className="action-dropdown empty-text">You have no actions for this request.</p>
                    </Empty>
                </Menu.Item>
            );
        }

        return actions.map((dropdownItem) => (
            <Menu.Item
                key={dropdownItem.id}
                className="action-dropdown-item-wrapper"
                onClick={() => onSelectAction(dropdownItem.action)}
                disabled={dropdownItem.disabled}>
                <div className="action-dropdown-item">
                    <span>
                        <dropdownItem.Icon s={dropdownItem.iconStroke} />
                        {dropdownItem.label}
                    </span>
                </div>
            </Menu.Item>
        ));
    };

    return (
        <Menu
            className="dropdown"
            style={{
                boxShadow: '0px 0px 5px 3px rgba(43, 45, 66, 0.05)',
            }}
            onClick={(info) => {
                info.domEvent.stopPropagation();
            }}
            onMouseLeave={onMouseLeave}>
            {renderItems()}
        </Menu>
    );
};

export default ActionDropdown;
