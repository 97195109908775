import React, { FC, useState, useCallback } from 'react';
import Select, { SelectMethods } from 'react-dropdown-select';
import { ChevronDownIcon } from 'components/Elements/CustomSVGIcon';
import type { DropdownOption } from './types';
import './dropdown.scss';

interface DropdownProps {
    options: DropdownOption[];
    onSelect: (values: DropdownOption[]) => void;
    values: DropdownOption[];
}

const customStyles = {
    minHeight: '50px',
    borderRadius: '4px',
    border: '0.5px solid #2B2D42',
};

const Dropdown: FC<DropdownProps> = ({ options = [], onSelect, values = [] }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onDropdownOpen = useCallback(() => {
        setDropdownOpen(true);
    }, []);

    const onDropdownClose = useCallback(() => {
        setDropdownOpen(false);
    }, []);

    const getItem = (item: DropdownOption, methods: SelectMethods<DropdownOption>) => (
        <div className="dropdown-item-wrapper">
            <span className="item-label" onClick={() => methods.addItem(item)}>{item.label}</span>
        </div>
    );

    const getContentRenderer = () => (
        <div className="custom-content-renderer">
            {values[0]?.label}
        </div>
    );

    const getDropdownHandleRenderer = useCallback(() => (
        <div className={`dropdown-handle ${dropdownOpen ? 'rotate' : ''}`}>
            <ChevronDownIcon />
        </div>
    ), [dropdownOpen]);

    return (
        <div className="custom-dropdown-container">
            <Select
                contentRenderer={() => getContentRenderer()}
                searchable={false}
                style={customStyles}
                options={options}
                onChange={onSelect}
                onDropdownOpen={onDropdownOpen}
                onDropdownClose={onDropdownClose}
                values={values}
                color="#da0812"
                itemRenderer={({ item, methods }) => getItem(item, methods)}
                dropdownHandleRenderer={getDropdownHandleRenderer} />
        </div>
    );
};

export default Dropdown;
