import { useEffect } from 'react';
import { AppDispatch, CombinedStates } from 'core/types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnreadNotifications, fetchRecentNotifications, markAllNotificationsAsReadServer, markNotificationAsReadServer } from './notificationApi';
import { markAllNotificationsAsRead, markNotificationAsRead } from './notificationSlice';

const useNotifications = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { isFetching, notifications } = useSelector((state: CombinedStates) => state.notification);

    const fetchAllNotifications = () => {
        dispatch(fetchUnreadNotifications());
    };

    const markAsRead = (id: string) => {
        // pre-emptively mark as read
        dispatch(markNotificationAsRead(id));
        // then dispatch to server
        dispatch(markNotificationAsReadServer(id));
    };

    const markAllAsRead = () => {
        // pre-emptively mark as read
        dispatch(markAllNotificationsAsRead());
        // then dispatch to server
        dispatch(markAllNotificationsAsReadServer());
    };

    useEffect(() => {
        fetchAllNotifications();
        // Every 2 minutes, poll for new notifications
        const interval = setInterval(() => {
            console.log('Polling for new notifications');
            dispatch(fetchRecentNotifications());
        }, 120000);

        return () => clearInterval(interval);
    }, []);

    return {
        isFetching,
        notifications,
        fetchAllNotifications,
        markAsRead,
        markAllAsRead,
    };
};

export default useNotifications;
