import React, { FC } from 'react';
import type { UploadProps } from 'antd';
import { message, notification, Upload } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import './fileUploader.scss';
import { FileIcon, FileUploadIcon } from 'components/Elements/CustomSVGIcon';

interface FileUploaderProps {
    onChange: (fileList: UploadFile[]) => void;
    maxFiles?: number;
}

interface DraggableUploadListItemProps {
    originNode: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>;
}

const DraggableUploadListItem = ({ originNode }:DraggableUploadListItemProps) => (
    <div className="file-list">
        <FileIcon />
        {originNode}
        <div className="border" />
    </div>
);

const FileUploader: FC<FileUploaderProps> = ({ onChange }) => {

    const { Dragger } = Upload;

    const acceptedMimeTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/pdf',
        'image/jpeg',
        'image/png',
    ];

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        beforeUpload: (file) => {
            // Check if the uploaded file type is supported
            console.log(file.type);
            const isSupported = acceptedMimeTypes.includes(file.type);
            console.log({ isSupported });
            if (!isSupported) {
                notification.error({
                    message: 'Unsupported File Type',
                    description: 'The file you are attempting to upload is not supported. Please ensure that your file format is one of the accepted types (PDF, JPEG, PNG, XLSX, DOCX, PPTX) before trying to upload it again.',
                });
                return false;
            }
            return true;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading' && status !== undefined) {
                console.log(info.file, info.fileList);
                onChange(info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
        },
        showUploadList: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        itemRender: (originNode) => (
            <DraggableUploadListItem originNode={originNode} />
        ),
    };

    return (
        <Dragger {...props}
            className="file-dragger-container">
            <div className="file-uploader-content">
                <div className="icon-wrapper">
                    <FileUploadIcon />
                </div>
                <p className="caption">Drag and drop a document here, or click to upload</p>
                <p className="sub-caption">
                    Uploading documentation is optional
                </p>
            </div>
        </Dragger>
    );
};

FileUploader.defaultProps = {
    maxFiles: 20,
};

export default FileUploader;
