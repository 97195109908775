import React, { FC, useState, useEffect } from 'react';
import './returnCardsModal.scss';
import Table from 'components/Elements/Table';
import type { SaveReturnCardsValues, TableDataObject } from 'pages/VisitorsOnSite/types';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Elements/Tootltip';
import { CombinedStates } from 'core/types';
import { AccessCardObject } from 'core/securityDashboard/types';

interface ReturnCardsModalProps {
    onSave: (values: SaveReturnCardsValues) => void;
    selectedVisitorIds: string[];
}

interface ReturnTableDataObject {
    key: string;
    visitorName: string;
    assignedAccessCards: AccessCardObject[];
    assignedKeyReferences: AccessCardObject[];
}

const ReturnCardsModal: FC<ReturnCardsModalProps> = ({ onSave, selectedVisitorIds }) => {
    const [reasonForNoReturn, setReasonForNoReturn] = useState<string>('');
    const [checkedVisitors, setCheckedVisitors] = useState<string[]>([]);
    const [cardOrKeyIds, setCardOrKeyIds] = useState<string[]>([]);

    const visitorsToday = useSelector((state: CombinedStates) => state.securityDashboard.visitorsToday);
    const selectedVisitors = visitorsToday.filter((visitor) => selectedVisitorIds.includes(visitor.visitRequestVisitorId.toString()));
    const visitorsWithCardOrKey = selectedVisitors.filter((visitor) => visitor.assignedAccessCards.length > 0 || visitor.assignedKeyReferences.length > 0);

    useEffect(() => {
        const getCheckedVisitorsIds = visitorsWithCardOrKey.map((visitor) => visitor.visitRequestVisitorId.toString());
        setCheckedVisitors(getCheckedVisitorsIds);
    }, [selectedVisitorIds]);

    useEffect(() => {
        const allCheckedVisitors = visitorsWithCardOrKey.filter((visitor) => checkedVisitors.includes(visitor.visitRequestVisitorId.toString()));
        const allCardOrKeyIds = allCheckedVisitors.map((visitor) => {
            const cardIds = visitor.assignedAccessCards.map((card) => card.recordId.toString());
            const keyIds = visitor.assignedKeyReferences.map((key) => key.recordId.toString());
            return [...cardIds, ...keyIds];
        });
        const flattenedCardOrKeyIds = allCardOrKeyIds.flat();
        setCardOrKeyIds(flattenedCardOrKeyIds);
    }, [checkedVisitors]);

    const handleAddAccessCardClick = (key: string) => {
        console.log('Add access card clicked', key);
    };

    const handleAddKeyReferenceClick = (key: string) => {
        console.log('Add key reference clicked', key);
    };

    const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReasonForNoReturn(e.target.value);
    };

    const handleSaveClick = () => {
        const values = {
            cardOrKeyIds,
            reasonForNoReturn,
        };
        onSave(values);
        setReasonForNoReturn('');
        setCheckedVisitors([]);
        setCardOrKeyIds([]);
    };

    const columns = [
        {
            title: 'Visitor',
            dataIndex: 'visitorName',
            key: 'visitorName',
            width: '33%',
        },
        {
            title: 'Temporary Access Card',
            dataIndex: 'assignedAccessCards',
            key: 'assignedAccessCards',
            width: '33%',
            render: (assignedAccessCards: AccessCardObject[], record: TableDataObject) => {
                const accessCardDescriptions = assignedAccessCards.map((card) => card.description);
                const text = accessCardDescriptions.join(', ');
                if (text === '') {
                    const { key } = record;
                    return (
                        <div onClick={() => handleAddAccessCardClick(key)}>
                            <span className="add-label">Add</span>
                        </div>
                    );
                }
                return (
                    <p className="reference-text">{text}</p>
                );
            },
        },
        {
            title: 'Key Reference',
            dataIndex: 'assignedKeyReferences',
            key: 'assignedKeyReferences',
            width: '33%',
            render: (assignedKeyReferences: AccessCardObject[], record: TableDataObject) => {
                const keyReferenceDescriptions = assignedKeyReferences.map((key) => key.description);
                const text = keyReferenceDescriptions.join(', ');
                if (text === '') {
                    const { key } = record;
                    return (
                        <div onClick={() => handleAddKeyReferenceClick(key)}>
                            <span className="add-label">Add</span>
                        </div>
                    );
                }
                return (
                    <Tooltip title={text}>
                        <p className="reference-text">{text}</p>
                    </Tooltip>
                );
            },
        },
    ];

    const tableData: ReturnTableDataObject[] = visitorsWithCardOrKey.map((visitor) => {
        const {
            visitRequestVisitorId,
            visitorName,
            assignedAccessCards,
            assignedKeyReferences,
        } = visitor;
        return {
            key: visitRequestVisitorId.toString(),
            visitorName,
            assignedAccessCards,
            assignedKeyReferences,
        };
    });

    const rowSelection = {
        selectedRowKeys: checkedVisitors,
        onChange: (selectedRowKeys: React.Key[], selectedRows: ReturnTableDataObject[]) => {
            const visitorNames = selectedRows.map((row) => row.visitorName);
            const visitorIds = selectedVisitors.filter((visitor) => visitorNames.includes(visitor.visitorName)).map((visitor) => visitor.visitRequestVisitorId.toString());
            setCheckedVisitors(visitorIds);
        },
        getCheckboxProps: (record: ReturnTableDataObject) => ({
            disabled: record.visitorName === 'Disabled User', // Column configuration not to be checked
            name: record.visitorName,
        }),
    };

    return (
        <div className="return-cards-modal-container">
            <h1 className="heading">Returned Cards and Keys</h1>
            <p className="caption">
                Mark the Temporary Access Cards and Key References that have been returned as the visitors check out. If any have not been returned, please state the reason below.
            </p>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{
                    pageSize: 20,
                    currentPage: 1,
                    total: 20,
                    onChange: () => { console.log('hhh'); },
                }}
                columns={columns}
                data={tableData} />
            <div className="reason-container">
                <p className="caption">Reason for no return</p>
                <textarea
                    value={reasonForNoReturn}
                    onChange={handleReasonChange}
                    placeholder="e.g. visitor will return to site tomorrow" />
            </div>
            <div className="button-wrapper">
                <button onClick={handleSaveClick} type="button">Save</button>
            </div>
        </div>
    );
};

export default ReturnCardsModal;
