import { Button, Dropdown, Empty, Menu, Modal, ModalProps } from 'antd';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { ActionDataItem } from 'core/visitRequestReview/types';
import TextInput from 'components/Elements/TextInput';
import { CloseIcon, SearchIcon } from 'components/Elements/CustomSVGIcon';

import './pushActionModal.scss';
import Checkbox from 'components/Elements/Checkbox';
import { isEmpty } from 'underscore';

interface PushActionModalProps extends ModalProps {
    isModalOpen: boolean;
    handleCancel: () => void;
    handlePushToApprover: (selectedIds: string[]) => void;
    parentWorkType: ActionDataItem | null | undefined;
    childrenWorkTypes: ActionDataItem[] | null | undefined;
    defaultApprover: ActionDataItem[] | null | undefined;
    approvers: ActionDataItem[] | null | undefined;
    fetchData: () => void;
    loading: boolean;
}

export interface PushActionModalHandlers {
    clear: () => void;
}

const PushActionModal: React.ForwardRefRenderFunction<PushActionModalHandlers, PushActionModalProps> = ({
    isModalOpen = true,
    handleCancel,
    handlePushToApprover,
    parentWorkType,
    childrenWorkTypes,
    defaultApprover,
    approvers,
    loading,
    fetchData,
    ...rest
}, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const selectedApprovers = useMemo(() => approvers?.filter((item) => selectedIds.includes(item.id)), [approvers, selectedIds]);
    const filteredApprovers = useMemo(() => approvers?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase())), [approvers, searchTerm]);

    const handleClear = useCallback(() => {
        setSelectedIds([]);
        setSearchTerm('');
    }, [setSelectedIds, setSearchTerm]);

    useImperativeHandle(ref, () => ({
        clear: handleClear,
    }));

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleApproverSelection = (id: number) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const renderTag = useCallback((item: ActionDataItem) => (
        <div className="tag">
            {item.name}
            <div className="tag__remove"
                onClick={(info) => {
                    info.preventDefault();
                    info.stopPropagation();
                    handleApproverSelection(item.id);
                }}>
                <CloseIcon s="#FFF" />
            </div>
        </div>
    ), [selectedIds]);

    const renderItems = () => {
        if (approvers?.length === 0 || isEmpty(approvers)) {
            return (
                <Menu.Item key="empty" style={{ padding: '20px', width: '300px', margin: '0 auto' }}>
                    <Empty description="">
                        <p className="action-dropdown empty-text">No approvers found.</p>
                    </Empty>
                </Menu.Item>
            );
        }

        return filteredApprovers?.map((item) => (
            <Menu.Item
                onClick={(info) => {
                    info.domEvent.stopPropagation();
                    info.domEvent.preventDefault();
                    handleApproverSelection(item.id);
                }}
                key={item.id}>
                <div style={{ display: 'flex', gap: '10px', fontSize: '18px', marginTop: '2px', marginBottom: '2px' }}>
                    <Checkbox
                        checked={selectedIds.includes(item.id)}
                        onClick={(event) => {
                            event?.stopPropagation();
                            event?.preventDefault();
                            handleApproverSelection(item.id);
                        }} />
                    { item.name }
                </div>
            </Menu.Item>
        ));
    };

    const renderOptionsDropdown = () => (
        <Menu
            className="dropdown"
            onClick={(info) => info.domEvent.stopPropagation()}
            style={{
                boxShadow: '0px 0px 5px 3px rgba(43, 45, 66, 0.05)',
                paddingTop: '7px',
                paddingBottom: '5px',
                maxHeight: '185px',
                overflow: 'scroll',
            }}>
            {renderItems()}
        </Menu>
    );

    const renderSearchComponent = useCallback(() => (
        <Dropdown
            dropdownRender={renderOptionsDropdown}
            className="approvers-dropdown"
            trigger={['click']}
            align={{ offset: [0, 7] }}>
            <div className="selector">
                <div className="selector__search">
                    <p>Assign this request to an alternative approver</p>
                    <div className="search__wrapper">
                        { selectedApprovers?.length === 0 || isEmpty(selectedApprovers) ? (
                            <TextInput
                                placeholder="Search"
                                value={searchTerm}
                                borderless
                                onChange={handleSearchChange}
                                leftIcon={<SearchIcon />} />
                        ) : (
                            <div className="tag-container">
                                { selectedApprovers?.map((item) => renderTag(item)) }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Dropdown>
    ), [searchTerm, selectedApprovers]);

    const renderInfoBlock = useMemo(() => {
        const parent = parentWorkType?.name || 'N/A';
        const children = childrenWorkTypes?.map((child) => child.name).join(', ') || 'N/A';
        const _approvers = defaultApprover && defaultApprover?.length > 0 ? defaultApprover?.map((approver) => approver.name).join(', ') : 'N/A';

        return (
            <div className="info-block">
                <table>
                    <tbody>
                        <tr>
                            <td className="info-block__label">Parent Work Type:</td>
                            <td className="info-block__value">{ parent }</td>
                        </tr>
                        <tr>
                            <td className="info-block__label">Child Work Type:</td>
                            <td className="info-block__value">{ children }</td>
                        </tr>
                        <tr>
                            <td className="info-block__label">Default Approver:</td>
                            <td className="info-block__value">{ _approvers }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }, [parentWorkType, defaultApprover, childrenWorkTypes]);

    useEffect(() => {
        if (isModalOpen) {
            // Refresh data on load - data will already be present from visit request data
            // This will simply attempt to fetch any new approvers in the background
            fetchData();
        }
    }, [isModalOpen]);

    return (
        <Modal
            footer={null}
            width={650}
            open={isModalOpen}
            onCancel={handleCancel}
            title="Assign Request to Approver"
            wrapClassName="push-action-modal"
            {...rest}>
            <>
                {renderInfoBlock}
                <hr className="divider" />
                {renderSearchComponent()}
                <div className="modal-footer">
                    <Button
                        type="primary"
                        className="form-button"
                        loading={loading}
                        onClick={() => {
                            const ids = (isEmpty(selectedIds) && defaultApprover && defaultApprover.length > 0) ? defaultApprover.map(d => String(d.id)) : selectedIds.map((id) => String(id));
                            handlePushToApprover(ids);
                        }}>
                        Assign Request
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        className="form-button cancel-button"
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </>
        </Modal>
    );
};

export default forwardRef(PushActionModal);
