import React, { useRef, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import type { InputRef } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPagination } from 'core/dashboard/dashboardSlice';
import { resetPagination as resetSecurityPagination } from 'core/securityDashboard/securityDashboardSlice';
import { twoFactorConfirmation, twoFactorResend } from '../../../core/user/userApi';
import { FinishedFailedProps, valuesProps } from './types';
import { AppDispatch, CombinedStates } from '../../../core/types';
import './twoFactorAuth.scss';
import { SECURITY_USER } from '../../../core/utils/value';

const TwoFactorAuthForm = () => {
    const inputRefs = [
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
    ];
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const {
        isFetching,
        isLoggedIn,
        userData,
        showTwoFactorPage,
    } = useSelector((state:CombinedStates) => state.user);
    const [form] = Form.useForm();

    const focusNextInput = (currentRefIndex:number) => {
        const nextRef = inputRefs[currentRefIndex + 1];
        if (nextRef && nextRef.current) {
            nextRef.current.focus();
        }
    };

    const emptyCurrentAndFocusPreviousInput = (currentRefIndex:number, keyCode:number) => {
        console.log(currentRefIndex, keyCode);
        if (keyCode !== 8) return;
        const currentRef = inputRefs[currentRefIndex];
        if (currentRef && currentRef.current && currentRef.current.input) {
            setTimeout(() => {
                if (currentRefIndex > 0) {
                    const prevRef = inputRefs[currentRefIndex - 1];
                    if (prevRef && prevRef.current) {
                        prevRef?.current?.input?.focus();
                    }
                }
            }, 10);
        }
    };

    const onFinish = (values:valuesProps) => {
        const objectKeys = Object.keys(values);
        const code = objectKeys.reduce((str:string, key:string) => str + values[key as keyof valuesProps], '');
        dispatch(twoFactorConfirmation({
            code,
            callback: () => {
                dispatch(resetPagination());
                dispatch(resetSecurityPagination());
            },
        }));
    };

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    useEffect(() => {
        // If you want the first input to be focused when the component mounts
        inputRefs[0]?.current?.focus();
    }, []);

    useEffect(() => {
        const { role } = userData;
        const accessRole = role.accessLevel || null;
        console.log('accessRole', accessRole);
        if (isLoggedIn && accessRole === SECURITY_USER) {
            navigation('/security-dashboard');
        } else if (isLoggedIn) {
            navigation('/dashboard');
        } else if (showTwoFactorPage) {
            navigation('/two-factor');
        }
    }, [isLoggedIn, showTwoFactorPage]);

    return (
        <div className="two-factor-form-box">
            <div className="form">
                <h3 className="title-text">Verification Code</h3>
                <div className="form-sub-title">
                    Please enter the verification code we sent to your email address
                </div>
                <div className="form-sub-title2">
                    Enter Code
                </div>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal">
                    {inputRefs.map((ref, index) => (
                        <Form.Item name={`code${index}`} key={`code${index + 1}`}>
                            <Input
                                ref={ref}
                                maxLength={1}
                                onKeyDown={(e) => e.keyCode && emptyCurrentAndFocusPreviousInput(index, e.keyCode)}
                                onChange={(e) => e.target.value && focusNextInput(index)} />
                        </Form.Item>
                    ))}
                    {/* <Form.Item */}
                    {/*    label={null} */}
                    {/*    name="code1" */}
                    {/*    rules={[{ required: true, message: 'X' }]}> */}
                    {/*    <Input maxLength={1} /> */}
                    {/* </Form.Item> */}
                    {/* <Form.Item */}
                    {/*    label={null} */}
                    {/*    name="code2" */}
                    {/*    rules={[{ required: true, message: 'X' }]}> */}
                    {/*    <Input maxLength={1} /> */}
                    {/* </Form.Item> */}
                    {/* <Form.Item */}
                    {/*    label={null} */}
                    {/*    name="code3" */}
                    {/*    rules={[{ required: true, message: 'X' }]}> */}
                    {/*    <Input maxLength={1} /> */}
                    {/* </Form.Item> */}
                    {/* <Form.Item */}
                    {/*    label={null} */}
                    {/*    name="code4" */}
                    {/*    rules={[{ required: true, message: 'X' }]}> */}
                    {/*    <Input maxLength={1} /> */}
                    {/* </Form.Item> */}

                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{ width: '300px' }}
                            className="mt-3"
                            loading={isFetching}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <div className="text-center">
                    <Button onClick={() => dispatch(twoFactorResend())} type="link" className="button-link" href="#">
                        <span>Resend Code</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TwoFactorAuthForm;
