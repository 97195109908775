import React from 'react';
import { Button, Menu } from 'antd';
import { format, isToday, isYesterday } from 'date-fns';
import { NotificationItemProps } from './types';

import './notificationItem.css';

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, markAsRead, closeDropdown, navigation, isNextUnread }: NotificationItemProps) => {
    const handleOnClick = () => {
        if (notification.actionUrl) {
            try {
                const url = new URL(notification.actionUrl);
                window.open(url.href, '_blank');
            } catch {
                navigation(notification.actionUrl);
            }
        }

        if (!notification.readAt) {
            markAsRead(notification.id);
        }

        closeDropdown();
    };

    const calculateDate = () => {
        const date = new Date(notification.createdAt);

        if (isToday(date)) {
            return 'Today';
        }
        if (isYesterday(date)) {
            return 'Yesterday';
        }
        return format(date, 'dd MMMM yyyy');
    };

    const calculateTime = () => {
        const date = new Date(notification.createdAt);
        return format(date, 'hh:mm a');
    };

    const renderBullet = () => (
        <div>
            {'\u2022'}
        </div>
    );

    return (
        <Menu.Item
            key={notification.id}
            onClick={() => markAsRead(notification.id)}
            className="notification-item-wrapper"
            style={{
                backgroundColor: notification.readAt ? 'white' : '#EDF2F499',
                borderColor: notification.readAt && !isNextUnread ? '#EDF2F4' : '#C0D1D8',
            }}>
            <div className="notification-item">
                <p className="notification-message">{ notification.message }</p>
                <span className="notification-date">
                    { calculateTime() }
                    { renderBullet() }
                    { calculateDate() }
                </span>
                { notification.actionUrl
                    ? <Button className="notification-btn" size="middle" type="primary" onClick={handleOnClick}>View</Button>
                    : null }
            </div>
        </Menu.Item>
    );
};

export default NotificationItem;
