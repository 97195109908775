import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import './RequestTable.scss';
import { RequestDataResponse } from 'core/dashboard/types';
import { useNavigate } from 'react-router-dom';
import type { PaginationProps } from 'antd';
import { TableFilters } from 'core/visitRequestReview/types';

type LocalPaginationProps = {
    currentPage: number;
    pageSize: number;
    total: number;
    onChange: (page: number) => void;
}

interface RequestTableProps {
    columns: Array<object>;
    data: RequestDataResponse[];
    loading: boolean;
    pagination: LocalPaginationProps;
    filters: TableFilters;
}

const RequestTable: React.FC<RequestTableProps> = ({ columns, data, loading, pagination, filters }) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(pagination.currentPage);
    //  const [totalResult, setTotalResult] = useState(0);

    const handleRowClick = (record:RequestDataResponse) => {
        navigate(`/visitor-view-request/${record.encryptedId}`, { state: { filters } });
    };

    const rowProps = (record:RequestDataResponse) => ({
        onClick: () => handleRowClick(record),
    });

    const handleTotal = (total: number, range: [number, number]): JSX.Element => (
        <div style={{ left: 0, position: 'absolute' }}>
            Result: <span style={{ color: '#EF233C' }}>{range[0].toLocaleString()} - {range[1].toLocaleString()} of {pagination.total.toLocaleString()}</span>
        </div>
    );
    const handlePageChange = (page: number) => {
        console.log('page', page);
        setCurrentPage(page);
        pagination.onChange(page);
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [pagination.total]);

    const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
        const lastPage = Math.ceil(pagination.total / pagination.pageSize);
        if (type === 'page') {
            return page === pagination.currentPage ? <div className="request-table-pagination">Page <span style={{ color: '#EF233C' }}>{page}</span> of {lastPage}</div> : null;
        }
        return originalElement;
    };

    console.log('currentPage', currentPage);

    return (
        <div className="dashboard-table-container">
            <Table
                loading={loading}
                onRow={rowProps}
                dataSource={data}
                className="request-table"
                pagination={{
                    defaultPageSize: pagination.pageSize,
                    // defaultCurrent: 1,
                    current: pagination.currentPage,
                    total: pagination.total,
                    showSizeChanger: false,
                    itemRender,
                    showTotal: (total, range) => handleTotal(total, range),
                    onChange: handlePageChange,
                }}
                columns={columns} />
        </div>
    );
};

export default RequestTable;
