import React, { FC, useState } from 'react';
import TextInput from 'components/Elements/TextInput';
import { SearchIcon } from 'components/Elements/CustomSVGIcon';
import Highlighter from 'react-highlight-words';
import './listSearch.scss';

interface ClickListData {
    value: string | number;
    leftString: string;
    rightString?: string;
}

interface ListSearchProps {
    listData: ClickListData[];
    onSelect: (values: string | number) => void;
    selectedValues?: (number | string)[];
    showSelectedValues?: boolean;
}

const ListSearch: FC<ListSearchProps> = ({ listData, onSelect, selectedValues, showSelectedValues }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const getFilteredData = (): ClickListData[] => (listData as ClickListData[]).filter(item => item.leftString.toLowerCase().includes(searchTerm.toLowerCase()));
    const filteredData = getFilteredData();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSelect = (value: string | number) => {
        onSelect(value);
    };

    const getResults = (): JSX.Element => (
        <ul className="click-list-results">
            {(filteredData as ClickListData[]).map(item => (
                <li key={item.value}>
                    <div className={`click-list-item ${showSelectedValues && selectedValues?.includes(item.value) ? 'item-selected' : ''}`} onClick={() => handleSelect(item.value)}>
                        <Highlighter
                            highlightClassName="highlight"
                            searchWords={[searchTerm]}
                            autoEscape
                            textToHighlight={item.leftString} />
                        {item.rightString
                            && (
                                <Highlighter
                                    className="click-list-right-string"
                                    highlightClassName="highlight"
                                    searchWords={[searchTerm]}
                                    autoEscape
                                    textToHighlight={item.rightString} />
                            )}
                    </div>
                </li>
            ))}
            {filteredData.length === 0 && <li className="no-results-found">No results found</li>}
        </ul>
    );

    return (
        <div className="list-search-container">
            <div className="input-wrapper">
                <TextInput value={searchTerm} leftIcon={<SearchIcon />} onChange={handleSearchChange} />
            </div>
            <div className="results-container">
                {getResults()}
            </div>
        </div>
    );
};

export default ListSearch;

ListSearch.defaultProps = {
    selectedValues: [],
    showSelectedValues: false,
};
