/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Modal, ModalProps, Select, notification } from 'antd';
import { ActionDataItem } from 'core/visitRequestReview/types';
import React, { useCallback, useMemo, useState } from 'react';

import './scheduleModal.scss';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from 'components/Elements/CustomSVGIcon';
import moment, { Moment } from 'moment';
import { isEmpty, isUndefined } from 'underscore';
import TextInput from 'components/Elements/TextInput';

interface ShiftDataProps {
    securityOfficerId?: number | undefined;
    securityOfficer?: string | undefined;
    date: string | Moment;
    startTime: string | Moment;
    endTime: string | Moment;
    id: number | undefined;
}

export interface ScheduleModalPayload {
    dateId: string | number | undefined;
    securityOfficerId: string | number | undefined | null;
    tempSecurityName: string;
}

interface ScheduleModalProps extends ModalProps {
    securityTeam: ActionDataItem[] | null | undefined;
    isModalOpen: boolean;
    shiftData: ShiftDataProps[] | null | undefined;
    handleCancel: () => void;
    handleComplete: (payload: ScheduleModalPayload) => void;
    loading: boolean;
}

interface DropdownOption {
    value: string | number;
    label: string;
}

/**
 * Format time to 12 hour format
 * Examples:
 *  - 13:00:00 => 1 PM
 *  - 13:30:00 => 1:30 PM
 * @param time Time to be formatted
 * @returns string
 */
const formatTime = (time: string | Moment) => {
    if (typeof time === 'string') {
        const momentObj = moment(time, 'HH:mm:ss');
        const minute = momentObj.minutes();
        const formatString = minute === 0 ? 'h A' : 'h:mm A';
        return momentObj.format(formatString);
    }
    const minute = time.minutes();
    const formatString = minute === 0 ? 'h A' : 'h:mm A';
    return time.format(formatString);

};

const formatShiftLabel = (_shiftData: ShiftDataProps) => {
    const date = moment(_shiftData.date).format('dddd Do MMMM');
    const startTime = formatTime(_shiftData.startTime);
    const endTime = formatTime(_shiftData.endTime);

    return `${date} ${startTime} - ${endTime}`;
};

const ScheduleModal: React.FC<ScheduleModalProps> = ({
    securityTeam,
    isModalOpen = true,
    handleCancel,
    handleComplete,
    loading,
    shiftData,
    ...rest
}) => {
    const [selectedSecurityId, setSelectedSecurityId] = useState<string | undefined | number | null>(null);
    const [selectedShiftId, setSelectedShiftId] = useState<string | undefined | number | null>(null);
    const [shiftDropdownOpen, setShiftDropdownOpen] = useState<boolean>(false);
    const [tempSecurityName, setTempSecurityName] = useState<string>('');
    const selectedSecurity = securityTeam?.find((item) => item.id === selectedSecurityId);

    const shiftDataOptions: DropdownOption[] = useMemo(() => shiftData?.map((item) => ({ value: item.id || '', label: formatShiftLabel(item) })) || [], [shiftData]);

    const handleShiftIdChange = (value: string | number | undefined) => {
        console.log('value', value);
        setSelectedShiftId(value);

        const defaultSecurityId = shiftData?.find((item) => item.id === value)?.securityOfficerId;
        const defaulSecurityName = shiftData?.find((item) => item.id === value)?.securityOfficer;
        if (defaultSecurityId) {
            setSelectedSecurityId(defaultSecurityId);
            setTempSecurityName('');
        }

        if (!defaultSecurityId && defaulSecurityName) {
            setTempSecurityName(defaulSecurityName);
            setSelectedSecurityId(null);
        }
    };

    const handleSubmit = () => {
        if (isUndefined(selectedShiftId) || selectedShiftId === null) {
            notification.warning({
                message: 'Missing Fields',
                description: 'Please select a shift to attach a security officer to.',
            });
            return;
        }

        if ((isUndefined(selectedSecurityId) || selectedSecurityId === null) && tempSecurityName === '') {
            notification.warning({
                message: 'Missing Fields',
                description: 'Please select a security officer to assign to this shift.',
            });
            return;
        }

        handleComplete({
            dateId: selectedShiftId,
            securityOfficerId: selectedSecurityId,
            tempSecurityName,
        });
    };

    const handleTempSecurityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempSecurityName(e.target.value);
        setSelectedSecurityId(null);
    };

    const handleSelectSecurity = (e: React.SetStateAction<string | number | null | undefined>) => {
        setSelectedSecurityId(e);
        setTempSecurityName('');
    };

    const renderShiftDropdown = () => (
        <div className="shift-dropdown">
            <Select
                placeholder="Select a Shift"
                style={{ width: '100%', height: '50px', fontSize: '18px' }}
                onChange={(value) => handleShiftIdChange(value as string | number | undefined)}
                options={shiftDataOptions || []}
                filterOption={(input, option) => {
                    if (option?.label === undefined) {
                        return false;
                    }
                    return option?.label?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onDropdownVisibleChange={(open) => setShiftDropdownOpen(open)}
                suffixIcon={shiftDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                value={shiftDataOptions.find(s => s.value === selectedShiftId)} />
        </div>
    );

    const renderSecurityDropdown = () => (
        <div className="security-dropdown">
            <Select
                showSearch
                placeholder="Search"
                style={{ width: '100%', height: '50px', fontSize: '18px' }}
                onChange={handleSelectSecurity}
                options={securityTeam?.map((item) => ({ value: item.id, label: item.name })) || []}
                filterOption={(input, option) => {
                    if (option?.label === undefined) {
                        return false;
                    }
                    return option?.label?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                value={selectedSecurity?.name}
                suffixIcon={<SearchIcon />} />
        </div>
    );

    return (
        <Modal
            footer={null}
            width={650}
            open={isModalOpen}
            centered
            onCancel={handleCancel}
            title="Assign Security Officer"
            wrapClassName="schedule-action-modal"
            {...rest}>
            <div className="schedule-action-modal__inner">
                <p className="bold">Select Shift</p>
                { renderShiftDropdown() }
                <p>Assign a security officer to this request</p>
                { renderSecurityDropdown() }
                <div className="security-input">
                    <TextInput
                        value={tempSecurityName}
                        placeholder="Input Name"
                        onChange={handleTempSecurityChange} />
                </div>

                <div className="modal-footer">
                    <Button
                        type="primary"
                        className="form-button"
                        loading={loading}
                        onClick={() => handleSubmit()}>
                        Assign Security Officer
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        className="form-button cancel-button"
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ScheduleModal;
