import React, { FC } from 'react';
import './textinput.scss';
import { InputProps } from 'antd';

interface TextInputProps extends InputProps {
    value?: string;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    borderless?: boolean;
}

const TextInput: FC<TextInputProps> = ({ placeholder, onChange, leftIcon, rightIcon, value = '', borderless, ...rest }) => (
    <div className={`text-input-container ${borderless && 'borderless'}`}>
        {leftIcon && <div className="left-icon">{leftIcon}</div>}
        <input className="text-input" placeholder={placeholder} onChange={onChange} value={value} onFocus={rest?.onFocus} onClick={rest?.onClick} />
        {rightIcon && <div className="right-icon">{rightIcon}</div>}
    </div>
);

TextInput.defaultProps = {
    placeholder: '',
    leftIcon: null,
    rightIcon: null,
    value: '',
    borderless: false,
};

export default TextInput;
