import React, { FC } from 'react';
import './formSection.scss';

interface FormSectionProps {
    children: React.ReactNode;
    title: string;
}

const FormSection: FC<FormSectionProps> = ({ children, title }) => (
    <div className="form-section-container">
        <h3 className="title">{title}</h3>
        <div className="content">{children}</div>
    </div>
);

export default FormSection;
