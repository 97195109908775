import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { LoadingOverlayProps } from './types';

import './loadingOverlay.scss';

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, message }: LoadingOverlayProps) => (
    loading ? (
        <div className="loading-overlay">
            <div className="loading-overlay__content">
                <LoadingOutlined className="loading-overlay__spinner" style={{ fontSize: '75px' }} />
                <div className="loading-overlay__message">{message}</div>
            </div>
        </div>
    ) : null
);

export default LoadingOverlay;
