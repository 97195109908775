import React, { FC } from 'react';
import './costCode.scss';
import WarningBox from 'components/Elements/WarningBox';
import TextInput from 'components/Elements/TextInput';
import Tooltip from 'components/Elements/Tootltip';
import { WarningIcon } from '../../Elements/CustomSVGIcon';
import FormSection from '../FormSection';

interface CostCodeProps {
    onChangeCode: (code: string) => void;
    onChangeEmail: (email: string) => void;
    code: string;
    email: string;
    requireEmail: boolean;
}

const CostCode: FC<CostCodeProps> = ({ onChangeCode, onChangeEmail, code, email, requireEmail }) => {
    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeCode(e.target.value);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeEmail(e.target.value);
    };

    const getTooltip = () => (
        <div>
            <p>You only need to enter one cost code here. This can be in the format of the following:
                <br />
                <span className="tooltip-bold"><b>Project Code:</b></span> N00001
                <br />
                <span className="tooltip-bold"><b>Cost Centre:</b></span>   GB00001
                <br />
                <span className="tooltip-bold"><b>Vodafone PO Code:</b></span>
                1500001
            </p>
        </div>
    );

    return (
        <FormSection title="Enter Cost Code">
            <div className="cost-code-container">
                <div className="code-container">
                    <WarningBox>If you have requested to book a visit on a day another person has already submitted a request for, you still need to enter a Cost Code, but you will not be charged.
                        <br /><br /> Please be aware, if the current approved requester cancels for any reason, you will then be charged against the cost code you provide.
                    </WarningBox>
                    <div className="heading-wrapper">
                        <h5 className="heading">Cost Centre, Project Code or PO</h5>
                        <Tooltip title={getTooltip()}>
                            <div>
                                <WarningIcon />
                            </div>
                        </Tooltip>
                    </div>
                    <div className="input-wrapper">
                        <TextInput onChange={handleCodeChange} value={code} />
                    </div>
                </div>

                {requireEmail && (
                    <div className="contact-container">
                        <WarningBox>To continue with your request, please enter the email address of the Vodafone Sponsor/Manager who provided you with the Cost Centre, Project Code or PO.
                            <br /><br /> Request for a Non-Open Day is chargeable, however any request for an Open-Day will not require Cost Centre, Project Code or PO.
                        </WarningBox>
                        <div className="heading-wrapper">
                            <h5 className="heading" style={{ marginTop: '20px' }}>Vodafone Email Address for Cost Approval</h5>
                        </div>
                        <div className="input-wrapper">
                            <TextInput onChange={handleEmailChange} value={email} />
                        </div>
                    </div>
                )}
            </div>
        </FormSection>
    );
};

export default CostCode;
