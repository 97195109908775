import React, { FC, useState } from 'react';
import FormSection from 'components/Form/FormSection';
import { useForm, SubmitHandler } from 'react-hook-form';
import './manuallyAddVisitor.scss';
import Checkbox from 'components/Elements/Checkbox';
import type { AddVisitorValues } from './types';

interface ManuallyAddVisitorProps {
    addVisitorSubmit: (values: AddVisitorValues) => void;
}

interface Inputs {
    fullName: string;
    phoneNumber: string;
    email: string;
    companyName: string;
}

const ManuallyAddVisitor: FC<ManuallyAddVisitorProps> = ({ addVisitorSubmit }) => {
    const [saveVisitor, setSaveVisitor] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const cleanData = {
            ...data,
            phoneNumber: data.phoneNumber.trim(),
            saveVisitor,
        };
        addVisitorSubmit(cleanData);
    };

    const handleCheckSaveVisitorClick = () => {
        setSaveVisitor(!saveVisitor);
    };

    return (
        <FormSection title="Visitor Details">
            <div className="add-visitors-manually-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="fullName">Full Name</label>
                    <input id="fullName" {...register('fullName', { required: true })} />
                    {errors.fullName && <span className="error-message">A visitor name is required</span>}
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input id="phoneNumber"
                        {...register('phoneNumber', {
                            required: 'A phone number name is required',
                            pattern: {
                                value: /^\+?[0-9]{7,17} ?$/,
                                message: 'Invalid phone number',
                            } })} />
                    {errors.phoneNumber && <span className="error-message">{errors.phoneNumber.message}</span>}
                    <label htmlFor="email">Email Address</label>
                    <input id="email"
                        {...register('email', {
                            required: 'An email address is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address',
                            },
                        })} />
                    {errors.email && <span className="error-message">{errors.email.message}</span>}
                    <label htmlFor="companyName">Company Name</label>
                    <input id="companyName" {...register('companyName', { required: true })} />
                    {errors.companyName && <span className="error-message">A company name is required</span>}
                    <div className="save-visitor-container">
                        <Checkbox checked={saveVisitor} onClick={handleCheckSaveVisitorClick} />
                        <span className="caption">Save visitor details to system to save entering their details again in the future</span>
                    </div>
                    <div className="submit-wrapper">
                        <input type="submit" value="Add to Request" />
                    </div>
                </form>
            </div>
        </FormSection>
    );
};

export default ManuallyAddVisitor;
