import React, { useEffect, useState } from 'react';
import RequestFormLayout from 'components/layout/RequestFormLayout';
import { notification } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, CombinedStates } from 'core/types';
import { loadVisitRequestForm, updateVisitRequestForm } from 'core/visitRequestForm/visitRequestFormApi';
import VisitorList from 'components/Form/VisitorList';
import VisitorSiteAccess from 'components/Form/VisitorSiteAccess';
import { SiteAccess, VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import SiteAccessNotifications from 'components/Form/SiteAccessNotifications';
import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty } from 'underscore';

interface Visitor {
    id: number;
    name: string;
}

const SiteAccessForm: React.FC = () => {
    const [savedShiftDates, setSavedShiftDates] = useState<SiteAccess[]>([]);
    const [visitorsToNotify, setVisitorsToNotify] = useState<number[]>([]);

    const { stepData, visitRequest } = useSelector((state: CombinedStates) => state.visitRequestForm);
    const { id } = useParams<{ id: string }>();

    const { state: navState } = useLocation();
    const amending = navState?.amending;

    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const handleBackClick = (navigationState: Record<string, unknown>) => {
        navigation(`/visitor-request/${id}/step/3`, { state: navigationState });
    };

    const handleInitialLoad = () => {
        if (id !== undefined) {
            dispatch(loadVisitRequestForm({
                id,
                step: 4,
                amending,
            }));
        } else {
            navigation('/dashboard');
            notification.error({
                message: 'Invalid Visit Request',
                description: 'Please select a visit requst to proceed',
            });
        }
    };

    useEffect(() => {
        handleInitialLoad();
    }, []);

    useEffect(() => {
        if (visitRequest && stepData.visitors?.length) {
            if (!isEmpty(savedShiftDates)) {
                return;
            }
            setSavedShiftDates(visitRequest.siteAccess);
            const initialVisitorsToNotify = stepData.visitors
                .filter(visitor => visitor.notificationsEnabled)
                .map(visitor => visitor.id);
            setVisitorsToNotify(initialVisitorsToNotify);
        }
    }, [visitRequest, stepData]);

    let visitors: Visitor[] = [];
    if (stepData.visitors !== undefined) {
        visitors = stepData.visitors.map((visitor) => ({
            id: visitor.id,
            name: visitor.name,
        })).sort((a, b) => a.name.localeCompare(b.name));
    }

    const handleNextClick = (navigationState: Record<string, unknown>) => {
        const { dates } = stepData;
        if (dates?.length !== savedShiftDates.length) {
            notification.error({
                message: 'Invalid Site Access',
                description: 'Please select site access for all dates',
            });
            return;
        }

        const visitorsToDispatch = visitors.map((visitor) => ({
            id: visitor.id,
            notificationsEnabled: visitorsToNotify.includes(visitor.id),
        }));

        if (id !== undefined) {
            dispatch(updateVisitRequestForm({
                id,
                step: 4,
                data: {
                    visitors: visitorsToDispatch,
                    visitorAccess: savedShiftDates,
                    amending,
                },
            })).then(unwrapResult).then((payload: VisitRequestFormResponsePayload) => {
                if (payload.stepData) {
                    navigation(`/visitor-request/${id}/step/5`, { state: navigationState });
                }
            });
        }
    };

    const updateSavedAreaAccess = (values: SiteAccess[]) => {
        setSavedShiftDates(values);
    };

    const updateVisitorNotifications = (visitorIds: number[]) => {
        setVisitorsToNotify(visitorIds);
    };

    return (
        <RequestFormLayout
            currentStep={4}
            isNextEnabled
            isBackEnabled
            onBackClick={handleBackClick}
            onNextClick={handleNextClick}>
            <>
                <VisitorList visitors={visitors} />
                <VisitorSiteAccess
                    siteAreas={stepData.areas || []}
                    savedShiftDates={savedShiftDates}
                    handleSave={updateSavedAreaAccess} />
                <SiteAccessNotifications
                    visitors={visitors}
                    selectedVisitors={visitorsToNotify}
                    onSelect={updateVisitorNotifications} />
            </>
        </RequestFormLayout>
    );
};

export default SiteAccessForm;
