import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import './DropdownSearch.scss';
import { ChevronDownIcon, CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'components/Elements/CustomSVGIcon';
import { CheckedItems } from '../../types';

interface Site {
    id: number;
    addressLine1: string;
}

interface DropdownSearchProps {
    onFilterStatus: (status:Array<string>) => void;
    onFilterSite: (site:Array<string>) => void;
    statusFilter: object;
    siteFilter: Array<Site>;
    defaultStatus: Array<string>;
    defaultSite: Array<string> | undefined | null;
}

const DropdownSearch: React.FC<DropdownSearchProps> = ({ onFilterStatus, onFilterSite, statusFilter, siteFilter, defaultStatus, defaultSite }) => {
    const [checkedStatusItems, setCheckedStatusItems] = useState<CheckedItems>({});
    const [checkedSiteItems, setCheckedSiteItems] = useState<CheckedItems>({});

    useEffect(() => {
        if (defaultStatus) {
            const defaultStatusItems: CheckedItems = {};
            defaultStatus.forEach((status) => {
                defaultStatusItems[status] = true;
            });
            setCheckedStatusItems(defaultStatusItems);
        }
        if (defaultSite) {
            const defaultSiteItems: CheckedItems = {};
            defaultSite.forEach((site) => {
                defaultSiteItems[site] = true;
            });
            setCheckedSiteItems(defaultSiteItems);
        }
    }, [defaultStatus, defaultSite]);

    const handleStatusItemClick = (key:string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        const updatedCheckedItems: CheckedItems = { ...checkedStatusItems };
        if (updatedCheckedItems[key]) {
            delete updatedCheckedItems[key];
        } else {
            updatedCheckedItems[key] = true;
        }
        setCheckedStatusItems(updatedCheckedItems);
        onFilterStatus(Object.keys(updatedCheckedItems));
    };

    const handleSiteItemClick = (key:string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();

        const updatedCheckedItems: CheckedItems = { ...checkedSiteItems };
        if (updatedCheckedItems[key]) {
            delete updatedCheckedItems[key];
        } else {
            updatedCheckedItems[key] = true;
        }
        setCheckedSiteItems(updatedCheckedItems);
        onFilterSite(Object.keys(updatedCheckedItems));
    };

    const statuses = Object.entries(statusFilter).map(([key, value]) => ({
        label: (
            <div onClick={(event) => handleStatusItemClick(key, event)} className="item-dropdown">
                {checkedStatusItems[key] ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
                <div className="item-title-dropdown">
                    {value}
                </div>
            </div>
        ),
        key,
    }));

    const sites = siteFilter?.map((site: Site) => ({
        label: (
            <div onClick={(event) => handleSiteItemClick(site.addressLine1, event)} className="item-dropdown">
                {checkedSiteItems[site.addressLine1] ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
                <div className="item-title-dropdown">
                    {site.addressLine1}
                </div>
            </div>
        ),
        key: site.id,
    }));

    return (
        <div className="dropdown-container">
            <Dropdown
                overlayStyle={{ borderColor: '#2B2D42' }}
                menu={{ items: statuses }}
                overlayClassName="status-dropdown"
                placement="bottomRight"
                trigger={['click']}>
                <button type="button" className="dropdown-filter-button">
                    <div className="dropdown-filter-content">
                        Statuses
                        <ChevronDownIcon />
                    </div>
                </button>
            </Dropdown>
            <Dropdown
                menu={{ items: sites }}
                trigger={['click']}
                overlayClassName="status-dropdown"
                placement="bottomRight">
                <button type="button" className="dropdown-filter-button">
                    <div className="dropdown-filter-content">
                        Site
                        <ChevronDownIcon />
                    </div>
                </button>
            </Dropdown>
        </div>
    );
};

export default DropdownSearch;
