import React, { FC, useState, useEffect } from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'components/Elements/Tootltip';
import Main from 'components/layout/Main';
import './visitorsOnSite.scss';
import { LeftChevron } from 'components/Elements/CustomSVGIcon';
import Table from 'components/Elements/Table';
import { assignAccessCard, assignKeyReference, checkInVisitors, checkOutVisitors, visitorsTodayRequest } from 'core/securityDashboard/securityDashboardApi';
import { AppDispatch, CombinedStates } from 'core/types';
import moment from 'moment';
import { Modal } from 'antd/lib';
import { AccessCardObject } from 'core/securityDashboard/types';
import { useNavigate, useParams } from 'react-router';
import { ADMIN_ACCESS_LEVELS } from 'core/utils/value';
import AccessCardsModal from './components/AccessCardsModal';
import { SaveAccessCardValues, SaveKeyReferenceValues, SaveReturnCardsValues, TableDataObject } from './types';
import KeyReferenceModal from './components/KeyReferenceModal';
import ReturnCardsModal from './components/ReturnCardsModal';

const VisitorsOnSite: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectedVisitors, setSelectedVisitors] = useState<string[]>([]);
    const [showAccessCardModal, setShowAccessCardModal] = useState<boolean>(false);
    const [showKeyReferenceModal, setShowKeyReferenceModal] = useState<boolean>(false);
    const [selectedVisitor, setSelectedVisitor] = useState<string>('');
    const [showReturnCardsModal, setShowReturnCardsModal] = useState<boolean>(false);
    const { userData } = useSelector((state: CombinedStates) => state.user);

    const toolbar = [
        { label: 'Dashboard', link: ADMIN_ACCESS_LEVELS.includes(userData?.role?.accessLevel) ? '/dashboard' : '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const backLink = (
        <Button type="link" onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    const dispatch = useDispatch<AppDispatch>();
    const { visitorsToday, isFetching, visitorsTodayPagination } = useSelector((state: CombinedStates) => state.securityDashboard);

    useEffect(() => {
        if (id !== undefined || id !== null || id !== '') {
            dispatch(visitorsTodayRequest({
                page: 1,
                visitRequestId: id,
            }));
        }
    }, [id]);

    const handleBackClick = () => {
        navigate('/security-dashboard', { replace: true });
    };

    const handleModalCancel = () => {
        setShowAccessCardModal(false);
        setShowKeyReferenceModal(false);
        setShowReturnCardsModal(false);
        setSelectedVisitor('');
        setSelectedVisitors([]);
    };

    const handleCheckoutProcess = (reasonForNoReturn: string, returnedCardRecordIds: string[] = []) => {
        dispatch(checkOutVisitors({
            visitRequestVisitorIds: selectedVisitors,
            reasonForNoReturn,
            cardKeyVisitorRecordIds: returnedCardRecordIds,
            visitRequestId: id,
        }));
        handleModalCancel();
    };

    const handleCheckInClick = () => {
        //  TODO:  update the siteId from the logged in user
        dispatch(checkInVisitors({
            visitRequestVisitorIds: selectedVisitors,
            visitRequestId: id,
        }));
    };

    const handleCheckOutClick = () => {
        const selectedVisitorObjects = visitorsToday.filter((visitor) => selectedVisitors.includes(visitor.visitRequestVisitorId.toString()));
        const hasAccessCard = selectedVisitorObjects.some((visitor) => visitor.assignedAccessCards.length > 0);
        const hasKeyReference = selectedVisitorObjects.some((visitor) => visitor.assignedKeyReferences.length > 0);
        if (hasAccessCard || hasKeyReference) {
            setShowReturnCardsModal(true);
        } else {
            handleCheckoutProcess('', []);
        }
    };

    const handleReturnCardsSave = (values: SaveReturnCardsValues) => {
        const { reasonForNoReturn, cardOrKeyIds } = values;
        handleCheckoutProcess(reasonForNoReturn, cardOrKeyIds);
    };

    const handleAddAccessCardClick = (visitorRequestVisitorId: string) => {
        setShowAccessCardModal(true);
        setSelectedVisitor(visitorRequestVisitorId);
    };

    const handleAddKeyReferenceClick = (visitorRequestVisitorId: string) => {
        setShowKeyReferenceModal(true);
        setSelectedVisitor(visitorRequestVisitorId);
    };

    const handleAccessCardSave = (values: SaveAccessCardValues) => {
        const { selectedCardId, applyToAll } = values;
        if (applyToAll) {
            dispatch(assignAccessCard({
                selectedVisitorIds: selectedVisitors,
                cardId: selectedCardId,
                visitRequestId: id,
            }));
        } else {
            dispatch(assignAccessCard({
                selectedVisitorIds: [selectedVisitor],
                cardId: selectedCardId,
                visitRequestId: id,
            }));
        }
        handleModalCancel();
    };

    const handleKeyReferenceSave = (values: SaveKeyReferenceValues) => {
        const { keyReference, description, applyToAll } = values;
        const descriptionToAdd = `${keyReference} - ${description}`;
        if (applyToAll) {
            dispatch(assignKeyReference({
                selectedVisitorIds: selectedVisitors,
                description: descriptionToAdd,
                visitRequestId: id,
            }));
        } else {
            dispatch(assignKeyReference({
                selectedVisitorIds: [selectedVisitor],
                description: descriptionToAdd,
                visitRequestId: id,
            }));
        }
        handleModalCancel();
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'visitorName',
            key: 'visitorName',
            width: '20%',
        },
        {
            title: 'Check-in Time',
            dataIndex: 'checkInTime',
            key: 'checkInTime',
            width: '20%',
            render: (text: string) => <span>{text === '' ? '-' : moment(text).format('h:mm A')}</span>,
        },
        {
            title: 'Check Out Time',
            dataIndex: 'checkOutTime',
            key: 'checkOutTime',
            width: '20%',
            render: (text: string) => <span>{text === '' ? '-' : moment(text).format('h:mm A')}</span>,
        },
        {
            title: 'Temporary Access Card',
            dataIndex: 'assignedAccessCards',
            key: 'assignedAccessCards',
            width: '20%',
            render: (assignedAccessCards: AccessCardObject[], record: TableDataObject) => {
                const accessCardDescriptions = assignedAccessCards.map((card) => card.description);
                const text = accessCardDescriptions.join(', ');
                if (text === '') {
                    const { key } = record;
                    return (
                        <div onClick={() => handleAddAccessCardClick(key)}>
                            <span className="add-label">Add</span>
                        </div>
                    );
                }
                return (
                    <p className="reference-text">{text}</p>
                );
            },
        },
        {
            title: 'Key Reference',
            dataIndex: 'assignedKeyReferences',
            key: 'assignedKeyReferences',
            width: '20%',
            render: (assignedKeyReferences: AccessCardObject[], record: TableDataObject) => {
                const keyReferenceDescriptions = assignedKeyReferences.map((key) => key.description);
                const text = keyReferenceDescriptions.join(', ');
                if (text === '') {
                    const { key } = record;
                    return (
                        <div onClick={() => handleAddKeyReferenceClick(key)}>
                            <span className="add-label">Add</span>
                        </div>
                    );
                }
                return (
                    <Tooltip title={text}>
                        <p className="reference-text">{text}</p>
                    </Tooltip>
                );
            },
        },
    ];

    const data: TableDataObject[] = visitorsToday.map((visitor) => {
        const { visitorName, checkInTime, checkOutTime, visitRequestVisitorId, assignedAccessCards, assignedKeyReferences } = visitor;
        return {
            key: visitRequestVisitorId.toString(),
            visitorName,
            checkInTime: checkInTime || '',
            checkOutTime: checkOutTime || '',
            tempAccessCard: '',
            keyReference: '',
            assignedAccessCards: assignedAccessCards || [],
            assignedKeyReferences: assignedKeyReferences || [],
        };
    });

    const rowSelection = {
        selectedRowKeys: selectedVisitors,
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataObject[]) => {
            const visitorNames = selectedRows.map((row) => row.visitorName);
            const visitorIds = visitorsToday.filter((visitor) => visitorNames.includes(visitor.visitorName)).map((visitor) => visitor.visitRequestVisitorId.toString());
            setSelectedVisitors(visitorIds);
        },
        getCheckboxProps: (record: TableDataObject) => ({
            disabled: record.visitorName === 'Disabled User', // Column configuration not to be checked
            name: record.visitorName,
        }),
    };

    const renderInvalidIdModal = () => (
        <Modal
            open={id === undefined || id === null || id === ''}
            onCancel={handleBackClick}
            centered
            footer={null}
            width={650}>
            <div className="invalid-id-modal">
                <h2 className="heading">Invalid Reference</h2>
                <div className="caption">
                    <p>The reference you have provided is invalid! Please head back to the dashboard and select a valid request to view.</p>
                </div>
                <div className="button-wrapper">
                    <button type="button" className="overrule-button" onClick={handleBackClick}>OK</button>
                </div>
            </div>
        </Modal>
    );

    const getCheckoutDisabled = () => {
        const selectedVisitorObjects = visitorsToday.filter((visitor) => selectedVisitors.includes(visitor.visitRequestVisitorId.toString()));
        if (selectedVisitorObjects.every((visitor) => visitor.checkInTime !== null)) {
            return false;
        }
        return true;
    };

    return (
        <Main
            className="login"
            title={<div>Visitors on Site</div>}
            hideSubHeader
            subHeaderTitle="Visitors on Site"
            subHeaderHasBackLink
            subHeaderBackLink="/"
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            footerContent=""
            floatingFooter={false}
            backLink={backLink}
            breadcrumb={navigation}>
            <>
                {renderInvalidIdModal()}
                <Modal
                    open={showAccessCardModal}
                    onCancel={handleModalCancel}
                    centered
                    footer={null}
                    width={650}>
                    <AccessCardsModal
                        onSave={handleAccessCardSave}
                        onCancel={handleModalCancel}
                        visitRequestId={id}
                        selectedVisitorIds={selectedVisitors} />
                </Modal>
                <Modal
                    open={showKeyReferenceModal}
                    onCancel={handleModalCancel}
                    centered
                    footer={null}
                    width={650}>
                    <KeyReferenceModal
                        onCancel={handleModalCancel}
                        onSave={handleKeyReferenceSave}
                        selectedVisitorIds={selectedVisitors} />
                </Modal>
                <Modal
                    open={showReturnCardsModal}
                    onCancel={handleModalCancel}
                    centered
                    footer={null}
                    width={900}>
                    <ReturnCardsModal
                        onSave={handleReturnCardsSave}
                        selectedVisitorIds={selectedVisitors} />
                </Modal>
                <div className="visitors-on-site-container">
                    <div className="header">
                        <div className="content-wrapper">
                            <div className="icon-wrapper" onClick={handleBackClick}>
                                <LeftChevron />
                            </div>
                            <h1 className="title">Visitors on Site</h1>
                            <div className="placeholder" />
                        </div>
                    </div>
                    <div className="main-on-site-content">
                        <p className="warning-caption">Please check the ID of the visitors and select which visitors are on site to check-in and check out when leaving.</p>
                        {selectedVisitors.length > 0
                            ? (
                                <div className="check-in-out-container">
                                    <p className="caption">{selectedVisitors.length} items selected</p>
                                    <div className="buttons-wrapper">
                                        <button onClick={handleCheckInClick} type="button">Check In</button>
                                        <button disabled={getCheckoutDisabled()} onClick={handleCheckOutClick} type="button" className={getCheckoutDisabled() ? 'button-disabled' : ''}>Check Out</button>
                                    </div>
                                </div>
                            )
                            : null}
                        <Table
                            pagination={{
                                pageSize: visitorsTodayPagination.pageSize,
                                currentPage: visitorsTodayPagination.currentPage,
                                total: visitorsTodayPagination.total,
                                onChange: (page) => {
                                    dispatch(visitorsTodayRequest({
                                        page,
                                        visitRequestId: id,
                                    }));
                                },
                            }}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            loading={isFetching}
                            columns={columns}
                            data={data} />
                    </div>
                </div>
            </>

        </Main>
    );
};

export default VisitorsOnSite;
