import React, { useState, FC } from 'react';
import './siteselection.scss';
import FormSection from 'components/Form/FormSection';
import ListSearch from 'components/Form/ListSearch';
import { CloseIcon } from 'components/Elements/CustomSVGIcon';
import moment from 'moment';
import { SiteSelectionProps } from './types';

interface OpenDay {
    day: string;
    startTime: string;
    endTime: string;
}

const SiteSelection: FC<SiteSelectionProps> = ({ options = [], onSelect, selectedSiteId }) => {
    const [showWarning, setShowWarning] = useState(true);

    const handleSelectSiteChange = (value: string | number) => {
        onSelect(value);
    };

    const sitesListData = options.map((site) => ({
        leftString: `${site.type}: ${site.fullAddress}`,
        value: site.id.toString(),
    }));

    const selectedSite = options.find((site) => site.id.toString() === selectedSiteId);
    const openDays = selectedSite?.openDays || [];

    const getOpenDay = (day: OpenDay) => {
        const formattedStartTime = moment(day.startTime, 'HH:mm:ss').format('h A');
        const formattedEndTime = moment(day.endTime, 'HH:mm:ss').format('h A');
        return <span className="caption">{`${day.day} | ${formattedStartTime} - ${formattedEndTime}`}</span>;
    };

    return (
        <div className="select-site-form-page-container">
            {showWarning && (
                <div className="visitor-form-info-warning">
                    <h3 className="heading">Applications for &quot;Controlled Access&quot; to restricted areas or buildings.</h3>
                    <span>Controlled Access is intended for personnel visiting restricted sites for a specific purpose and a limited period, under the control of a guard.<br />
                        To ensure a guard booking, application must be submitted at least 72 hours in advance (or 48 hours if submitted with all approvals completed).<br />
                        Please Note: Incomplete applications may be rejected.
                    </span>
                    <h4 className="sub-heading">12 Hours Minimum Shift Notice</h4>
                    <span>Booking a shift for a Non-Open day should be requested as 07:00 - 19:00 (12 hours minimum) for day time works and 19:00 - 07:00 for overnight works.</span>
                    <div className="info-warning-icon-wrapper" onClick={() => setShowWarning(false)}>
                        <CloseIcon />
                    </div>
                </div>

            )}
            {selectedSite && (
                <div className="selected-site-container">
                    <div className="header">
                        <h3 className="heading">Selected Site for Visit Request</h3>
                    </div>
                    <div className="content">
                        <div className="detail-wrapper">
                            <h5 className="label">Site Type:</h5>
                            <span className="detail">{selectedSite?.type}</span>
                        </div>
                        <div className="detail-wrapper">
                            <h5 className="label">Site Address:</h5>
                            <span className="detail">{selectedSite?.fullAddress}</span>
                        </div>
                        {selectedSite?.openDays && selectedSite?.openDays?.length > 0
                            ? (
                                <div className="open-days-banner">
                                    <span className="label">Site Open Days:</span>
                                    <div className="open-days-list-wrapper">
                                        {openDays.length > 0 ? openDays.map((day) => getOpenDay(day)) : null}
                                    </div>
                                </div>
                            )
                            : null}

                    </div>
                </div>
            )}
            <FormSection title="Choose Your Site">
                <ListSearch
                    listData={sitesListData}
                    onSelect={handleSelectSiteChange}
                    selectedValues={[selectedSiteId]}
                    showSelectedValues />
            </FormSection>
        </div>
    );
};

export default SiteSelection;
