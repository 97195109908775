import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';
import { DashboardResponseProps, TemporaryAccessCardsResponseProps, UnreturnedCardsAndKeysResponseProps, VisitorsResponseProps } from './types';
import { handleError } from '../utils/helpers';

export const securityDashboardRequest = createAsyncThunk(
    'securityDashboard/securityRequest',
    async (payload: { page?: number; }, { rejectWithValue }) => {
        try {
            const response: DashboardResponseProps = await fetchApiAuth({
                method: 'GET',
                url: 'security-visit-request/all',
                params: {
                    page: payload.page,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const visitorsTodayRequest = createAsyncThunk(
    'securityDashboard/visitorsTodayRequest',
    async (payload: { page?: number; visitRequestId: string | undefined }, { rejectWithValue }) => {
        try {
            const { page, visitRequestId } = payload;
            const response: VisitorsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/todays-visitors',
                params: {
                    page,
                    visitRequestId,
                },
            });
            console.log('visitorsTodayRequest', response.data);
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const checkInVisitors = createAsyncThunk(
    'securityDashboard/checkInVisitors',
    async (payload: { visitRequestVisitorIds: string[], visitRequestId: string | undefined }, { rejectWithValue }) => {
        const { visitRequestVisitorIds, visitRequestId } = payload;
        try {
            const response: VisitorsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/check-in-visitors',
                body: {
                    visitRequestVisitorIds,
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const checkOutVisitors = createAsyncThunk(
    'securityDashboard/checkOutVisitors',
    async (payload: { visitRequestVisitorIds: string[]; reasonForNoReturn: string; cardKeyVisitorRecordIds: string[], visitRequestId: string | undefined }, { rejectWithValue }) => {
        const { reasonForNoReturn, visitRequestVisitorIds, cardKeyVisitorRecordIds, visitRequestId } = payload;
        try {
            const response: VisitorsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/check-out-visitors',
                body: {
                    reasonForNoReturn,
                    visitRequestVisitorIds,
                    cardKeyVisitorRecordIds,
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const getAccessCards = createAsyncThunk(
    'securityDashboard/getAccessCards',
    async (payload: { visitRequestId: string | undefined }, { rejectWithValue }) => {
        try {
            const { visitRequestId } = payload;
            const response: TemporaryAccessCardsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/get-access-cards',
                body: {
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const addNewAccessCard = createAsyncThunk(
    'securityDashboard/addNewAccessCard',
    async (payload: { description: string, visitRequestId: string | undefined }, { rejectWithValue }) => {
        const { description, visitRequestId } = payload;
        try {
            const response: TemporaryAccessCardsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/add-new-access-card',
                body: {
                    description,
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const assignAccessCard = createAsyncThunk(
    'securityDashboard/assignAccessCard',
    async (payload: { cardId: string; selectedVisitorIds: string[], visitRequestId: string | undefined }, { rejectWithValue }) => {
        const { cardId, selectedVisitorIds, visitRequestId } = payload;
        try {
            const response: VisitorsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/assign-access-card',
                body: {
                    cardId,
                    selectedVisitorIds,
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const assignKeyReference = createAsyncThunk(
    'securityDashboard/assignKeyReference',
    async (payload: { description: string; selectedVisitorIds: string[], visitRequestId: string | undefined }, { rejectWithValue }) => {
        const { description, selectedVisitorIds, visitRequestId } = payload;
        try {
            const response: VisitorsResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/assign-key-reference',
                body: {
                    description,
                    selectedVisitorIds,
                    visitRequestId,
                },
            });
            return response.data;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const unreturnedCardsAndKeysRequest = createAsyncThunk(
    'securityDashboard/unreturnedCardsAndKeysRequest',
    async (payload, { rejectWithValue }) => {
        try {
            const response: UnreturnedCardsAndKeysResponseProps = await fetchApiAuth({
                method: 'GET',
                url: 'security-visit-request/unreturned-cards-and-keys',
            });
            return response.data.unreturnedCardsAndKeys;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);

export const markCardReturned = createAsyncThunk(
    'securityDashboard/markCardReturned',
    async (payload: { cardIds: number[] }, { rejectWithValue }) => {
        const { cardIds } = payload;
        try {
            const response: UnreturnedCardsAndKeysResponseProps = await fetchApiAuth({
                method: 'POST',
                url: 'security-visit-request/mark-card-as-returned',
                body: { cardIds },
            });
            return response.data.unreturnedCardsAndKeys;
        } catch (error: unknown) {
            const errorMessage = handleError(error);
            if (errorMessage !== false) {
                notification.error({
                    message: 'Error',
                    description: errorMessage,
                });
                return rejectWithValue(errorMessage);
            }
            throw error;
        }
    },
);
