import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Main from 'components/layout/Main';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { securityDashboardRequest } from 'core/securityDashboard/securityDashboardApi';
import { AppDispatch, CombinedStates } from '../../core/types';
import SecurityInfoBlock from './components/SecurityInfoBlock';
import SecurityVisitList from './components/SecurityVisitList';
import SecurityCalendar from './components/SecurityCalendar';
import type { DailyVisit } from './types';
import './securityDashboard.scss';

const SecurityDashboard: FC = () => {
    // Toolbar for security dashboard only
    const toolbar = [
        { label: 'Dashboard', link: '/security-dashboard', iconName: 'home' },
        { label: 'Unreturned Cards & Keys', link: '/unreturned-cards-and-keys', iconName: 'key' },
    ];
    const navigation = [{ label: 'test', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const backLink = (
        <Button type="link" onClick={() => console.log('Back')}>
            <LeftOutlined /> BACK
        </Button>
    );

    const dispatch = useDispatch<AppDispatch>();
    const { securityVisitRequests } = useSelector((state: CombinedStates) => state.securityDashboard);

    useEffect(() => {
        dispatch(securityDashboardRequest({
            page: 1,
        }));
    }, []);

    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const filteredArray = securityVisitRequests?.filter(object => object.dates.some(dateObject => dateObject.date === today
        || (object.dates.some(date => date.date === yesterday && moment(date.start_time, 'HH:mm:ss').isAfter(moment(date.end_time, 'HH:mm:ss'))))));
    const visitsToday: DailyVisit[] = filteredArray.map(visit => {
        const date = visit.dates.find(dateObject => dateObject.date === today || (dateObject.date === yesterday && moment(dateObject.start_time, 'HH:mm:ss').isAfter(moment(dateObject.end_time, 'HH:mm:ss'))));
        return {
            encryptedId: visit.encrypted_id,
            reference: visit.id,
            siteName: visit.site_address,
            visitorsCount: visit.visitors_count,
            visitors: visit.visitors,
            shift: date?.shift || '',
            timeSlot: `${moment(date?.start_time, 'HH:mm:ss').format('h A')} - ${moment(date?.end_time, 'HH:mm:ss').format('h A')}`,
            areaAccess: date?.site_area_name || '',
            workDetails: visit.work_description || '',
            startDate: date?.date || '',
            status: visit.status,
            showButton: true,
        };
    });

    const allVisits: DailyVisit[] = [];

    securityVisitRequests.forEach(visit => {
        visit.dates.forEach(date => {
            const showButton = date.date === moment().format('YYYY-MM-DD') || (date.date === moment().subtract(1, 'days').format('YYYY-MM-DD') && moment(date.start_time, 'HH:mm:ss').isAfter(moment(date.end_time, 'HH:mm:ss')));
            const newVisit = {
                encryptedId: visit.encrypted_id,
                reference: visit.id,
                siteName: visit.site_address,
                visitorsCount: visit.visitors_count,
                visitors: visit.visitors,
                shift: date.shift,
                timeSlot: `${moment(date.start_time, 'HH:mm:ss').format('h A')} - ${moment(date.end_time, 'HH:mm:ss').format('h A')}`,
                areaAccess: date.site_area_name,
                workDetails: visit.work_description || '',
                startDate: date.date,
                status: visit.status,
                showButton,
            };
            allVisits.push(newVisit);
        });
    });

    allVisits.sort((a, b) => (moment(a.startDate).isBefore(b.startDate) ? -1 : 1));

    return (
        <Main
            className="login"
            title={<div>Dashboard</div>}
            hideSubHeader
            subHeaderTitle="Dashboard"
            subHeaderHasBackLink
            subHeaderBackLink="/"
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            floatingHeader={false}
            showFooter={false}
            footerContent=""
            floatingFooter={false}
            backLink={backLink}
            breadcrumb={navigation}>
            <div className="main-container dashboard">
                <div className="security-dashboard-container">
                    <h3 className="heading">Today</h3>
                    <div className="top-container">
                        <div className="info-block">
                            <SecurityInfoBlock visitsToday={visitsToday} />
                        </div>
                        <div className="calendar">
                            <SecurityCalendar visitData={allVisits} />
                        </div>
                    </div>
                    <div className="bottom-container">
                        <SecurityVisitList visitData={allVisits} />
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default SecurityDashboard;
