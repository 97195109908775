import { AppDispatch } from 'core/types';
import { useDispatch } from 'react-redux';
import { createVisitRequest } from 'core/visitRequestForm/visitRequestFormApi';
import { useEffect, useRef, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { VisitRequestFormResponsePayload } from 'core/visitRequestForm/types';
import { useNavigate } from 'react-router';

const CreateNewRequest = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [requestMade, setRequestMade] = useState(false);
    const requestSentRef = useRef(false);

    const sendRequest = async () => {
        if (!requestMade && !requestSentRef.current) {
            requestSentRef.current = true; // Block any further execution attempts
            try {
                await dispatch(createVisitRequest())
                    .then(unwrapResult)
                    .then((payload: VisitRequestFormResponsePayload) => {
                        navigate(`/visitor-request/${payload.visitRequest.id}/step/1`);
                    });
                window.location.reload();
            } catch (error) {
                console.error('Failed to create a visit request: ', error);
            } finally {
                setRequestMade(true);
            }
        }
    };

    useEffect(() => {
        sendRequest();
    }, [requestMade]);
    return null;
};

export default CreateNewRequest;
