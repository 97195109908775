import React, { FC, useState } from 'react';
import Checkbox from 'components/Elements/Checkbox';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Elements/CustomSVGIcon';
import { CheckboxData } from '../types';
import './checkboxparent.scss';
import CheckboxChild from '../CheckboxChild';

interface CheckboxParentProps {
    data: CheckboxData;
    onClick: (tier: 'child' | 'parent', value: string) => void;
}

const CheckboxParent: FC<CheckboxParentProps> = ({ data, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { name, description, children, checked, value } = data;

    const handleCheckboxClick = () => {
        onClick('parent', value);
        setIsOpen(!isOpen);
    };

    return (
        <div className="checkbox-parent-container">
            <div className="search-checkbox-header">
                <Checkbox checked={checked} onClick={handleCheckboxClick} icon="block" />
                <h3 className="search-checkbox-title">{name}</h3>
                <div className="search-checkbox-icon-wrapper" onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
            </div>
            <p className="search-checkbox-description">{description}</p>
            {isOpen && <div className="search-checkbox-children-wrapper">{children?.map((child) => <CheckboxChild key={child.value} data={child} onClick={onClick} />)}</div>}
        </div>
    );
};

export default CheckboxParent;
