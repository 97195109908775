import React, { useState, useEffect, useRef, FC } from 'react';
import { CompletedStepIcon, CurrentStepIcon, IncompletedStepIcon } from '../../Elements/CustomSVGIcon';
import './stepper.scss';

interface StepperProps {
    labels: string[];
    currentStep: number;
}

const Stepper: FC<StepperProps> = ({ labels, currentStep }) => {
    const [componentWidth, setComponentWidth] = useState<number>(0);
    const stepperRef = useRef<HTMLDivElement>(null);

    const stepperLength: number = labels.length;

    useEffect(() => {
        if (stepperRef.current) {
            const stepperWidth: number = stepperRef.current.offsetWidth;
            setComponentWidth(stepperWidth);
        }
    });

    const renderStep = (label: string, index: number): JSX.Element => {
        const stepWidth: number = componentWidth ? componentWidth / stepperLength : 150;
        const ICON_WIDTH = 25;
        const getIcon = (): JSX.Element => {
            if (index < currentStep - 1) {
                return <CompletedStepIcon />;
            }
            if (index === currentStep - 1) {
                return <CurrentStepIcon />;
            }
            return <IncompletedStepIcon />;
        };

        const getLineLength = (): string => {
            if (componentWidth) {
                return `${(stepWidth / 2) - (ICON_WIDTH / 2) - 4}px`;
            }
            return '100px';
        };

        return (
            <div className="step-wrapper" key={index} style={{ width: `${stepWidth}px` }}>
                <div className="step-graphic">
                    <span className="stepper-line" style={{ backgroundColor: index <= currentStep - 1 ? '#2B2D42' : '#C0D1D8', width: getLineLength(), opacity: index === 0 ? 0 : 1 }} />
                    <div className="icon-wrapper" style={{ height: `${ICON_WIDTH}px`, width: `${ICON_WIDTH}px` }}>{getIcon()}</div>
                    <span className="stepper-line" style={{ backgroundColor: index < currentStep - 1 ? '#2B2D42' : '#C0D1D8', width: getLineLength(), opacity: index >= stepperLength - 1 ? 0 : 1 }} />
                </div>
                <span className="step-label" style={{ color: index > currentStep - 1 ? '#C0D1D8' : '#2B2D42' }}>{label}</span>
            </div>
        );
    };

    return <div className="stepper-container" ref={stepperRef}>{labels.map((label, index) => renderStep(label, index))}</div>;
};

export default Stepper;
