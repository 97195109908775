import React, { FC, useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import './SearchFilter.scss';
import TextInput from 'components/Elements/TextInput';
import { ClearItem, SearchIcon, SortIcon } from 'components/Elements/CustomSVGIcon';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CombinedStates } from 'core/types';
import moment from 'moment';
import { SearchFilterProps } from '../../types';
import DatePickerSearch from '../DatePickerSearch';
import DropdownSearch from '../DropdownSearch';
import { ADMIN_USER, SCORCHSOFT_USER } from '../../../../core/utils/value';

interface DisplayTag {
    type: string;
    value: string;
}

const SearchFilter: FC<SearchFilterProps> = ({ isExport, statusFilter, siteFilter, onSort, onClear, onExport, searchParams, updateFilters }) => {
    const [text, setText] = useState<string>('');
    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [siteText, setSiteText] = useState<string[]>([]);
    const [statusText, setStatusText] = useState<string[]>([]);

    const { isFetching } = useSelector((state: CombinedStates) => state.dashboard);
    const { userData } = useSelector((state: CombinedStates) => state.user);

    const debouncedUpdateFilters = useCallback(debounce(updateFilters, 1000), []);
    const debouncedUpdateSearchTerms = useCallback(debounce(() => {
        if (text !== undefined && text !== '') {
            setSearchTerms((prevSearchTerms = []) => [...prevSearchTerms, text]);
            setText('');
        }
    }, 1500), [text]);

    useEffect(() => {
        const searchTermsParam = searchParams.get('searchTerms');
        const startDateParam = searchParams.get('startDate');
        const endDateParam = searchParams.get('endDate');
        const searchStatus = searchParams.get('statusText');
        const searchSite = searchParams.get('siteText');
        const searchStatusArray = searchStatus?.split(',') || [];
        const searchSiteArray = searchSite?.split(',') || [];
        setSearchTerms(searchTermsParam ? searchTermsParam.split(',') : []);
        setStartDate(startDateParam || '');
        setEndDate(endDateParam || '');
        setSiteText(searchSiteArray);
        setStatusText(searchStatusArray);
    }, []);

    useEffect(() => {
        debouncedUpdateSearchTerms();
        return () => {
            debouncedUpdateSearchTerms.cancel();
        };
    }, [text, debouncedUpdateSearchTerms]);

    useEffect(() => {
        const params = {
            searchTerms,
            startDate,
            endDate,
            siteText,
            statusText,
        };
        debouncedUpdateFilters(params);
        return () => {
            debouncedUpdateFilters.cancel();
        };
    }, [searchTerms, startDate, endDate, siteText, statusText, debouncedUpdateFilters]);

    const getDisplayItems = () => {
        const handleRemoveItemClick = (item: DisplayTag) => {
            switch (item.type) {
            case 'text': {
                const newSearchTerms = searchTerms.filter((term) => term !== item.value);
                setSearchTerms(newSearchTerms);
                break;
            }
            case 'startDate': {
                setStartDate('');
                break;
            }
            case 'endDate': {
                setEndDate('');
                break;
            }
            case 'site': {
                const newSiteText = [...siteText];
                const foundIndex = newSiteText.findIndex((site) => site === item.value);
                newSiteText.splice(foundIndex, 1);
                setSiteText(newSiteText);
                break;
            }
            case 'status': {
                const foundKey = Object.keys(statusFilter).find(key => statusFilter[key] === item.value);
                const newStatusText = [...statusText];
                const foundIndex = newStatusText.findIndex((status) => status === foundKey);
                newStatusText.splice(foundIndex, 1);
                setStatusText(newStatusText);
                break;
            }
            default:
                break;
            }
        };

        const items = [];
        if (searchTerms.length) {
            searchTerms.forEach((term) => {
                items.push({ type: 'text', value: term });
            });
        }
        if (startDate) {
            items.push({ type: 'startDate', value: moment(startDate).format('ddd Do MMMM') });
        }
        if (endDate) {
            items.push({ type: 'endDate', value: moment(endDate).format('ddd Do MMMM') });
        }
        if (siteText.length) {
            siteText.forEach((site) => {
                items.push({ type: 'site', value: site });
            });
        }
        if (statusText.length) {
            statusText.forEach((status) => {
                const statusValue = statusFilter[status];
                items.push({ type: 'status', value: statusValue });
            });
        }
        return (
            <div className="items-list-container">{items.map((item) => (
                <div key={item.value} className="selected-item">
                    <p className="label">{item.value}</p>
                    <div className="icon-wrapper" onClick={() => handleRemoveItemClick(item)}>
                        <ClearItem />
                    </div>
                </div>
            )) }
            </div>
        );
    };

    const resetFilters = () => {
        setText('');
        setSearchTerms([]);
        setStartDate('');
        setEndDate('');
        setSiteText([]);
        setStatusText([]);
        onClear();
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };

    const handleStartDateChange = (date: string) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: string) => {
        setEndDate(date);
    };

    const handleFilterSiteChange = (site: string[]) => {
        setSiteText(site);
    };

    const handleFilterStatusChange = (status: string[]) => {
        setStatusText(status);
    };

    return (
        <div className="dashboard-search-container">
            <div className="filter-container">
                <TextInput leftIcon={<SearchIcon />} placeholder="Search" value={text} onChange={handleTextChange} />
                <DatePickerSearch
                    startDate={startDate}
                    endDate={endDate}
                    onSelectStartDate={handleStartDateChange}
                    onSelectEndDate={handleEndDateChange} />
                <DropdownSearch
                    statusFilter={statusFilter}
                    siteFilter={siteFilter}
                    defaultStatus={statusText}
                    defaultSite={siteText}
                    onFilterSite={handleFilterSiteChange}
                    onFilterStatus={handleFilterStatusChange} />
            </div>
            <div className="filter-button-container">
                {getDisplayItems()}
                <div className="extra-button">
                    <Button type="link" className="sort-button" style={{ borderRadius: 0 }} onClick={onSort}>
                        <span style={{ textDecorationLine: 'none' }}>
                            Sort
                        </span>
                        <div className="sort-icon">
                            <SortIcon />
                        </div>
                    </Button>
                    <Button type="link"
                        className="clear-button"
                        style={{ borderRadius: 0 }}
                        onClick={resetFilters}>
                        <span style={{ textDecorationLine: 'none' }}>
                            Clear All
                        </span>
                    </Button>
                    {isExport && (userData.role.accessLevel === ADMIN_USER || userData.role.accessLevel === SCORCHSOFT_USER) && (
                        <Button onClick={onExport} type="primary" htmlType="submit" className="export-button" style={{ height: 45 }} disabled={isFetching}>
                            Export Completed Visits
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

SearchFilter.defaultProps = {
    statusFilter: {},
    siteFilter: [],
};

SearchFilter.propTypes = {
    isExport: PropTypes.bool.isRequired,
    statusFilter: PropTypes.object,
    siteFilter: PropTypes.array,
};

export default SearchFilter;
