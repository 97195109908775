import React from 'react';
import ResetPasswordForm from '../../../components/Form/ResetPasswordForm';
import '../Login/login.scss';
import { SignupProps } from './types';
import AuthLayout from '../../../components/layout/AuthLayout';

const Signup: React.FC<SignupProps> = ({ match }) => {

    // Probably need a function to check the reset code first before showing the form
    console.log(match);

    return (
        <AuthLayout>
            <ResetPasswordForm
                title="Create Password"
                subTitle="To complete the creation of your account, please enter a password you wish to use to access to your account" />
        </AuthLayout>
    );
};

export default Signup;
