import React, { FC, useEffect, useMemo } from 'react';
import { Modal } from 'antd';
import './overruledModal.scss';

interface LockedRequestModalProps {
    isModalOpen: boolean;
    handleCancel: () => void;
    lockedBy: string;
    requestId: string | number | null;
}

const LockedRequestModal: FC<LockedRequestModalProps> = ({ isModalOpen = true, handleCancel, lockedBy, requestId }) => {
    const formattedRequestId = useMemo(() => `00${requestId}`.slice(-3), [requestId]);

    useEffect(() => {
        if (isModalOpen) {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }
    }, [isModalOpen]);

    return (
        <Modal
            footer={null}
            width={650}
            centered
            open={isModalOpen}
            onCancel={handleCancel}>
            <div className="overruled-modal-container">
                <h2 className="heading">Request Overruled</h2>
                <div className="caption">User <span style={{ fontWeight: 700 }}>{lockedBy}</span> has overruled you to take control of the Request {formattedRequestId}. Any unsaved changes you made will be cleared.</div>
                <div className="button-wrapper">
                    <button type="button" className="overrule-button" onClick={handleCancel}>OK</button>
                </div>
            </div>
        </Modal>
    );
};

export default LockedRequestModal;
