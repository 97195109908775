import React, { FC, useState } from 'react';
import { Empty } from 'antd';
import { DailyVisit } from 'pages/SecurityDashboard/types';
import ViewDetailsModal from './ViewDetailsModal';
import './securityInfoBlock.scss';

interface SecurityInfoBlockProps {
    visitsToday: DailyVisit[];
}

const SecurityInfoBlock: FC<SecurityInfoBlockProps> = ({ visitsToday }) => {
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<DailyVisit | null>(null);

    const allCompleted = visitsToday.every(visit => visit.status === 'Completed');

    const handleViewDetailsCancel = () => {
        setShowDetailsModal(false);
    };

    const getVisitBlock = (visit: DailyVisit) => {
        const handleViewDetailsClick = () => {
            setModalData(visit);
            setShowDetailsModal(true);
        };

        return (
            <div className="security-info-block-container" key={visit.reference}>
                <div className="top-section">
                    <div className="ref-wrapper">
                        <h5 className="label">Ref</h5>
                        <span className="caption">{visit.reference}</span>
                    </div>
                    <div className="site-wrapper">
                        <h5 className="label">Site</h5>
                        <span className="caption">{visit.siteName}</span>
                    </div>
                    <div className="visitors-wrapper">
                        <h5 className="label">Number of Visitors</h5>
                        <span className="caption">{visit.visitorsCount}</span>
                    </div>
                    <div className="shift-wrapper">
                        <h5 className="label">Shift</h5>
                        <span className="caption">{visit.shift}</span>
                        <span className="caption">{visit.timeSlot}</span>
                    </div>
                    <div className="area-wrapper">
                        <h5 className="label">Area Access</h5>
                        <span className="caption">{visit.areaAccess}</span>
                    </div>
                </div>
                <div className="bottom-section">
                    <h3 className="label">Work Details</h3>
                    <p className="caption">{visit.workDetails}</p>
                    <button
                        className="view-details-button"
                        onClick={handleViewDetailsClick}
                        type="button"
                        disabled={allCompleted}>{allCompleted ? '✓ Completed' : 'View Details'}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <ViewDetailsModal
                open={showDetailsModal}
                handleCancel={handleViewDetailsCancel}
                data={modalData} />
            <div className="security-info-blocks">
                {visitsToday.length === 0
                    ? (
                        <div className="no-visits">
                            <Empty description="No visits today" />
                        </div>
                    ) : null}
                {visitsToday.map((visitData: DailyVisit) => getVisitBlock(visitData))}
            </div>
        </>

    );
};

export default SecurityInfoBlock;
