/* eslint-disable dot-notation */
import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Row, Col } from 'antd';
import { RuleObject, RuleRender } from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from '../../../core/utils/helpers';
import { resetPassword } from '../../../core/user/userApi';
import { updatePasswordResetCompleted } from '../../../core/user/userSlice';
import { TickIcon } from '../../Elements/CustomSVGIcon';
import './resetPasswordForm.scss';
import { FinishedFailedProps, valuesProps, ResetPasswordFormProps, FormInstance } from './types';
import { AppDispatch, CombinedStates } from '../../../core/types';

const ResetPasswordForm:FC<ResetPasswordFormProps> = ({
    title,
    subTitle,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigate();
    const {
        isFetching,
        passwordResetCompleted,
    } = useSelector((state:CombinedStates) => state.user);
    const { token, email } = useParams();

    const [form] = Form.useForm();
    const [customErrors, setCustomErrors] = useState<Record<string|number|symbol, string[]>>({
        newPassword: [],
        confirmPassword: [],
    });

    const onFinish = (values: valuesProps) => {
        dispatch(resetPassword({
            email,
            password: values.newPassword,
            passwordConfirmation: values.confirmPassword,
            token,
        }));
    };

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    // Errors
    const handlePasswordValidation = (_: unknown, value: string) => {
        if (!value) return Promise.reject();
        const error = validatePassword(value);
        setCustomErrors({ ...customErrors, newPassword: error });
        if (error && error.length > 0) {
            return Promise.reject();
        }
        return Promise.resolve();
    };

    useEffect(() => {
        if (!isFetching && passwordResetCompleted) {
            dispatch(updatePasswordResetCompleted(false));
            navigation('/');
        }
    }, [isFetching]);

    // Define the signature of your validator function
    const handleConfirmPasswordValidation: RuleRender = ({ getFieldValue }: FormInstance) => ({
        validator(_: RuleObject, value: string) {
            if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });

    const renderCustomError = (error: string[]|undefined) => (
        error && error.length > 0
            ? (
                <div className="error-block">
                    <div className="title-text-md">Password must:</div>
                    {error.map(err => (
                        <div className="custom-error">
                            <TickIcon />
                            <span>{err}</span>
                        </div>
                    ))}
                </div>
            ) : null
    );

    return (

        <div className="login-form-box">
            <div className="form">
                <h3 className="form-title">{title}</h3>
                <div className="form-sub-title">
                    {subTitle}
                </div>
                <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
                    <Form.Item
                        label="Password"
                        name="newPassword"
                        validateStatus={customErrors['newPassword'] && customErrors['newPassword'].length > 0 ? 'error' : ''}
                        help={renderCustomError(customErrors['newPassword'])}
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { validator: handlePasswordValidation },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item label="Verify Password"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        validateStatus={customErrors['confirmPassword'] && customErrors['confirmPassword'].length > 0 ? 'error' : ''}
                        help={renderCustomError(customErrors['confirmPassword'])}
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            handleConfirmPasswordValidation,
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Button type="primary" size="large" block htmlType="submit" className="wide" loading={isFetching}>
                                Reset Password
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </div>

    );
};

export default ResetPasswordForm;
